import { InstrumentType, isSequel } from "../../../data/model/instrument-type-model"

export interface RunCollectionInfo {
    collectionIndex: number
    movieTime_min: number
    usageCount: number
    immobilizationTime_min: number
    preextensionTime_min: number
    maxLoadingTime: number
    usePredictiveLoading: boolean
}

export class RunDurationEstimator {

    static maxTotalRunTimeAllowed_min = 96 * 60
    static defaultImmobilizationTime_min = 120

    public static calculateEstimatedRunDuration_min(runInfo: RunCollectionInfo[], systemName: InstrumentType): number {

        if (isSequel(systemName)) {
            return RunDurationEstimator.calculateEstimatedRunDuration_Sequel(runInfo)
        } else {
            return RunDurationEstimator.calculateEstimatedRunDuration_Sequel2(runInfo)
        }
    }

    static calculateEstimatedRunDuration_Sequel(runInfo: RunCollectionInfo[]): number {

        if (!runInfo || !runInfo.length) { return 0 }

        const robotOverheadFirstCell_min = 60
        const immobilizationTimeFirstCell_min = runInfo[0].immobilizationTime_min
        const preextensionTimeFirstCell_min = runInfo[0].preextensionTime_min
        const totalMovieTimeAllCollections_min = runInfo.reduce(
            (total, movieInfo) => total + movieInfo.movieTime_min * movieInfo.usageCount,
            0
        )
        const totalTime_min = 0
            + robotOverheadFirstCell_min
            + immobilizationTimeFirstCell_min
            + preextensionTimeFirstCell_min
            + totalMovieTimeAllCollections_min

        return totalTime_min
    }

    static calculateEstimatedRunDuration_Sequel2(runInfo: RunCollectionInfo[]): number {

        if (!runInfo || !runInfo.length) { return 0 }

        // Robotic cell preparation time
        const cellPrep = 81
        // Robotic transfer of cell to stage
        const cellToStage = 10
        // Robotic transfer of cell to CPS
        const cellToCPS = 2
        // Robotic predictive loading cell prep time
        const plPrep = 23
        // Robotic cell prep after predictive loading pass
        const postPrep = 54

        let totalTime_min = runInfo[0].usePredictiveLoading ?
            plPrep + cellToStage + cellToCPS + runInfo[0].maxLoadingTime +
            runInfo[0].preextensionTime_min + postPrep + runInfo[0].movieTime_min :
            cellPrep + runInfo[0].immobilizationTime_min + runInfo[0].preextensionTime_min + runInfo[0].movieTime_min

        for (let i = 1; i < runInfo.length; i++) {
            if (runInfo[i].usePredictiveLoading ) {
                totalTime_min += cellToStage + cellToCPS + runInfo[i].maxLoadingTime +
                    runInfo[i].preextensionTime_min + postPrep + runInfo[i].movieTime_min
            } else {
                totalTime_min += cellToStage + runInfo[i].movieTime_min
            }
        }

        return totalTime_min
    }
}
