import { Reducer } from "redux"
import { FilenamePrefixState, FilenamePrefixActionTypes } from "./filename-prefix-types"

const initialState: FilenamePrefixState = {
    prefixConfigsJson: "{}"
}

export const filenamePrefixReducer: Reducer<FilenamePrefixState> = (state: FilenamePrefixState = initialState, action: any) => {
    switch (action.type) {
        case FilenamePrefixActionTypes.SET_PREFIX_CONFIGS:
            return Object.assign({},state,{prefixConfigsJson: action.payload})
        default:
            return state
    }
}
