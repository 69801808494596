import { SelectionMap } from "../../../../core-components/shared/DrillDownTable/drill-down-types"

export const AnalysisSelectorTypes = {
    SET_SELECTOR_TYPE: "@@analysis-selector/SET_SELECTOR_TYPE",
    SET_SELECTION_MAP: "@@analysis-selector/SET_SELECTION_MAP",
    SET_IS_FLAT_MODE: "@@analysis-selector/SET_IS_FLAT_MODE"
}

export const DEFAULT_ID_FIELD = "id"

export interface AnalysisSelectorState {
    readonly selectionMap: SelectionMap,
    readonly isFlatMode: boolean
}
