import {
    DataStoreViewRule,
    PipelineDataStoreViewRule
} from "../model/pipeline-datastore-view-rules-model"
import { apiCache } from "./api-cache"
import { ReportViewRule } from "../model/report-view-rules.model"
import * as API from "../api"
import { PromisePollScheduler } from "../../core/utils/promise-poll-scheduler"
import { Report, missingReport } from "../model/report-model"
import { BaseDatastoreFile } from "../model/datastore-file-model"
import { DatasetType } from "../model/dataset-type-model"

export const getDatastoreViewRulesMap = (
    pipelineId: string
): Promise<Map<string, DataStoreViewRule> | null> => {
    let viewRules$ = getPipelineDataStoreViewRules()
        .then((pipelineRules: PipelineDataStoreViewRule[]) => {
            return new Map((
                pipelineRules.map(pipelineRule => {
                    const viewRules = new Map((
                        pipelineRule.rules.map(viewRule => {
                            return [viewRule.sourceId, viewRule]
                        })
                    ) as [string, DataStoreViewRule][])
                    return [pipelineRule.pipelineId, viewRules]
                })
            ) as [string, Map<string, DataStoreViewRule>][])
        })

    return viewRules$.then(pipelineRules => {
        if (!pipelineRules.has(pipelineId)) {
            pipelineRules.set(pipelineId, new Map<string, DataStoreViewRule>())
        }
        return pipelineRules.get(pipelineId) || null
    })
}

export const getPipelineDataStoreViewRules = (): Promise<
    PipelineDataStoreViewRule[]
> => {
    return apiCache.getPipelineDatastoreViewRules()
}

export const getReportViewRules = (): Promise<ReportViewRule[]> => {
    return apiCache.getReportViewRules()
}

export const getFileDownload = (uuid: string): Promise<string> => {
    return apiCache.getDatastoreFileDownload(uuid)
}

export const getFiles = (jobType: string, jobId: number) => {
    let intervals: number[] = [1, 1, 1, 1, 1, 5, 5, 5, 5, 30]
    let poller = new PromisePollScheduler(
        () => {
            return API.nDatastoreFiles({jobId, jobType, options:{}}).then(
                response => response.data
            )
        },
        intervals,
        files => {
            return files.length > 1
        }
    )
    return poller
}

export const getReportFile = (
    jobId: number,
    uuid: string
): Promise<Report> => {
    return API.aReportFile(jobId, uuid).then(
        response => response.data,
        () => missingReport(uuid) // mcantor 08.02.23: don't see how this is ever used
    )
}
export const getUrlForImageFile = (
        { jobId, datastoreUuid, fileName }:
        { jobId: number, datastoreUuid: string,fileName: string}
    ): string => {
        return API.fileResourceUrl({jobId, datastoreUuid, fileName})
}

export const isDataStoreFileADataset = (file: BaseDatastoreFile): boolean => {
    return file.fileTypeId && DATASET_FILETYPES.indexOf(file.fileTypeId) > -1
}

const DATASET_FILETYPES = [
    DatasetType.SUBREAD,
    DatasetType.HDF_SUBREAD,
    DatasetType.ALIGNMENT,
    DatasetType.CONSENSUS_ALIGNMENT,
    DatasetType.CONSENSUS_READ,
    DatasetType.CONTIG,
    DatasetType.BARCODE,
    DatasetType.REFERENCE,
    DatasetType.TRANSCRIPT,
    DatasetType.TRANSCRIPT_ALIGNMENT
].map(type => type.filetype)
