import * as API from "../api"
import { ApiResponse } from "apisauce"

export interface ComponentVersionInfo {
    name: string
    description: string
    version: string
    id: string
    dependencies: string[]
    // The following two fields are derived from the entire manifest hierarchy
    parent: string
    depth: number
}

export interface VersionManifest {
    [key: string]: ComponentVersionInfo
}

export class VersionManifestService {

    static async getVersionManifest() {
        try {
            const res: ApiResponse<ComponentVersionInfo[]> = await API.versionManifest()

            if (res.ok) {
                const result = {}
                for (let version of res.data) {
                    result[version.id] = version
                }
                return result
            } else {
                throw res.data
            }
        } catch (err) {
            return null
        }
    }

    // Assumes no cycles
    static getComponentsInHierarchicalOrderWithDepth(
        versionManifest: VersionManifest, rootID: string, components: any[], depth = 0
    ) {
        const component: ComponentVersionInfo = versionManifest[rootID]
        components.push(component)
        component.depth = depth
        for (let childID of component.dependencies) {
            const child = versionManifest[childID]
            if (child) {
                this.getComponentsInHierarchicalOrderWithDepth(versionManifest, childID, components, depth + 1)
            }
        }
    }

    // This is useful to check if there are more roots than just smrtlink
    static getAllRoots(versionManifest: VersionManifest) {
        const roots: string[] = []
        // Stage 1: Add all items to top level
        for (let id in versionManifest) {
            if (versionManifest.hasOwnProperty(id)) {
                const dependencies = versionManifest[id].dependencies
                for (let childID of dependencies) {
                    const child = versionManifest[childID]
                    if (child) {
                        versionManifest[childID].parent = id
                    }
                }
            }
        }
        // Stage 2: Find roots, which are components without parents
        for (let id in versionManifest) {
            if (versionManifest.hasOwnProperty(id)) {
                const component = versionManifest[id]
                if (!component.parent) {
                    roots.push(component.id)
                }
            }
        }
        return roots
    }
}
