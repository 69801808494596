import * as API from "../api"
import { ResourceNotFoundError } from "../api"

export interface Eula {
    user: string
    enableInstallMetrics: boolean
    enableJobMetrics: boolean
}

export class EulaService {
    static getEula(smrtlinkVersion: string): Promise<Eula> {
        return API.getEula(smrtlinkVersion).then(response => {
            if (response.ok) {
                return response.data
            } else if (response.status === 404) {
                throw new ResourceNotFoundError("EULA not found")
            } else {
                throw new Error("Error in getEula!")
            }
        })
    }

    static addEula(eula: Eula): Promise<any> {
        return API.addEula(eula).then(response => {
            if (response.ok) {
                return response.data
            } else {
                throw new Error("Error in addEula!")
            }
        })
    }
}
