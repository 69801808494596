// TODO(pfernhout) Fix this to reflect application name
export const AVAILABLE_APPLICATION_NAMES = [
    "CREATED",
    "RUNNING",
    "SUBMITTED",
    "TERMINATED",
    "SUCCESSFUL",
    "FAILED",
    "ABORTED"
]
