import React, { FunctionComponent, useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { NotificationModel } from "../../../data/model/notification-model"

interface Props {
    selected: NotificationModel[]
    markNotifications: (unread: boolean) => void
    className?: string
}
export const MarkAsButton: FunctionComponent<Props> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const onMark = (unread: boolean) => {
        // note: the wierd inversion goes all the way to the model: Notification.unread
        props.markNotifications(unread)
        toggle()
    }

    const disabled = !props.selected || props.selected.length === 0

    return (
        <ButtonDropdown isOpen={isOpen} toggle={toggle} className = {props.className}>
          <DropdownToggle caret disabled={disabled}>
            Mark as
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => onMark(false)} disabled={disabled}> Read </DropdownItem>
            <DropdownItem onClick={() => onMark(true)} disabled={disabled}> Unread </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      );
}
