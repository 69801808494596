/* eslint:disable */

import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";

import { Main } from "./Main";
import { configureStore } from "./core/configure-store";
import { configureLocalStorage } from "./core/configure-localstorage";
import { configureApi } from "./data/api";

import "typeface-ibm-plex-sans"
import "typeface-merriweather-sans/index.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/900-italic.css";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import "pretty-checkbox/src/pretty-checkbox.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/theme/ui-refresh.scss";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./scss/index.scss";

require("dotenv").config()

const history = createBrowserHistory({ basename:  process.env.REACT_APP_ROUTER_BASE || "" });
const initialState = window.initialReduxState;
const { store, persistor } = configureStore(history, initialState);

configureLocalStorage(store);
configureApi(store);

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <Main store={store} history={history} persistor={persistor} />);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}
