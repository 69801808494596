export const BatchMode = {
    Merge: "MERGE",
    Identical: "IDENTICAL",
    Custom: "CUSTOM"
}
export const BATCH_MODE_LABEL_MERGE = "One Analysis for All Data Sets"
export const BATCH_MODE_LABEL_IDENTICAL = "One Analysis per Data Set - Identical Parameters"
export const BATCH_MODE_LABEL_CUSTOM = "One Analysis per Data Set - Custom Parameters"

export const BATCH_MODE_OPTIONS = [
    { mode: BatchMode.Merge, label: BATCH_MODE_LABEL_MERGE},
    { mode: BatchMode.Identical, label: BATCH_MODE_LABEL_IDENTICAL },
    { mode: BatchMode.Custom, label: BATCH_MODE_LABEL_CUSTOM}
]

export const START_BUTTON_LABEL = "Start"
export const START_BUTTON_LABEL_MULTI = "Start Multiple Jobs"
export const START_BUTTON_LABEL_CUSTOM = "Start and Create Next"
export const START_BUTTON_LABEL_CUSTOM_FINAL = "Start and Complete"

export const CameFrom = {
    DataManagement: "DATA_MANAGEMENT",
    Step1: "STEP1",
    RunDesign: "RUN_DESIGN",
    CopyAnalysis: "COPY_ANALYSIS",
    Unknown: "UNKNOWN"
}
