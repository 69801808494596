import { Action, ActionCreator } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { UserPreferencesActionTypes } from "./user-preferences-types"
import * as API from "../../api"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { User } from "../../model/user-model"
import { UserPreferences, defaultUserPreference } from "../../model/user-preferences-model"
import * as _ from "lodash"

export const fetchUserPreferences = (user: User): ThunkResult<Promise<UserPreferences | null>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
    if (!user) {
        return Promise.resolve(null)
    }
    try {
        const response = await API.getUserPreferences(user)
        const userPrefs = response.data
        const newPrefs = _.isEmpty(userPrefs) ? defaultUserPreference : userPrefs
        if (!newPrefs.notificationPreferences.emailAddress && user.email) {
            newPrefs.notificationPreferences.emailAddress = user.email
        }

        dispatch(onFetchUserPreferencesSuccess(newPrefs))
        if (! _.isEqual(userPrefs,newPrefs)) {
            dispatch(saveUserPrefences(newPrefs))
        }
        return userPrefs
    } catch (error) {
        dispatch(onFetchUserPreferencesFailure())
        throw(error)
    }
}

export const saveUserPrefences = (prefs: UserPreferences): ThunkResult<Promise<UserPreferences | null>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
    dispatch(onSaveUserPreferencesAttempt())
    try {
        const response = await API.updateUserPreferences(prefs)
        if (response.ok) {
            setTimeout( () =>
                dispatch(onSaveUserPreferencesSuccess(prefs))
            , 500) // fake a bit of delay to show spinner
        }
        return prefs
    } catch (err) {
        dispatch(onSaveUserPreferencesFailure(err))
        return null
    }
}

export const onSaveUserPreferencesAttempt: ActionCreator<Action> = () => ({
    type: UserPreferencesActionTypes.SAVE_USER_PREFERENCES_ATTEMPT
})

export const onSaveUserPreferencesFailure: ActionCreator<Action> = (error) => ({
    type: UserPreferencesActionTypes.SAVE_USER_PREFERENCES_FAILURE,
    payload: error
})

export const onSaveUserPreferencesSuccess: ActionCreator<Action> = (data: UserPreferences) => ({
    type: UserPreferencesActionTypes.SAVE_USER_PREFERENCES_SUCCESS,
    payload: data
})

export const onFetchUserPreferencesSuccess: ActionCreator<Action> = (data: UserPreferences) => ({
    type: UserPreferencesActionTypes.SET_USER_PREFERENCES,
    payload: data
})

export const onFetchUserPreferencesFailure: ActionCreator<Action> = () => ({
    type: UserPreferencesActionTypes.SET_USER_PREFERENCES,
    payload: null
})
