import { Project } from "../../model/project-model"

export const ProjectActionTypes = {
  API_ATTEMPT: "@@project/API_ATTEMPT",
  API_SUCCESS: "@@project/API_SUCCESS",
  API_FAILURE: "@@project/API_FAILURE",

  GET_PROJECT_SUCCESS: "@@project/GET_PROJECT_SUCCESS",
  GET_PROJECTS_SUCCESS: "@@project/GET_PROJECTS_SUCCESS",

  SET_PROJECT: "@@project/SET_PROJECT"
}

export interface ProjectApiState {
  readonly loading: boolean
  readonly data: Array<Project>
  readonly error: Error | null
  readonly project: Project
}
