import React, { ReactNode, FC } from "react"
import { Button, Spinner } from "reactstrap"
import classnames from "classnames"

interface Props {
  loading?: boolean
  children: ReactNode
  [x: string]: any
}

export const SMButton: FC<Props> = ({ children, loading, ...rest }: Props) => (
  <Button {...rest} disabled={loading}>
    <Spinner
      className={classnames({
        "button-style": true,
        "position-relative": true,
        visible: loading,
        invisible: !loading
      })}
      size="sm"
      type="grow"
    />
    <span
      className={classnames({
        "span-style": true,
        invisible: loading,
        visible: !loading
      })}
    >
      {children}
    </span>
  </Button>
)
