import { Reducer } from "redux"
import { AuthActionTypes, AuthState } from "./login-types"
import { setTokenHeader, refreshService } from "../../../data/api"

const initialState: AuthState = {
    loading: false,
    errorMessage: "",
    user: null,
    token: null,
    refreshToken: null
}

export const authReducer: Reducer<AuthState> = (state: AuthState = initialState, action: any) => {
    switch (action.type) {
        case "persist/REHYDRATE":
            if (action.key === "auth") {
                const refreshIsValid = !!(state.refreshToken && state.user)
                refreshService.setHasCachedRefreshToken(refreshIsValid)
                if (refreshIsValid && state.token) {
                    setTokenHeader(state.token)
                }
                return Object.assign({}, state, {
                    // Kludge to clear out loading flag and errorMessage when startup
                    loading: false,
                    errorMessage: "",
                    // Kludge to also fixup possible error conditions with auth state
                    user: refreshIsValid ? state.user : null,
                    token: refreshIsValid ? state.token : null,
                    refreshToken: refreshIsValid ? state.refreshToken : null
                })
            }
            return state

        case AuthActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true,
                errorMessage: ""
            })

        case AuthActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                errorMessage: ""
            })

        case AuthActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                errorMessage: action.payload,
                token: null,
                refreshToken: null,
                user: null
            })

        case AuthActionTypes.SET_TOKEN: {
            const { access_token, refresh_token } = action.payload
            // The next line is only needed to support developer testing of invalidating access tokens
            if (access_token) {
                setTokenHeader(access_token)
                // Extra check on undefined is to support testing by invalidating only auth token
                if (refresh_token !== undefined) { refreshService.setHasCachedRefreshToken(!!refresh_token) }
            }
            return Object.assign({}, state, {
                token: access_token,
                // Extra check on undefined is to support testing by invalidating only auth token
                refreshToken: refresh_token || (refresh_token === undefined ? state.refreshToken : refresh_token)
            })
        }
        case AuthActionTypes.SET_USER:
            return Object.assign({}, state, {
                user: action.payload
            })

        default:
            return state
    }
}
