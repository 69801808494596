import { Reducer } from "redux"
import { BarcodeSampleNamesActionTypes, BarcodeSampleNamesState } from "./barcode-sample-names-types"

const initialState: BarcodeSampleNamesState = {
    allBarcodeSamples: {},
    userBarcodeSamples: {}
}

export const barcodeSampleNamesReducer: Reducer<BarcodeSampleNamesState> = (
    state: BarcodeSampleNamesState = initialState,
    action: any
) => {
    switch (action.type) {
        case BarcodeSampleNamesActionTypes.SET_ALL_BARCODE_SAMPLES:
            return Object.assign({}, state, {
                allBarcodeSamples: action.payload
            })
        case BarcodeSampleNamesActionTypes.SET_USER_BARCODE_SAMPLES:
            return Object.assign({}, state, {
                userBarcodeSamples: action.payload
            })
        case BarcodeSampleNamesActionTypes.SET_USER_AND_ALL_BARCODE_SAMPLES: 
            return Object.assign({}, state, {
                userBarcodeSamples: action.payload[0],
                allBarcodeSamples: action.payload[1]
            })  
        default:
            return state
    }
}
