import React, { FC, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ConnectedRouterProps } from "react-router-redux"
import { NavLink } from "react-router-dom"
import { ApplicationState } from "../../core/types"

import * as CoreActions from "../../core/store/core-actions"
import { User } from "../../data/model/user-model"
import { pageNameMapping, ModuleNavMenu } from "../navbar/ModuleNavMenu"
import Navbar from "reactstrap/lib/Navbar"
import Container from "reactstrap/lib/Container"
import NavbarBrand from "reactstrap/lib/NavbarBrand"
import Nav from "reactstrap/lib/Nav"
import { UtilityNavMenu } from "../navbar/UtilityNavMenu"
import { setToken } from "../login/store/login-actions"
import { NavMenuProps } from "../navbar/NavMenuProps"
import { UserNavMenu } from "../navbar/UserNavMenu"

// For developer WSO2 testing; do not set to true in production!
const developerTestingForWSO2Timeouts = false

export const HELP_ROOT = "Default_CSH.htm"
const routeDocMapping = {
    "/": HELP_ROOT,
    "/login": HELP_ROOT,
    "/sample-setup": `${HELP_ROOT}#SL_CS_SampleSetUp_Top.htm`,
    "/runs": `${HELP_ROOT}#SL_CS_Runs_Top.htm`,
    "/analysis": `${HELP_ROOT}#SL_CS_SA_Top.htm`,
    "/data-management": `${HELP_ROOT}#SL_CS_DataMan_Top.htm`,
    "/instruments": `${HELP_ROOT}#SL_CS_Instruments_Top.htm`
}

const getDocRouteInfo = (route: string) => {
    let isAvailable: boolean = false
    let docRoute: string = ""

    Object.keys(routeDocMapping).forEach(routeKey => {
        if (route.startsWith(routeKey)) {
            isAvailable = true
            docRoute = routeDocMapping[routeKey]
        }
    })
    return { isAvailable, docRoute }
}

const openDocWindow = (route: string) => {
    window.open(
        `${process.env.PUBLIC_URL}/docs/${getDocRouteInfo(route).docRoute}`,
        "detab",
        "toolbar=0, top=100, left=500, width=800, height=800"
    )
}

type Props = ConnectedRouterProps<ApplicationState>

export const NavBar: FC<Props> = (props: Props) => {

    const dispatch = useDispatch()

    const token = useSelector<ApplicationState, string>( state => state.auth.token )
    const user = useSelector<ApplicationState, User>( state => state.auth.user )
    const needsChemistryBundleUpdate = useSelector<ApplicationState, boolean>( state => {
        const { chemistryBundleUpdateAvailableVersion, chemistryBundleCurrentVersion } = state.about
        return chemistryBundleUpdateAvailableVersion > chemistryBundleCurrentVersion
    })
    const needsUiBundleUpdate = useSelector<ApplicationState, boolean>( state => {
        const { uiBundleBundleUpdateAvailableVersion, uiBundleBundleCurrentVersion } = state.about
        return uiBundleBundleUpdateAvailableVersion > uiBundleBundleCurrentVersion
    })
    const pageName =  useSelector<ApplicationState, string>( state => state.core.currentPageName )

    const navBarProps: NavMenuProps = {
        token,
        user,
        needsChemistryBundleUpdate,
        needsUiBundleUpdate,
        pageName
    }

    useEffect(() => {
        const path: string = props.history.location.pathname
        let pageInfo: any

        Object.keys(pageNameMapping).forEach(key => {
            if (path.includes(key) && !path.includes("/settings")) {
                pageInfo = pageNameMapping[key]
            }
        })

        const name = pageInfo ? pageInfo.displayName : null
        dispatch(CoreActions.setCurrentPageName(name))
    })

    const route = props.history.location.pathname

    // For developer testing only of WSO2 timeouts
    function invalidateAccessToken() {
        // The purpose of this is to simulate an access token timeout.
        dispatch(setToken(
            { access_token: "BAD_ACCESS_TOKEN_TEST" }
        ))
        alert("Invalidated WSO2 access token")
    }

    // For developer testing only of WSO2 timeouts
    function invalidateRefreshToken() {
        // The purpose of this is to simulate a refresh token timeout.
        dispatch(setToken({
            access_token: "BAD_ACCESS_TOKEN_TEST",
            refresh_token: "BAD_REFRESH_TOKEN_TEST"
        }))
        alert("Invalidated WSO2 refresh token")
    }

    const allProps = {...navBarProps, ...props}
    return (
        <Navbar dark fixed="top" className="navbar-expand" role="navigation">
            <Container className="container-nav">
                <NavbarBrand
                    href={process.env.PUBLIC_URL || "/"}
                    title="Pacific Biosciences"
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/img/pb-logo.svg`}
                        width="auto"
                        height="30"
                        alt="Pacific Biosciences logo"
                    />
                </NavbarBrand>
                {token && (
                    <Fragment>
                        <Nav navbar>
                            <ModuleNavMenu {...navBarProps} />
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            <UtilityNavMenu {...allProps} />
                        </Nav>
                    </Fragment>
                )}
                {developerTestingForWSO2Timeouts && (
                    <Fragment>
                        <button onClick={() => invalidateAccessToken()}>
                            Invalidate WSO2 auth token
                        </button>
                        <button onClick={() => invalidateRefreshToken()}>
                            Invalidate WSO2 refresh token
                        </button>
                    </Fragment>
                )}
                {getDocRouteInfo(route).isAvailable && (
                    <NavLink
                        to={route}
                        onClick={() => openDocWindow(route)}
                        className="nav-link"
                        title="Help Documentation for this page"
                        aria-label="Help Documentation"
                    >
                        <span style={{ color: "grey", "marginLeft": "2rem" }}>
                            Help
                        </span>
                        <span className="sr-only">
                            Help Documentation for this page
                        </span>
                    </NavLink>
                )}
                { user &&
                    <UserNavMenu user={user} />
                }
            </Container>
        </Navbar>
    )
}

