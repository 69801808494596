import { UserPreferences } from "../../model/user-preferences-model"

export const UserPreferencesActionTypes = {
    SET_USER_PREFERENCES: "@@userPrefs/SET_USER_PREFERENCES",

    SAVE_USER_PREFERENCES_ATTEMPT: "@@userPrefs/SAVE_USER_PREFERENCES_ATTEMPT",
    SAVE_USER_PREFERENCES_SUCCESS: "@@userPrefs/SAVE_USER_PREFERENCES_SUCCESS",
    SAVE_USER_PREFERENCES_FAILURE: "@@userPrefs/SAVE_USER_PREFERENCES_FAILURE",
}

export interface UserPreferencesState {
  readonly userPreferences: UserPreferences
  readonly isSaving: boolean
  readonly saveError: Error | null
}

