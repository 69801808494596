import { Action, ActionCreator } from "redux"
import { InstrumentActionTypes, InstrumentSupport } from "./instrument-types"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { ThunkDispatch } from "redux-thunk"
import { InstrumentType, isRevio, isSequel2, isSequel2e } from "../../model/instrument-type-model"

export const setInstrumentSupport = (
    instrumentSupport: InstrumentSupport,
    currentInstrumentType: InstrumentType
): ThunkResult<void> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>) => {

        instrumentSupport = Object.assign(instrumentSupport, {sequel: false})

        if (!instrumentSupport.supportsRevio &&
            !instrumentSupport.supportsSequel2e &&
            !instrumentSupport.supportsSequel2
        ) {
            instrumentSupport = {
                supportsSequel: false,
                supportsSequel2: true,
                supportsSequel2e: true,
                supportsRevio: true }
        }

        if (!isRevio(currentInstrumentType) &&
            !isSequel2e(currentInstrumentType) &&
            !isSequel2(currentInstrumentType)
        ) {
            currentInstrumentType = "Revio"
        }

        if (!instrumentSupport.supportsRevio && isRevio(currentInstrumentType)) {
            instrumentSupport.supportsSequel2e
                ? dispatch(updateCurrentInstrumentType("Sequel2e"))
                : dispatch(updateCurrentInstrumentType("Sequel2"))
        }

        if (!instrumentSupport.supportsSequel2e && isSequel2e(currentInstrumentType)) {
            instrumentSupport.supportsRevio
                ? dispatch(updateCurrentInstrumentType("Revio"))
                : dispatch(updateCurrentInstrumentType("Sequel2"))
        }

        if (!instrumentSupport.supportsSequel2 && isSequel2(currentInstrumentType)) {
            instrumentSupport.supportsRevio
                ? dispatch(updateCurrentInstrumentType("Revio"))
                : dispatch(updateCurrentInstrumentType("Sequel2e"))
        }

        // Note: at least one instrument type should be available

        dispatch(updateInstrumentSupport(instrumentSupport))
    }

export const setInstrumentSupportOnly = (
    instrumentSupport: InstrumentSupport,
): ThunkResult<void> => async (
        dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
        getState: () => ApplicationState
    ) => {
        const { currentInstrumentType } = getState().instrument
        dispatch(setInstrumentSupport(instrumentSupport,currentInstrumentType))
    }


export const updateInstrumentSupport: ActionCreator<Action> = (instrumentSupport: InstrumentSupport) => ({
    type: InstrumentActionTypes.UPDATE_INSTRUMENT_SUPPORT,
    payload: instrumentSupport
})

export const updateCurrentInstrumentType: ActionCreator<Action> = (currentInstrumentType: InstrumentType) => ({
    type: InstrumentActionTypes.UPDATE_CURRENT_INSTRUMENT_TYPE,
    payload: currentInstrumentType
})

