import {BaseJob, BaseJobEvent, BaseTaskOption, JobStatusEvent} from "./job-model"
import { JobOptions, JobTaskOption } from "./job-options-model"
import { UiDatastoreFile } from "./ui-datastore-file"
import { Dataset } from "./dataset-model"
import { PipelineTemplate, PipelineTaskOption } from "./pipeline-template-models"

export interface PipelineDetails { // cached props from JobDetails (in angular, part of results.ts job processing)
    pipeline: PipelineTemplate
    uiTaskOptions: UiTaskOption[]
    jobApplicationName: string
}
export interface JobDetails {
    job: UiJob
    events: UiJobEvent[]
    taskStatusEvents: JobStatusEvent[]
    datasetLinks: DatasetLink[]
    log: string
    options: JobOptions
    downloadFiles: UiDatastoreFile[]
    igvFiles: UiDatastoreFile[]
    allFiles: UiDatastoreFile[]
    primaryDataset?: Dataset
    pipelineDetails: PipelineDetails
}


export interface UiJob extends BaseJob {
    createdAt?: Date
    importedAt?: Date
    updatedAt?: Date
    analysisApplication?: string
    workflow?: string
    projectId?: number
    isActive?: boolean
}

export interface UiJobEvent extends BaseJobEvent {
    createdAt?: Date
}

export interface UiTaskOption extends BaseTaskOption {
    name: string
    value: any
}

export interface DatasetLink {
    id?: number
    uuid?: string
    fileTypeId?: string
    title?: string
    route?: string
    isMerged?: boolean
    jobId?: number
    parentUuid?: string | null
    isAccessible: boolean
}

export class UiTaskOptionBuilder  {

    // References to jobTaskOption["optionId"] are to support the older 4.0 schema

    static fromJobTaskOptionAndPipelineTaskOption(
        jobTaskOption: JobTaskOption, pipelineTaskOption?: PipelineTaskOption): UiTaskOption {
            return {
                id: jobTaskOption.id || jobTaskOption["optionId"],
                optionTypeId: jobTaskOption.optionTypeId,
                value: jobTaskOption.value,
                name: pipelineTaskOption && pipelineTaskOption.name ? pipelineTaskOption.name : jobTaskOption.id
            }
    }

    static dummyOption(): UiTaskOption {
          return {
            id: null,
            optionTypeId: "string",
            value: "",
            name: "none"
        }
    }

    static uiTaskOptionsForPipelineAndJob(pipeline: PipelineTemplate, jobOptions: JobOptions): UiTaskOption[] {

        const uiTaskOptions: UiTaskOption[] = []

        if (pipeline.taskOptions) {
            const optionsMap = {}
            for (let option of pipeline.taskOptions) {
                optionsMap[option.id] = option
            }
            const sortedJobTaskOptions = (jobOptions.taskOptions || []).sort(
                (x, y) => (x.id || x["optionId"] || "").localeCompare(y.id || y["optionId"]|| "")
            )

            for (let jobTaskOption of sortedJobTaskOptions) {
                let pipelineTaskOption: PipelineTaskOption = optionsMap[jobTaskOption.id || jobTaskOption["optionId"]]
                uiTaskOptions.push(this.fromJobTaskOptionAndPipelineTaskOption(jobTaskOption, pipelineTaskOption))
            }
        }
        if (uiTaskOptions.length === 0) {
            return [this.dummyOption()]
        }
        return uiTaskOptions
    }
}
