import { NotificationModel } from "../../model/notification-model"

export const NotificationsActionTypes = {
  API_ATTEMPT: "@@notifications/API_ATTEMPT",
  API_FAILURE: "@@notifications/API_FAILURE",

  SET_NOTIFICATIONS: "@@notifications/SET_NOTIFICATIONS",
}

export interface NotificationsState {
  readonly loading: boolean
  readonly error: Error | null
  readonly notifications: NotificationModel[]
}
