import { Reducer } from "redux"
import { AboutApiState, AboutActionTypes } from "./about-api-types"

const initialState: AboutApiState = {
    chemistryBundleCurrentVersionLoading: false,
    chemistryBundleCurrentVersion: null,
    chemistryBundleCurrentVersionError: null,

    chemistryBundleUpdateAvailableLoading: false,
    chemistryBundleUpdateAvailable: false,
    chemistryBundleUpdateAvailableVersion: null,
    chemistryBundleUpdateAvailableDescription: null,
    chemistryBundleUpdateAvailableError: null,

    chemistryBundleUpgradeLoading: false,
    chemistryBundleUpgradeFinished: false,
    chemistryBundleUpgradeError: null,

    uiBundleBundleCurrentVersionLoading: false,
    uiBundleBundleCurrentVersion: null,
    uiBundleBundleCurrentVersionError: null,

    uiBundleBundleUpdateAvailableLoading: false,
    uiBundleBundleUpdateAvailable: false,
    uiBundleBundleUpdateAvailableVersion: null,
    uiBundleBundleUpdateAvailableDescription: null,
    uiBundleBundleUpdateAvailableError: null,

    uiBundleBundleUpgradeLoading: false,
    uiBundleBundleUpgradeFinished: false,
    uiBundleBundleUpgradeError: null
}

export const aboutReducer: Reducer<AboutApiState> = (state: AboutApiState = initialState, action: any) => {
    switch (action.type) {

        // chemistry bundle current version

        case AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                chemistryBundleCurrentVersionLoading: true,
                chemistryBundleCurrentVersionError: null
            })

        case AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                chemistryBundleCurrentVersionLoading: false,
                chemistryBundleCurrentVersion: action.payload.chemistryBundleCurrentVersion
            })

        case AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                chemistryBundleCurrentVersionLoading: false,
                chemistryBundleCurrentVersionError: action.payload
            })

        // chemistry bundle available version

        case AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                chemistryBundleUpdateAvailableLoading: true,
                chemistryBundleUpdateAvailableError: null
            })

        case AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                chemistryBundleUpdateAvailableLoading: false,
                chemistryBundleUpdateAvailable: action.payload.chemistryBundleUpdateAvailable,
                chemistryBundleUpdateAvailableVersion: action.payload.chemistryBundleUpdateAvailableVersion,
                chemistryBundleUpdateAvailableDescription: action.payload.chemistryBundleUpdateAvailableDescription
            })

        case AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                chemistryBundleUpdateAvailableLoading: false,
                chemistryBundleUpdateAvailableError: action.payload
            })

        // chemistry bundle upgrade

        case AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                chemistryBundleUpgradeLoading: true,
                chemistryBundleUpgradeFinished: false,
                chemistryBundleUpgradeError: null
            })

        case AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                chemistryBundleUpgradeLoading: false,
                chemistryBundleUpgradeFinished: true,
                chemistryBundleUpdateAvailable: false,
                chemistryBundleUpdateAvailableVersion: null,
                chemistryBundleUpdateAvailableDescription: null,
                chemistryBundleCurrentVersion: action.payload.chemistryBundleCurrentVersion
            })

        case AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                chemistryBundleUpgradeLoading: false,
                chemistryBundleUpgradeError: action.payload
            })

        // ui bundle current version

        case AboutActionTypes.API_ATTEMPT_UI_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleCurrentVersionLoading: true,
                uiBundleBundleCurrentVersionError: null
            })

        case AboutActionTypes.API_SUCCESS_UI_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleCurrentVersionLoading: false,
                uiBundleBundleCurrentVersion: action.payload.uiBundleBundleCurrentVersion
            })

        case AboutActionTypes.API_FAILURE_UI_BUNDLE_CURRENT_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleCurrentVersionLoading: false,
                uiBundleBundleCurrentVersionError: action.payload
            })

        // ui bundle available version

        case AboutActionTypes.API_ATTEMPT_UI_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleUpdateAvailableLoading: true,
                uiBundleBundleUpdateAvailableError: null
            })

        case AboutActionTypes.API_SUCCESS_UI_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleUpdateAvailableLoading: false,
                uiBundleBundleUpdateAvailable: action.payload.uiBundleBundleUpdateAvailable,
                uiBundleBundleUpdateAvailableVersion: action.payload.uiBundleBundleUpdateAvailableVersion,
                uiBundleBundleUpdateAvailableDescription: action.payload.uiBundleBundleUpdateAvailableDescription
            })

        case AboutActionTypes.API_FAILURE_UI_BUNDLE_UPDATE_AVAILABLE_VERSION:
            return Object.assign({}, state, {
                uiBundleBundleUpdateAvailableLoading: false,
                uiBundleBundleUpdateAvailableError: action.payload
            })

        // ui bundle upgrade

        case AboutActionTypes.API_ATTEMPT_UI_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                uiBundleBundleUpgradeLoading: true,
                uiBundleBundleUpgradeFinished: false,
                uiBundleBundleUpgradeError: null
            })

        case AboutActionTypes.API_SUCCESS_UI_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                uiBundleBundleUpgradeLoading: false,
                uiBundleBundleUpgradeFinished: true,
                uiBundleBundleUpdateAvailable: false,
                uiBundleBundleUpdateAvailableVersion: null,
                uiBundleBundleUpdateAvailableDescription: null,
                uiBundleBundleCurrentVersion: action.payload.uiBundleBundleCurrentVersion
            })

        case AboutActionTypes.API_FAILURE_UI_BUNDLE_UPGRADE:
            return Object.assign({}, state, {
                uiBundleBundleUpgradeLoading: false,
                uiBundleBundleUpgradeError: action.payload
            })

        // default

        default:
            return state
    }
}
