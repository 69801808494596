import { SelectionMap } from "../../../core-components/shared/DrillDownTable/drill-down-types"

export type SelectorType = "projects" | "datasets"
export const DEFAULT_ID_FIELD = "uuid"

export const DatasetSelectorActionTypes = {

    SET_SELECTOR_TYPE: "@@dataset-selector/SET_SELECTOR_TYPE",
    SET_SELECTION_MAP: "@@dataset-selector/SET_SELECTION_MAP",

    SET_IS_FLAT_MODE: "@@dataset-selector/SET_IS_FLAT_MODE"
}

export interface DatasetSelectorState {
    readonly selectorType: SelectorType
    readonly selectionMap: SelectionMap
    readonly isFlatMode: boolean
}
