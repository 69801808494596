import { Reducer } from "redux"
import { CoreActionTypes, CoreState } from "./core-types"

const initialState: CoreState = {
    currentPageName: ""
}

export const coreReducer: Reducer<CoreState> = (state: CoreState = initialState, action: any) => {
    switch (action.type) {
        case CoreActionTypes.SET_CURRENT_PAGE_NAME:
            return Object.assign({}, state, {
                currentPageName: action.payload
            })

        default:
            return state
    }
}
