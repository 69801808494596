import React, { FunctionComponent, /*StrictMode*/ } from "react"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { Store } from "redux"
import { History } from "history"
import { Persistor } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

import { Routes } from "./core/Routes"
import { ApplicationState } from "./core/types"

import { ModalPostInstallPrompt } from "./core-components/shared/ModalPostInstallPrompt"

import "bootstrap/dist/css/bootstrap.min.css"

interface MainProps {
    store: Store<ApplicationState>
    history: History
    persistor: Persistor
}

export const Main: FunctionComponent<MainProps> = ({ store, history, persistor }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    {/* <StrictMode> */}
                        <Routes />
                        <ModalPostInstallPrompt />
                    {/* </StrictMode> */}
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    )
}
