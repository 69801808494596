import { Job } from "../../../model/job-model"

export const MultiJobPollingTypes = {
    SET_MULTIJOB_JOBS: "@@multijob-polling/SET_MULTIJOB_JOBS",
    START_POLLING: "@@multijob-polling/START_POLLING",
    STOP_POLLING: "@@multijob-polling/START_POLLING",
    SET_IS_CANCELLED: "@@multijob-polling/SET_IS_CANCELLED"

}

export interface MultiJobPollingState {
    readonly multiJobJobs: Job[]
    readonly isPolling: boolean
    readonly isCancelled: boolean
    readonly error: Error | null
}

