import { JobDetails } from "../../../model/ui-job-model"
import { UiReport } from "../../../model/ui-report-model"


export const AnalysisResultsTypes = {

  SET_IS_VALID_JOB: "@@analysis-results/SET_IS_VALID_JOB",

  API_ATTEMPT_JOB_DETAILS: "@@analysis-results/API_ATTEMPT_JOB_DETAILS",
  API_SUCCESS_JOB_DETAILS: "@@analysis-results/API_SUCCESS_JOB_DETAILS",
  API_FAILURE_JOB_DETAILS: "@@analysis-results/API_FAILURE_JOB_DETAILS",
  API_ATTEMPT_REPORTS: "@@analysis-results/API_ATTEMPT_REPORTS",
  API_SUCCESS_REPORTS: "@@analysis-results/API_SUCCESS_REPORTS",
  API_FAILURE_REPORTS: "@@analysis-results/API_FAILURE_REPORTS",


  SET_JOB_DETAILS: "@@analysis-results/SET_JOB_DETAILS",
  SET_REPORTS: "@@analysis-results/SET_REPORTS",

  API_ATTEMPT: "@@analysis-results/API_ATTEMPT",
  API_SUCCESS: "@@analysis-results/API_SUCCESS",
  API_FAILURE: "@@analysis-results/API_FAILURE"
}


export interface AnalysisResultsApiState {
    readonly loading: boolean
    readonly isValidJob: boolean | null
    readonly loadingJobDetails: boolean
    readonly loadingReports: boolean
    readonly loadingAnalysisJobs: boolean
    readonly jobDetails: JobDetails | null
    readonly reports: UiReport[] | null
    readonly error: Error | null
}
