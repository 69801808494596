import { analysisResultsLink, runSummaryLink } from "../../../core/Routes"
import { UiRun, UiCollection, UiBiosample } from "../../../data/model/ui-run-model"
import { SortModel } from "../../../data/services/grid-storage-service"
import { createLinkCellRenderer } from "../../../core/utils/ag-grid-helper"
import { smrtLinkDateFormat } from "../../../core/utils/smrt-link-date-format"
import { AllRunData } from "../../../data/stores/run-selector/run-selector-types"
import { SelectionMap, ROOT, DrillDownColRef } from "../DrillDownTable/drill-down-types"
import { GridSelectionMode } from "../Grid"

export type Level = "Runs" | "Collections" | "Samples" | "Unset"

export interface RunSelectorTableState {
    level: Level
    parentRun?: UiRun,
    parentCollection?: UiCollection,
    idField?: string,
    nameField?: string,
    linkField?: string,
    mainHeaderPrependItems: DrillDownColRef[]
    mainHeaderItems: DrillDownColRef[]
    columnDefs: DrillDownColRef[]
    selectionMode?: GridSelectionMode
    isSelectable?: (data:any) => boolean
    hideSelectAll?: boolean
    sortModel?: SortModel
    headerCheckboxSelectionFilteredOnly?: boolean
}

export const INITIAL_TABLE_STATE: RunSelectorTableState = {
    level: "Unset",
    idField: "",
    mainHeaderPrependItems: [],
    mainHeaderItems: [],
    columnDefs: [],
    hideSelectAll: false
}

export const createRunSelectionMap = () => {
    const DELIMITER_THAT_IS_NEVER_IN_A_UUID = "|"
    const keyGenerator = (data: AllRunData) => {
        if (!data) {
            return ROOT
        }
        const level = getLevel(data)
        const idField = idFieldsMap[level]
        const key = level + DELIMITER_THAT_IS_NEVER_IN_A_UUID + data[idField]
        return key
    }
    const idFromKey = key => key.split(DELIMITER_THAT_IS_NEVER_IN_A_UUID)[1]
    return new SelectionMap(keyGenerator, idFromKey)

}

export const getLevel = (data: AllRunData): Level => {
    if (!data) { return null }
    if (idFieldsMap.Samples in data)  { return "Samples" }
    if (idFieldsMap.Collections in data)  { return "Collections" }
    if (idFieldsMap.Runs in data)  { return "Runs" }
    return null
}
export const nameFieldsMap = {
    Runs: "name",
    Collections: "collectionName",
    Samples: null
}
export const idFieldsMap = {
    Runs: "uniqueId",
    Collections: "collectionUniqueId",
    Samples: "barcodeUniqueId"
}

export const runTableState = (
    drillDown: (run: UiRun) => void): RunSelectorTableState  =>  {
    return {
            level: "Runs",
            parentRun: null,
            parentCollection: null,
            idField: "uniqueId",
            nameField: "name",
            linkField: "totalCells",
            mainHeaderItems: [],
            mainHeaderPrependItems: [],
            selectionMode: "None",
            hideSelectAll: true,
            columnDefs: [
                {
                    headerName: "Name",
                    field: "name",
                    cellRenderer: createLinkCellRenderer( (run: UiRun) => {
                        return runSummaryLink(run.uniqueId, true)
                    })
                },
                {
                    headerName: "Collections",
                    field: "totalCells",
                    drillDown: drillDown
                },
                {
                    headerName: "Multi-analysis ID",
                    field: "multiJobId",
                    cellRenderer: createLinkCellRenderer( (run: UiRun) => {
                        return analysisResultsLink(run.multiJobId)
                    })
                },
                {
                    headerName: "Created At",
                    field: "createdAt",
                    cellRenderer: (params) => { return smrtLinkDateFormat(new Date(params.value)) },
                    sort: "desc"
                },
                {
                    headerName: "Summary",
                    field: "runSummary"
                },
                {
                    headerName: "Created By",
                    field: "createdBy"
                }
            ]
        }
}

export const collectionsTableState = (
    parentRun: UiRun,
    drillDown: (collection: UiCollection) => void):RunSelectorTableState =>  {

    return {
            level: "Collections",
            parentRun,
            parentCollection: null,
            idField: "collectionUniqueId",
            nameField: "collectionName",
            linkField: "numChildren",
            mainHeaderItems: [],
            mainHeaderPrependItems: [],
            selectionMode: "Single",
            hideSelectAll: true,
            isSelectable: ( collection: UiCollection) => {
                return !collection.jobId
            },
            columnDefs: [
                {
                    headerName: "Name",
                    field: "collectionName"
                },
                {
                    headerName: "Barcoded Samples",
                    field: "numChildren",
                    drillDown: drillDown
                },
                {
                    headerName: "Sample Well",
                    field: "wellName"
                },
                {
                    headerName: "Job ID",
                    field: "jobId",
                    cellRenderer: createLinkCellRenderer( (collection: UiCollection) => {
                        return analysisResultsLink(collection.jobId)
                    })
                },
                {
                    headerName: "MultiJob Id",
                    field: "multiJobId",
                    cellRenderer: createLinkCellRenderer( (collection: UiCollection) => {
                        return analysisResultsLink(collection.multiJobId)
                    })

                }
            ]
        }
}

const biosampleIsSelectable = ( biosample: UiBiosample) => {
    return !biosample.jobId
}



export const biosampleTableState = (parentRun: UiRun, parentCollection: UiCollection): RunSelectorTableState =>  {

        return {
        level: "Samples",
        parentRun,
        parentCollection,
        idField: "barcodeUniqueId",
        mainHeaderItems: [],
        mainHeaderPrependItems: [],
        hideSelectAll: false,
        selectionMode: "Multiple",
        headerCheckboxSelectionFilteredOnly: true,
        isSelectable: biosampleIsSelectable,
        columnDefs: [
            {
                headerName: "Barcode Name",
                field: "barcodeName"
            },
            {
                headerName: "Bio Sample Name",
                field: "bioSampleName"
            },
            {
                headerName: "Job ID",
                field: "jobId",
                cellRenderer: createLinkCellRenderer( (biosample: UiBiosample) => {
                    return analysisResultsLink(biosample.jobId, true)
                })
            },
            {
                headerName: "MultiJob Id",
                field: "multiJobId",
                cellRenderer: createLinkCellRenderer( (biosample: UiBiosample) => {
                    return analysisResultsLink(biosample.multiJobId, true)
                })
            }
        ]
        }
 }


