import React, { FC, useState } from "react"
import { Form, Input, FormGroup, Label, FormFeedback, Button } from "reactstrap"
import { SMButton } from "../../SMButton"
import { LoginPayload } from "../store/login-types"
import { Formik, FormikProps } from "formik"

interface Props {
    loading: boolean
    errorMessage: string
    onLogin(payload: LoginPayload): void
}

interface FormValues {
    username: string
    password: string
}

const INITIAL_VALUE: FormValues = {
    username: "",
    password: ""
}

export const LoginForm: FC<Props> = (props: Props) => {
    // Error messages are cached to deal with username changing after they are first processed.
    const [originalErrorMessage, setOriginalErrorMessage] = useState<string>("")
    const [cleanedUpErrorMessage, setCleanedUpErrorMessage] = useState<string>("")

    const onLogin = (values: FormValues) => {
        const { username, password } = values
        const payload: LoginPayload = { username, password }
        props.onLogin(payload)
    }

    const validate = (values: FormValues): any => {
        const errors: any = {}

        if (!values.username) {
            errors.username = "Required"
        }

        if (!values.password) {
            errors.password = "Required"
        }

        return errors
    }

    const cleanupErrorMessage = (errorMessage: string, userName: string): string => {
        // This verifies the message format and username to reduce the risk of munging other error messages.
        // It is intended to remove the stuff after the @ like in: "Authentication failed for admin@carbon.super".
        // useMemo() was not used here due to Formik keeping the username internal to the form.
        if (errorMessage === originalErrorMessage) { return cleanedUpErrorMessage }
        const [first, rest] = ("" + errorMessage).split("@", 2)
        if (rest && first.endsWith(userName)) {
            setOriginalErrorMessage(errorMessage)
            setCleanedUpErrorMessage(first)
            return first
        }
        setOriginalErrorMessage(errorMessage)
        setCleanedUpErrorMessage(errorMessage)
        return errorMessage
    }

    return (
        <Formik initialValues={INITIAL_VALUE} validate={validate} onSubmit={onLogin}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }: FormikProps<FormValues>) => (
                <div className="login-form">
                    <h2 className="h1 mb-4">Please Sign In</h2>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="userInput">Username</Label>
                            <Input
                                className="username"
                                name="username"
                                type="text"
                                id="userInput"
                                autoComplete="username"
                                value={values.username}
                                invalid={!!errors.username && touched.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.username && touched.username && (
                                <FormFeedback invalid="true">{errors.username}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="passwordInput">Password</Label>
                            <Input
                                className="password"
                                name="password"
                                type="password"
                                id="passwordInput"
                                autoComplete="current-password"
                                value={values.password}
                                invalid={!!errors.password && touched.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.password && touched.password && (
                                <FormFeedback invalid="true">{errors.password}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-0">
                            <SMButton type="submit" color="primary" loading={props.loading}>
                                Log In
                            </SMButton>
                        </FormGroup>
                        {props.errorMessage && (
                            <div style={{color: "red", marginTop: "0.5rem"}}>
                                {cleanupErrorMessage(props.errorMessage, values.username)}
                            </div>
                        )}
                        {props.errorMessage
                            && (cleanupErrorMessage(props.errorMessage, values.username)
                                .includes("Account is not fully set up")) && (
                            <div>
                                <br></br>
                                <Button
                                    onClick={() => {
                                        window.location.href = "https://" + window.location.hostname + ":9443/auth/realms/SMRTLink/account/#/security/signingin"
                                    }}
                                    >
                                    Update Password
                                </Button>
                            </div>
                        )}
                    </Form>
                </div>
            )}
        </Formik>
    )
}
