import { ActionCreator, Action, Dispatch } from "redux"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { errorHandler } from "../../../core/utils/error-handler"

import * as API from "../../api"
import { AboutActionTypes } from "./about-api-types"
import { ResourceNotFoundError } from "../../api"
import { ApiResponse } from "apisauce"
import { VersionManifestService } from "../../services/version-manifest-service"

const CHEMISTRY_BUNDLE_ID = "chemistry-pb"
const UI_BUNDLE_ID = "smrtlink-ui"

// chemistry bundle actions

export const getChemistryBundleCurrentVersion = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(apiAttemptChemistryBundleCurrentVersion())

    try {
        const response: any = await API.chemistryBundleVersion()
        if (response.ok) {
            dispatch(apiSuccessChemistryBundleCurrentVersion({ chemistryBundleCurrentVersion: response.data.version }))
        } else {
            throw response.data
        }
    } catch (err) {
        dispatch(apiFailureChemistryBundleCurrentVersion(err))
        errorHandler(dispatch, err)
    }
}

export const checkForChemistryBundleUpgrade = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(apiAttemptChemistryBundleUpdateAvailableVersion())

    try {
        const response: any = await API.checkBundleUpgrade(CHEMISTRY_BUNDLE_ID)

        if (response.ok) {
            const chemistryBundleUpdateAvailable = !!response.data && !!response.data.bundle
            const payload: any = { chemistryBundleUpdateAvailable }

            if (chemistryBundleUpdateAvailable) {
                payload.chemistryBundleUpdateAvailableVersion = response.data.bundle.version
                payload.chemistryBundleUpdateAvailableDescription = response.data.bundle.description
            } else {
                payload.chemistryBundleUpdateAvailableVersion = null
                payload.chemistryBundleUpdateAvailableDescription = null
            }
            dispatch(apiSuccessChemistryBundleUpdateAvailableVersion(payload))
        } else {
            throw response.data
        }
    } catch (err) {
        dispatch(apiFailureChemistryBundleUpdateAvailableVersion(err))
        errorHandler(dispatch, err)
    }
}

export const doChemistryBundleUpgrade = (doneCallback: (error: any) => void): ThunkResult<Promise<void>> =>
        async (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(apiAttemptChemistryBundleUpgrade())

    try {
        const { about } = getState()
        const response: any = await API.doBundleUpgrade(CHEMISTRY_BUNDLE_ID, about.chemistryBundleUpdateAvailableVersion)

        if (response.ok) {
            const payload: any = {
                chemistryBundleCurrentVersion: about.chemistryBundleUpdateAvailableVersion
            }
            dispatch(apiSuccessChemistryBundleUpgrade(payload))
            doneCallback(null)
        } else {
            throw response.data
        }
    } catch (err) {
        dispatch(apiFailureChemistryBundleUpgrade(err))
        doneCallback(err)
        errorHandler(dispatch, err)
    }
}

// UI bundle actions

export const getUiBundleCurrentVersion = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(apiAttemptUiBundleCurrentVersion())

    try {
        let response: any
        try {
            response = await API.uiBundleVersion()
        } catch (err) {
            if (err instanceof ResourceNotFoundError) {
                // Special handling for 404 error for fresh install without a ui bundle update
                const verisionManifest = await VersionManifestService.getVersionManifest()
                if (verisionManifest && verisionManifest["smrtlink-analysisservices-gui"]) {
                    dispatch(apiSuccessUiBundleCurrentVersion({ uiBundleBundleCurrentVersion: verisionManifest["smrtlink-analysisservices-gui"].version }))
                    return
                }
            } else {
                throw response.data
            }
        }
        if (response.ok) {
            dispatch(apiSuccessUiBundleCurrentVersion({ uiBundleBundleCurrentVersion: response.data.version }))
        } else {
            throw response.data
        }
    } catch (err) {
        if (err instanceof ResourceNotFoundError) {
            // Special handling for 404 error for fresh install without a ui bundle update
            const response: ApiResponse<any> = await API.slStatus()
            if (response.ok) {
                dispatch(apiSuccessUiBundleCurrentVersion({ uiBundleBundleCurrentVersion: response.data.version }))
                return
            }
        } else {
            dispatch(apiFailureUiBundleCurrentVersion(err))
            errorHandler(dispatch, err)
        }
    }
}

export const checkForUiBundleUpgrade = (): ThunkResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(apiAttemptUiBundleUpdateAvailableVersion())

    try {
        const response: any = await API.checkBundleUpgrade(UI_BUNDLE_ID)
        if (response.ok) {
            const uiBundleBundleUpdateAvailable = !!response.data && !!response.data.bundle
            const payload: any = { uiBundleBundleUpdateAvailable }

            if (uiBundleBundleUpdateAvailable) {
                payload.uiBundleBundleUpdateAvailableVersion = response.data.bundle.version
                payload.uiBundleBundleUpdateAvailableDescription = response.data.bundle.description
            } else {
                payload.uiBundleBundleUpdateAvailableVersion = null
                payload.uiBundleBundleUpdateAvailableDescription = null
            }
            dispatch(apiSuccessUiBundleUpdateAvailableVersion(payload))
        } else {
            throw response.data
        }
    } catch (err) {
        dispatch(apiFailureUiBundleUpdateAvailableVersion(err))
        errorHandler(dispatch, err)
    }
}

export const doUiBundleUpgrade = (doneCallback: (error: any) => void): ThunkResult<Promise<void>> =>
        async (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(apiAttemptUiBundleUpgrade())

    try {
        const { about } = getState()
        const response: any = await API.doBundleUpgrade(UI_BUNDLE_ID, about.uiBundleBundleUpdateAvailableVersion)

        if (response.ok) {
            const payload: any = {
                uiBundleBundleCurrentVersion: about.uiBundleBundleUpdateAvailableVersion
            }
            dispatch(apiSuccessUiBundleUpgrade(payload))
            doneCallback(null)
        } else {
            throw response.data
        }
    } catch (err) {
        dispatch(apiFailureUiBundleUpgrade(err))
        doneCallback(err)
        // errorHandler(dispatch, err)
    }
}

// chemistry bundle current version

export const apiAttemptChemistryBundleCurrentVersion: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_CURRENT_VERSION
})

export const apiSuccessChemistryBundleCurrentVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_CURRENT_VERSION,
    payload: data
})

export const apiFailureChemistryBundleCurrentVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_CURRENT_VERSION,
    payload: data
})

// chemistry bundle update available

export const apiAttemptChemistryBundleUpdateAvailableVersion: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION
})

export const apiSuccessChemistryBundleUpdateAvailableVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION,
    payload: data
})

export const apiFailureChemistryBundleUpdateAvailableVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION,
    payload: data
})

// chemistry bundle upgrade

export const apiAttemptChemistryBundleUpgrade: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_CHEMISTRY_BUNDLE_UPGRADE
})

export const apiSuccessChemistryBundleUpgrade: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_CHEMISTRY_BUNDLE_UPGRADE,
    payload: data
})

export const apiFailureChemistryBundleUpgrade: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_CHEMISTRY_BUNDLE_UPGRADE,
    payload: data
})

// ui bundle current version

export const apiAttemptUiBundleCurrentVersion: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_UI_BUNDLE_CURRENT_VERSION
})

export const apiSuccessUiBundleCurrentVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_UI_BUNDLE_CURRENT_VERSION,
    payload: data
})

export const apiFailureUiBundleCurrentVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_UI_BUNDLE_CURRENT_VERSION,
    payload: data
})

// ui bundle available

export const apiAttemptUiBundleUpdateAvailableVersion: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_UI_BUNDLE_UPDATE_AVAILABLE_VERSION
})

export const apiSuccessUiBundleUpdateAvailableVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_UI_BUNDLE_UPDATE_AVAILABLE_VERSION,
    payload: data
})

export const apiFailureUiBundleUpdateAvailableVersion: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_UI_BUNDLE_UPDATE_AVAILABLE_VERSION,
    payload: data
})

// ui bundle upgrade

export const apiAttemptUiBundleUpgrade: ActionCreator<Action> = () => ({
    type: AboutActionTypes.API_ATTEMPT_UI_BUNDLE_UPGRADE
})

export const apiSuccessUiBundleUpgrade: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_SUCCESS_UI_BUNDLE_UPGRADE,
    payload: data
})

export const apiFailureUiBundleUpgrade: ActionCreator<Action> = (data: any) => ({
    type: AboutActionTypes.API_FAILURE_UI_BUNDLE_UPGRADE,
    payload: data
})
