import { RunQcObject } from "../../model/run-qc-model"
import { UiCollection, UiBiosample } from "../../model/ui-run-model"
import { SelectionMap } from "../../../core-components/shared/DrillDownTable/drill-down-types"

export const RunSelectorActionTypes = {
  API_ATTEMPT: "@@run-selector/API_ATTEMPT",
  API_SUCCESS: "@@run-selector/API_SUCCESS",
  API_FAILURE: "@@run-selector/API_FAILURE",

  SET_SELECTOR_TYPE: "@@run-selector/SET_SELECTOR_TYPE",
  SET_SELECTION_MAP: "@@run-selector/SET_SELECTION_MAP",

  GET_RUN_DATA_SUCCESS: "@@run-selector/GET_RUN_DATA_SUCCESS"
}

export type AllRunData = RunQcObject | UiCollection | UiBiosample
export type AnalysisRunData = UiCollection | UiBiosample

export interface RunSelectorState {
  readonly loading: boolean
  readonly data: Array<AllRunData>
  readonly error: Error | null
  readonly runSelectionMap: SelectionMap
}
