import { RunObject, SampleObject, AutomationParameterObject, Consumable } from "../../../model/run-model"
import { XMLElement, XMLDocument, NamespaceMap } from "../../../../core/utils/xml"
import { GENERAL_PROJECT_ID } from "../../../services/project-service"
import { remapObsoleteApplicationIfNeeded } from "../../../../silos/sample-setup/utils/binding-calculator/sample"
import { CollectionStateValue } from "../../../model/run-qc-model"
import { makeNewBarcode } from "../../../../silos/runs/utils/helpers"
import { ChipType, InstrumentType, getDefaultInstrumentTypeForChipType, isRevio } from "../../../model/instrument-type-model"

export const parseXML = (xml: string): RunObject => {
    const designNSMap: NamespaceMap = {
        // default = pbbase here: http://web/~mkocher/docs/xsds/PacBioDataModel.xsd#ns_pbbase
        default: "http://pacificbiosciences.com/PacBioBaseDataModel.xsd",
        pbbase: "http://pacificbiosciences.com/PacBioBaseDataModel.xsd",
        xsi: "http://www.w3.org/2001/XMLSchema-instance",
        pbds: "http://pacificbiosciences.com/PacBioDatasets.xsd",
        pbdm: "http://pacificbiosciences.com/PacBioDataModel.xsd",
        pbrk: "http://pacificbiosciences.com/PacBioReagentKit.xsd",
        pbsample: "http://pacificbiosciences.com/PacBioSampleInfo.xsd",
        pbpn: "http://pacificbiosciences.com/PacBioPartNumbers.xsd",
        pbmeta: "http://pacificbiosciences.com/PacBioCollectionMetadata.xsd"
    }

    const doc = XMLDocument.fromString(xml, designNSMap)

    const DEMUX_PRESET = "DemultiplexPreset"
    const PRESET_BC_SYMM = "SYMMETRIC-ADAPTERS"

    const run: RunObject = {}

    const pbdm = doc.getElement("/pbdm:PacBioDataModel")
    if (!pbdm) {
        throw new Error("Missing PacBioDataModel element")
    }

    const experimentContainer = pbdm.getElement("pbdm:ExperimentContainer")
    if (!experimentContainer) {
        throw new Error("Missing ExperimentContainer element")
    }

    const runElement = experimentContainer.getElement(".//pbdm:Run")
    if (!runElement) {
        throw new Error("Missing Run element")
    }

    run.experimentName = experimentContainer.getAttribute("Name")
    run.experimentDescription = experimentContainer.getAttribute("Description")
    run.experimentId = experimentContainer.getAttribute("ExperimentId")
    run.runName = runElement.getAttribute("Name")
    run.runDescription = runElement.getAttribute("Description")
    run.uuid = runElement.getAttribute("UniqueId")
    run.chipType = runElement.getAttribute("ChipType") as ChipType
    const instrumentTypeAttr = runElement.getAttribute("InstrumentType") as InstrumentType
    run.instrumentType = instrumentTypeAttr || getDefaultInstrumentTypeForChipType(run.chipType)

    let recordedEventMap = {} // Map events to sample uuid
    runElement.getElements("pbdm:RecordedEvents/pbdm:RecordedEvent").forEach(recordedEvent => {
        // RecordedEvents are encoded in one of two ways:

        let context = recordedEvent.getAttribute("Context")

        if (!recordedEventMap[context]) {
            recordedEventMap[context] = []
        }
        recordedEventMap[context].push(recordedEvent)
    })

    run.samples = runElement.getElements(".//pbds:SubreadSet").map(sampleElement => {
        const sample: SampleObject = {}

        sample.subreadSetUuid = sampleElement.getAttribute("UniqueId")

        const metadataElement = sampleElement.getElement(
            "pbds:DataSetMetadata/pbmeta:Collections/pbmeta:CollectionMetadata"
        )
        sample.collectionMetadataUuid = metadataElement.getAttribute("UniqueId")
        const ccsElement = metadataElement.getElement("pbmeta:ConsensusReadSetRef")
        sample.multiJobId = metadataElement.getNumber("pbmeta:MultiJobId")
        sample.projectId = metadataElement.getNumber("pbmeta:ProjectId") || GENERAL_PROJECT_ID
        const wellElement = metadataElement.getElement("pbmeta:WellSample")
        sample.sampleName = wellElement.getAttribute("Name")
        sample.sampleDescription = wellElement.getAttribute("Description")
        sample.wellName = wellElement.getString("pbmeta:WellName")
        sample.application = remapObsoleteApplicationIfNeeded(run.chipType, wellElement.getString("pbmeta:Application"))
        sample.biosampleName = wellElement.getString("pbmeta:BioSample")
        sample.insertSize = wellElement.getNumber("pbmeta:InsertSize")
        sample.isIsoseq = wellElement.getString("pbmeta:IsoSeq").toLowerCase() === "true"
        sample.isCLR = wellElement.getString("pbmeta:IsCCS").toLowerCase() !== "true"
        sample.AutoAnalysisEnabled = wellElement.getString("pbmeta:AutoAnalysisEnabled").toLowerCase() === "true"
        sample.AutoAnalysisName = wellElement.getString("pbmeta:AutoAnalysisName") || ""
        if (wellElement.getString("pbmeta:SampleSetupId")) {
            sample.sampleGuid = wellElement.getString("pbmeta:SampleSetupId")
        }
        if (wellElement.getNumber("pbmeta:OnPlateLoadingConcentration")) {
            sample.loadingConcentration = wellElement.getNumber("pbmeta:OnPlateLoadingConcentration")
        }
        sample.sampleReuseEnabled = wellElement.getString("pbmeta:SampleReuseEnabled").toLowerCase() === "true"
        sample.stageStartEnabled = wellElement.getString("pbmeta:StageHotstartEnabled").toLowerCase() === "true"
        sample.sizeSelectionEnabled = wellElement.getString("pbmeta:SizeSelectionEnabled").toLowerCase() === "true"

        if (wellElement.getElement("pbsample:BioSamples")) {
            sample.barcodeSampleMap = {}
            sample.barcodeUuidMap = {}
            if (wellElement.getElement("pbsample:BioSamples/pbsample:BioSamplesCsv")) {
                sample.barcodeCSVName = wellElement
                    .getElement("pbsample:BioSamples/pbsample:BioSamplesCsv")
                    .getAttribute("Name")
            }
            const biosampleElements = wellElement.getElements("pbsample:BioSamples/pbsample:BioSample")
            if (biosampleElements && biosampleElements.length === 1) {
                sample.biosampleName = wellElement
                    .getElement("pbsample:BioSamples/pbsample:BioSample")
                    .getAttribute("Name")
            }
            if (biosampleElements && biosampleElements.length > 0) {
                biosampleElements.forEach(bioSampleElement => {
                    if (bioSampleElement.getElement("pbsample:DNABarcodes") !== null) {
                        sample.isBarcoded = true
                        let barcodeElement = bioSampleElement.getElement("pbsample:DNABarcodes/pbsample:DNABarcode")
                        let barcodeName = barcodeElement.getAttribute("Name")
                        let barcodeUuid = barcodeElement.getAttribute("UniqueId")
                        let biosample = bioSampleElement.getAttribute("Name")
                        sample.barcodeSampleMap[barcodeName] = biosample
                        sample.barcodeUuidMap[barcodeName] = barcodeUuid
                    }
                })
            }
        } else {
            if (wellElement.getElement("pbsample:BioSamplesCsv")) {
                sample.barcodeCSVName = wellElement.getElement("pbsample:BioSamplesCsv").getAttribute("Name")
            }
        }

        const automationElement = metadataElement.getElement("pbmeta:Automation")
        sample.magBead = automationElement
            .getAttribute("Name")
            .toLowerCase()
            .includes("magbead")
        sample.automationName = automationElement.getAttribute("Name")

        sample.automationParameters = automationElement
            .getElements(".//default:AutomationParameter")
            .map(paramElement => {
                const param: AutomationParameterObject = {
                    name: paramElement.getAttribute("Name"),
                    type: paramElement.getAttribute("ValueDataType"),
                    value: paramElement.getAttribute("SimpleValue")
                };
                (param as any).xml = paramElement
                return param
            })

        let totalTimeMinutes = 0
        let immobilizationTime = 0
        automationElement.getElements(".//default:AutomationParameter").forEach(paramElement => {
            let paramName = paramElement.getAttribute("Name")
            if (paramName === "DynamicLoadingCognate") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.useDynamicLoading = paramValue.toLowerCase() === "true"
            } else if (paramName === "DynamicLoadingTarget") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.loadingTarget = Number(paramValue)
            } else if (paramName === "DynamicLoadingMaxImmobilization") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.maxLoadingTime = Number(paramValue) / 60
            } else if (paramName === "DynamicLoadingTotalTimeSeconds") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                totalTimeMinutes = Number(paramValue) / 60
            } else if (paramName === "ImmobilizationTime") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                immobilizationTime = Number(paramValue)
            }
        })

        sample.actualLoadingTime = sample.useDynamicLoading ? totalTimeMinutes : immobilizationTime

        const controlElement = metadataElement.getElement("pbmeta:ControlKit")
        if (controlElement) {
            sample.controlKit = controlElement.getAttribute("Barcode")
        }

        const prepKitElement = metadataElement.getElement("pbmeta:TemplatePrepKit")
        if (prepKitElement) {
            sample.templatePrepKit = prepKitElement.getAttribute("Barcode")
        }

        const bindingKitElement = metadataElement.getElement("pbmeta:BindingKit")
        if (bindingKitElement) {
            sample.bindingKit = bindingKitElement.getAttribute("Barcode")
        }

        const sequencingElement = metadataElement.getElement("pbmeta:SequencingKitPlate")
        if (sequencingElement) {
            sample.sequencingKit = sequencingElement.getAttribute("Barcode")
            if (isRevio(run.instrumentType)) {
                sample.plateNumber = Number(sequencingElement.getAttribute("PlateNumber"))
                let labelNumber = sequencingElement.getAttribute("LabelNumber") || "xxxxx"
                let newBarcode = makeNewBarcode(sample.sequencingKit, labelNumber)
                if (sample.plateNumber === 1) {run.plate1Barcode = newBarcode}
                if (sample.plateNumber === 2) {run.plate2Barcode = newBarcode}
            }
        }

        const cellElement = metadataElement.getElement("pbmeta:CellPac")
        if (cellElement) {
            sample.cellPac = cellElement.getAttribute("Barcode")
        }

        let parseConsumable = (name: string) => {
            metadataElement.getElements(name).forEach((kitElement: XMLElement) => {
                const attrs = ["Barcode", "ExpirationDate", "LotNumber", "Name", "ActiveHours", "ActiveHoursLimit"]
                let consumable: Consumable = { Well: sample.wellName ? sample.wellName : "" } as Consumable
                attrs.forEach((attr: string) => {
                    if (attr === "ActiveHours" || attr === "ActiveHoursLimit") {
                        consumable[attr] = kitElement.hasAttribute(attr)
                            ? parseFloat(kitElement.getAttribute(attr))
                            : 0
                    } else {
                        consumable[attr] = kitElement.hasAttribute(attr) ? kitElement.getAttribute(attr) : ""
                    }
                })
                if (!sample.consumables) {
                    sample.consumables = []
                }
                sample.consumables.push(consumable)
            })
        }
        sample.consumables = []
        parseConsumable("pbmeta:TemplatePrepKit")
        parseConsumable("pbmeta:BindingKit")
        parseConsumable("pbmeta:ControlKit")
        parseConsumable("pbmeta:SequencingKitPlate")
        parseConsumable("pbmeta:SequencingKitPlate/pbrk:ReagentTubes")
        parseConsumable("pbmeta:CellPac")

        const secondaryAutomationParametersContainer = metadataElement.getElement("pbmeta:Secondary/pbmeta:AutomationParameters")
        const secondaryAutomationParameters = secondaryAutomationParametersContainer.getElements(".//pbmeta:AutomationParameter")
        secondaryAutomationParameters.forEach(paramElement => {
            let paramName = paramElement.getAttribute("Name")
            if (paramName === "AllReads") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.includeAllReads = paramValue.toLowerCase() === "true"
            }
            if (paramName === "CpG") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.include5mC = paramValue.toLowerCase() === "true"
            }
            if (paramName === "DemultiplexMode") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.demuxBarcodes = paramValue
            }
            if (paramName === "Heteroduplex") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.detectHeteroduplex = paramValue.toLowerCase() === "true"
            }
            if (paramName === "BarcodesUuid") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.barcodeUuid = paramValue
            }
            if (paramName === DEMUX_PRESET) {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.sameBarcodesBothEnds = paramValue === PRESET_BC_SYMM
            }
            if (paramName === "SampleSubreadsPct") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.emitSubreadsPercent = parseInt(paramValue)
            }
            if (paramName === "FullResolutionBaseQual") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.fullResolutionBaseQual = paramValue.toLowerCase() === "true"
            }
            if (paramName === "SubreadToHiFiPileup") {
                let paramValue = paramElement.getAttribute("SimpleValue")
                sample.subreadToHiFiPileup = paramValue.toLowerCase() === "true"
            }
        })

        const primaryElement = metadataElement.getElement("pbmeta:Primary")
        const ccsOptionsElement = primaryElement ? primaryElement.getElement("pbmeta:CCSOptions") : null
        if (ccsOptionsElement) {
            sample.enableCCS = ccsOptionsElement.getString("pbmeta:ExecutionMode")
            sample.includeKinetics = ccsOptionsElement.getString("pbmeta:IncludeKinetics").toLowerCase() === "true"
            if (sample.enableCCS !== "None") {
                sample.ccsUuid = ccsElement.getAttribute("UniqueId")
            }
        } else {
            if (ccsElement) {
                sample.enableCCS = "OffInstrument"
                sample.ccsUuid = ccsElement.getAttribute("UniqueId")
            } else {
                sample.enableCCS = "None"
            }
        }

        if (primaryElement) {
            sample.primaryAutomationName = primaryElement.getString("pbmeta:AutomationName")
            sample.primaryConfigFileName = primaryElement.getString("pbmeta:ConfigFileName")

            const optionsElement = primaryElement.getElement("pbmeta:OutputOptions")
            if (optionsElement) {
                sample.copyFiles = optionsElement
                    .getElements("pbmeta:CopyFiles/*")
                    .map(element => element.getString("."))

                sample.metricsVerbosity = optionsElement.getString("pbmeta:MetricsVerbosity")
                sample.readout = optionsElement.getString("pbmeta:Readout")
                sample.collectionPathUri = optionsElement.getString("pbmeta:CollectionPathUri")
            }
        }

        let recordedEvents = recordedEventMap[sample.subreadSetUuid]
        if (recordedEvents) {
            for (let recordedEvent of recordedEvents) {
                const eventName = recordedEvent.getAttribute("Name")
                if (eventName === "AcquisitionInitializeInfo") {
                    sample.startedAt = recordedEvent.getAttribute("CreatedAt")
                }
                if (eventName === "AcquisitionCompletion") {
                    sample.completedAt = recordedEvent.getAttribute("CreatedAt")
                }
            }
        }
        sample.status = metadataElement.getAttribute("Status") as CollectionStateValue
        sample.context = metadataElement.getAttribute("Context")
        sample.collectionNumber = Number(metadataElement.getString("pbmeta:CollectionNumber"))

        sample.cellName = metadataElement.getString("pbmeta:CellIndex")
        const washKitElement = metadataElement.getElement("pbmeta:WashKitPlate")
        if (washKitElement) {
            sample.washKit = washKitElement.getAttribute("Barcode")
        }

        for (let automationParameter of sample.automationParameters) {
            if (automationParameter.name === "MovieLength") {
                sample.movieMinutes = Number(automationParameter.value)
            }
            if (automationParameter.name === "ReuseCell") {
                sample.cellReuseEnabled = Boolean(
                    automationParameter.value && automationParameter.value.toLowerCase() === "true"
                )
            }
            if (automationParameter.name === "ExtendFirst") {
                if (Boolean(automationParameter.value)) {
                    for (let automationParameter of sample.automationParameters) {
                        if (automationParameter.name === "ExtensionTime") {
                            let extensionTime = automationParameter.value ? Number(automationParameter.value) / 60 : 0
                            sample.extensionTime = extensionTime.toString()
                        }
                        if (automationParameter.name === "ExtensionTimeAL") {
                            let extensionTime = automationParameter.value ? Number(automationParameter.value) / 60 : 0
                            sample.extensionTime = extensionTime.toString()
                        }
                    }
                } else {
                    sample.extensionTime = "0"
                }
            }
            if (automationParameter.name === "ImmobilizationTime") {
                sample.immobilizationTime = Boolean(automationParameter.value)
                    ? Number(automationParameter.value) / 60
                    : 2
            }
        }
        sample.xml = sampleElement as any

        return sample
    });

    (run as any).xml = doc

    // Determine whether any cell reuse
    let hasCellReuse = false
    run.samples.forEach(sample => {
        if (sample.cellReuseEnabled) {
            hasCellReuse = true
        }
    })

    if (hasCellReuse) {
        run.samples.sort(sortByCellNameAndWellName)

        // collapse collections for cell reuse into single collections with multiple well names
        const collapsedSamples: SampleObject[] = []
        let firstSample
        run.samples.forEach(sample => {
            if (sample.cellReuseEnabled) {
                if (!firstSample || firstSample.cellName !== sample.cellName) {
                    firstSample = sample
                    firstSample.cellReuseSamples = []
                    sample.collectionsPerSMRTCell = 1
                    collapsedSamples.push(sample)
                } else {
                    firstSample.wellName += " " + sample.wellName
                    firstSample.collectionsPerSMRTCell += 1
                    firstSample.cellReuseSamples.push(sample)
                    if (!firstSample.washKit) {
                        firstSample.washKit = sample.washKit
                    }
                }
            } else {
                collapsedSamples.push(sample)
            }
        })

        run.samples = collapsedSamples
    }

    return run
}

const sortByCellNameAndWellName = (a: SampleObject, b: SampleObject) => {
    if ((a.cellName || "") < (b.cellName || "")) { return -1 }
    if ((a.cellName || "") > (b.cellName || "")) { return 1 }
    if ((a.wellName || "") < (b.wellName || "")) { return -1 }
    if ((a.wellName || "") > (b.wellName || "")) { return 1 }
    throw new Error("Samples/Collections should not have same cellName and wellName")
}
