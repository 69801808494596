// See: https://medium.com/@stowball/a-dummys-guide-to-redux-and-thunk-in-react-d8904a7005d3

export const AboutActionTypes = {
    API_ATTEMPT_CHEMISTRY_BUNDLE_CURRENT_VERSION: "@@about/API_ATTEMPT_CHEMISTRY_BUNDLE_CURRENT_VERSION",
    API_SUCCESS_CHEMISTRY_BUNDLE_CURRENT_VERSION: "@@about/API_SUCCESS_CHEMISTRY_BUNDLE_CURRENT_VERSION",
    API_FAILURE_CHEMISTRY_BUNDLE_CURRENT_VERSION: "@@about/API_FAILURE_CHEMISTRY_BUNDLE_CURRENT_VERSION",

    API_ATTEMPT_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_ATTEMPT_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION",
    API_SUCCESS_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_SUCCESS_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION",
    API_FAILURE_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_FAILURE_CHEMISTRY_BUNDLE_UPDATE_AVAILABLE_VERSION",

    API_ATTEMPT_CHEMISTRY_BUNDLE_UPGRADE: "@@about/API_ATTEMPT_CHEMISTRY_BUNDLE_UPGRADE",
    API_SUCCESS_CHEMISTRY_BUNDLE_UPGRADE: "@@about/API_SUCCESS_CHEMISTRY_BUNDLE_UPGRADE",
    API_FAILURE_CHEMISTRY_BUNDLE_UPGRADE: "@@about/API_FAILURE_CHEMISTRY_BUNDLE_UPGRADE",

    API_ATTEMPT_UI_BUNDLE_CURRENT_VERSION: "@@about/API_ATTEMPT_UI_BUNDLE_CURRENT_VERSION",
    API_SUCCESS_UI_BUNDLE_CURRENT_VERSION: "@@about/API_SUCCESS_UI_BUNDLE_CURRENT_VERSION",
    API_FAILURE_UI_BUNDLE_CURRENT_VERSION: "@@about/API_FAILURE_UI_BUNDLE_CURRENT_VERSION",

    API_ATTEMPT_UI_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_ATTEMPT_UI_BUNDLE_UPDATE_AVAILABLE_VERSION",
    API_SUCCESS_UI_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_SUCCESS_UI_BUNDLE_UPDATE_AVAILABLE_VERSION",
    API_FAILURE_UI_BUNDLE_UPDATE_AVAILABLE_VERSION: "@@about/API_FAILURE_UI_BUNDLE_UPDATE_AVAILABLE_VERSION",

    API_ATTEMPT_UI_BUNDLE_UPGRADE: "@@about/API_ATTEMPT_UI_BUNDLE_UPGRADE",
    API_SUCCESS_UI_BUNDLE_UPGRADE: "@@about/API_SUCCESS_UI_BUNDLE_UPGRADE",
    API_FAILURE_UI_BUNDLE_UPGRADE: "@@about/API_FAILURE_UI_BUNDLE_UPGRADE"
}

export interface AboutApiState {
    readonly chemistryBundleCurrentVersionLoading: boolean
    readonly chemistryBundleCurrentVersion: string
    readonly chemistryBundleCurrentVersionError: Error | null

    readonly chemistryBundleUpdateAvailableLoading: boolean
    readonly chemistryBundleUpdateAvailable: boolean
    readonly chemistryBundleUpdateAvailableVersion: string
    readonly chemistryBundleUpdateAvailableDescription: string
    readonly chemistryBundleUpdateAvailableError: Error | null

    readonly chemistryBundleUpgradeLoading: boolean
    readonly chemistryBundleUpgradeFinished: boolean
    readonly chemistryBundleUpgradeError: Error | null

    readonly uiBundleBundleCurrentVersionLoading: boolean
    readonly uiBundleBundleCurrentVersion: string
    readonly uiBundleBundleCurrentVersionError: Error | null

    readonly uiBundleBundleUpdateAvailableLoading: boolean
    readonly uiBundleBundleUpdateAvailable: boolean
    readonly uiBundleBundleUpdateAvailableVersion: string
    readonly uiBundleBundleUpdateAvailableDescription: string
    readonly uiBundleBundleUpdateAvailableError: Error | null

    readonly uiBundleBundleUpgradeLoading: boolean
    readonly uiBundleBundleUpgradeFinished: boolean
    readonly uiBundleBundleUpgradeError: Error | null
}
