import { InstrumentType } from "../../model/instrument-type-model"

export interface InstrumentState {
    readonly instrumentSupport: InstrumentSupport
    readonly currentInstrumentType: InstrumentType
}

export interface InstrumentSupport {
    readonly supportsSequel: boolean
    readonly supportsSequel2: boolean
    readonly supportsSequel2e: boolean
    readonly supportsRevio: boolean
}

export const InstrumentActionTypes = {
    UPDATE_INSTRUMENT_SUPPORT: "@@instrument/UPDATE_INSTRUMENT_SUPPORT",
    UPDATE_CURRENT_INSTRUMENT_TYPE: "@@instrument/UPDATE_CURRENT_INSTRUMENT_TYPE"
}


