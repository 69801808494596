import { ReportViewRule } from "../../model/report-view-rules.model"
import { ReportFormatMap } from "../../services/job-reports-service"
import { PipelineTemplateMap, PipelineTemplate } from "../../model/pipeline-template-models"
import { PipelineViewRule } from "../../model/pipeline-template-view-rules-model"
import { WorkflowPreset } from "../../model/workflow-preset-model"

export interface RulesState {
    reportViewRules: ReportViewRule[]
    reportFormatMap: ReportFormatMap
    pipelineTemplateMap: PipelineTemplateMap
    pipelineViewRules: PipelineViewRule[]
    pipelineTemplates: PipelineTemplate[]
    workflowPresets: WorkflowPreset[]
}

export const RulesActionTypes = {
  GET_REPORT_VIEW_RULES: "@@rules/GET_REPORT_VIEW_RULES",
  GET_REPORT_FORMAT_MAP: "@@rules/GET_REPORT_FORMAT_MAP",
  GET_PIPELINE_TEMPLATE_MAP: "@@rules/GET_PIPELINE_TEMPLATE_MAP",
  GET_PIPELINE_VIEW_RULES: "@@rules/GET_PIPELINE_VIEW_RULES",
  GET_PIPELINE_TEMPLATES: "@@rules/GET_PIPELINE_TEMPLATES",
  GET_WORKFLOW_PRESETS: "@@rules/GET_WORKFLOW_PRESETS"
}
