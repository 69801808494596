import { ActionCreator, Action } from "redux"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { ThunkDispatch } from "redux-thunk"

import * as API from "../../api"
import { NotificationsActionTypes } from "./notifications-types"
import { NotificationModel } from "../../model/notification-model"

export const fetchNotifications = (): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
    getState: () => ApplicationState
) => {
    dispatch(apiAttempt())
    try {
        const userPreferences = getState().userPreferences.userPreferences
        const { daysTillArchive } = userPreferences.notificationPreferences
        const { data: notifications } = await API.getNotifications(daysTillArchive)
        dispatch(setNotifications(notifications))
    } catch (err) {
        dispatch(apiFailure(err))
    }
}

export const updateNotifications =
    ( notificationIds: number[], isUnread: boolean): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
    const response = await API.updateNotifications( notificationIds, isUnread )
    if (response.ok) {
        dispatch(fetchNotifications())
    } else {
        throw new Error("Server error: Failed to update notifications.")
    }
}

export const setNotifications: ActionCreator<Action> = (notifications: NotificationModel[]) => ({
    type: NotificationsActionTypes.SET_NOTIFICATIONS,
    payload: notifications
})

export const apiAttempt: ActionCreator<Action> = () => ({
    type: NotificationsActionTypes.API_ATTEMPT
})

export const apiFailure: ActionCreator<Action> = (error) => ({
    type: NotificationsActionTypes.API_FAILURE,
    payload: error
})

