import React, { Component } from "react"
import { NotFoundLayout } from "../shared/NotFoundLayout"

const BODY_THEME_CLASS = "btheme-base"

export class NotFoundPage extends Component {

  componentDidMount() {
    document.body.classList.add(BODY_THEME_CLASS)
  }

  componentWillUnmount() {
    document.body.classList.remove(BODY_THEME_CLASS)
  }

  render() {
    return (
        <div className="error-page">
            <NotFoundLayout />
        </div>
    )
  }
}