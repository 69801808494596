import React, { Component } from "react"

export class BadPermPage extends Component {
    render() {
        return (
            <div className="bad-perm-page">
                <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
                    <p>Insufficient permissions: User does not have access to this module or page. </p>
                    <p>The user may not 
                    have the required role (e.g. "Admin") or the page may belong to a Project to which the user 
                    does not have access.</p>
                </h2>
            </div>
        )
    }
}
