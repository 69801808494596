export type NotificationSeverity = "INFO" | "WARNING" | "ERROR" | "CRITICAL"

const prefKeys = [
    {pref: "daysTillArchive", key: "smrtlink.notifications.days_till_archive" },
    {pref: "isByEmail", key: "smrtlink.notifications.is_by_email" },
    {pref: "emailAddress", key: "smrtlink.notifications.email_address" },
    {pref: "notifyOnUpdate", key: "smrtlink.notifications.notify_on_update" },
    {pref: "notifyOnWarning", key: "smrtlink.notifications.notify_on_warning" },
    {pref: "notifyOnError", key: "smrtlink.notifications.notify_on_error" },
    {pref: "badgeIncludesInfos", key: "smrtlink.notifications.badge_includes_infos" },
    {pref: "badgeIncludesWarnings", key: "smrtlink.notifications.badge_includes_warnings" },
    {pref: "badgeIncludesErrors", key: "smrtlink.notifications.badge_includes_errors" },
    {pref: "badgeIncludesCriticals", key: "smrtlink.notifications.badge_includes_criticals" }
]
export interface UserPreferences {
    notificationPreferences?: NotificationsPreferences
}

export interface NotificationsPreferences {
    daysTillArchive?: number
    isByEmail?: boolean
    emailAddress?: string
    notifyOnUpdate?: boolean
    notifyOnWarning?: boolean
    notifyOnError?: boolean
    badgeIncludesInfos?: boolean
    badgeIncludesWarnings?: boolean
    badgeIncludesErrors?: boolean
    badgeIncludesCriticals?: boolean
}

export const defaultUserPreference: UserPreferences = {
    notificationPreferences: {
        daysTillArchive: 14,
        isByEmail: false,
        emailAddress: null,
        notifyOnUpdate: false,
        notifyOnWarning: false,
        notifyOnError: false,
        badgeIncludesInfos: true,
        badgeIncludesWarnings: true,
        badgeIncludesErrors: true,
        badgeIncludesCriticals: true
    }
}

type UserPreferencesJson = {
    key: string
    value: any
}[]

export const jsonToUserPreferences = (json: UserPreferencesJson): UserPreferences => {
    const notificationPrefs = {}
    for (let keyValue of json) {
        const {key, value} = keyValue
            const prefKey = prefKeys.find( prefKey => prefKey.key === key)
            if (prefKey) {
                notificationPrefs[prefKey.pref] = value
            }
    }
    const userPreferences: UserPreferences = {}
    if (Object.keys(notificationPrefs).length > 0) {
        userPreferences.notificationPreferences = notificationPrefs
    }
    return userPreferences
}

export const userPreferencesToJson = (prefs: UserPreferences): UserPreferencesJson => {
    const json = []

    const notificationPrefs = prefs.notificationPreferences
    if (notificationPrefs) {
        for (let prefKey of prefKeys) {
            const { pref, key} = prefKey
            if (pref in notificationPrefs) {
                json.push( {
                    key: key,
                    value: notificationPrefs[pref]
                })
            }
        }
    }
    return json
}
