import { AlarmModel } from "../../model/alarm-model"

export const AlarmActionTypes = {
  API_ATTEMPT: "@@alarm/API_ATTEMPT",
  API_FAILURE: "@@alarm/API_FAILURE",

  SET_ALARMS: "@@alarm/SET_ALARMS",
  SET_CLEARED_ALARM_IDS: "@@alarm/SET_CLEARED_ALARM_IDS"
}

export interface AlarmState {
  readonly loading: boolean
  readonly alarms: AlarmModel[]
  readonly clearedAlarmIds: string[]
  readonly error: Error | null
}
