import { AlarmModel, AlarmSeverity } from "../../model/alarm-model"

export interface AlarmsStatus {
    alarmsCount: number
    highestSeverity: AlarmSeverity 
    isHighestFatal: boolean
    isHighestError: boolean
    isHighestCritical: boolean
    isHighestWarning: boolean
}

export const displayedAlarms = (allAlarms: AlarmModel[], clearedAlarmIds: string[]): AlarmModel[] => {
    return allAlarms.filter( alarm => ! (clearedAlarmIds.includes(alarm.id)) )
}

export const calcAlarmsStatus = (allAlarms: AlarmModel[], clearedAlarmIds: string[]): AlarmsStatus => {
    const alarms = displayedAlarms(allAlarms, clearedAlarmIds)
    const highestSeverity =  determineHighestSeverity(alarms)
    const fatalSeverities = [AlarmSeverity.FatalImmediate, AlarmSeverity.Fatal]
    return {
        alarmsCount: determineAlarmCountAtOrAboveSeverity( alarms, AlarmSeverity.Warning ),
        highestSeverity: highestSeverity,
        isHighestFatal: fatalSeverities.includes(highestSeverity),
        isHighestCritical:  highestSeverity === AlarmSeverity.Critical,
        isHighestError: highestSeverity === AlarmSeverity.Error,
        isHighestWarning: highestSeverity === AlarmSeverity.Warning,
    }
}

const determineAlarmCountAtOrAboveSeverity = (alarms: AlarmModel[], severity: AlarmSeverity): number => {
    let alarmCount = 0
    alarms.forEach(alarm => {
        if (alarm.severity >= severity) {
            alarmCount++
        }
    })
    return alarmCount
}

const determineHighestSeverity = (alarms: AlarmModel[]): AlarmSeverity => {
    let highestSeverity = AlarmSeverity.Clear
    alarms.forEach(alarm => {
        if (alarm.severity === AlarmSeverity.FatalImmediate) {
            highestSeverity = AlarmSeverity.FatalImmediate
        } else if (alarm.severity > highestSeverity) {
            highestSeverity = alarm.severity
        }
    })
    return highestSeverity
}


export const debugAlarm = ():AlarmModel => {
    const id = prompt("id", "test.id")
    if (!id) {
        return null
    }
    const severityString = prompt("severity", "" + AlarmSeverity.Warning)
    if (severityString === null) {
        return null
    }
    const severity = Number.parseInt(severityString, 10)
    const alarm = new AlarmModel(
        id,
        "test.message",
        "test.name",
        severity,
        "test.source",
        new Date()
    )
    return alarm
}