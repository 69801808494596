import { PartNumbersJSON, PartsService, PartLookupService } from "../../model/parts-model"

export const PartsActionTypes = {
  API_ATTEMPT: "@@parts/API_ATTEMPT",
  API_SUCCESS: "@@parts/API_SUCCESS",
  API_FAILURE: "@@parts/API_FAILURE"
}

export interface PartsApiState {
  readonly loading: boolean
  readonly data: PartNumbersJSON
  readonly partsService: PartsService
  readonly partLookupService: PartLookupService
  readonly error: Error | null
}

