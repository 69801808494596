import * as API from "../api"
import * as datastoreService from "./datastore-service"
import {
    Job,
    JobEvent,
    JobStatusEvent,
    JobEntryPoint
} from "../model/job-model"
import {
    MultiJobOptions,
    DeferredJob,
    JobOptions,
    MergeJobOptions,
    DeleteJobOptions,
    ExportAnalysisJobOptions,
    CopyDatasetJobOptions,
    TechSupportJobOptions,
    TechSupportStatusOptions,
    JobOptionEntryPoint
} from "../model/job-options-model"
import { DesignModel, RunModel } from "../model/run-model"
import {
    JobDetails,
    UiJob,
    UiJobEvent,
    DatasetLink,
    UiTaskOptionBuilder,
    PipelineDetails
} from "../model/ui-job-model"
import { UiDatastoreFile } from "../model/ui-datastore-file"
import { DatastoreFile } from "../model/datastore-file-model"
import { Dataset, MISSING_DATASET } from "../model/dataset-model"
import { DatasetType, isFileTypePrimary } from "../model/dataset-type-model"
import { ImportType } from "../model/import-type-model"
import { PromisePollScheduler } from "../../core/utils/promise-poll-scheduler"
import {
    PipelineTemplate,
    PipelineTemplateMap
} from "../model/pipeline-template-models"
import * as datasetService from "./dataset-services"
import {PRE_ANALYSIS_IDS} from "./run-service"
import { apiCache } from "./api-cache"
import { datasetDetailsLink } from "../../core/Routes"
import { ApiResponse } from "apisauce"
import { partitionArray } from "../../core/utils/array-helper"
import { DataType, PRIMARY_FILE_TYPES } from "../model/data-type"
import { toSubreadDemuxJob, toCcsDemuxJob, toGenerateCcsJob, toExportCcsJob} from "./pre-analysis-utils"
import { DataStoreViewRule } from "../model/pipeline-datastore-view-rules-model"
import * as _ from "lodash"

const MASTER_LOG_SOURCE_ID = "master.log"

export const getJobDetails = async (
    jobId: number,
    showHiddenFiles: boolean = false
): Promise<JobDetails> => {

    const jobPromise = getUiJob(jobId)

    return jobPromise.then(
        job => {
            const jobDetails$ = Promise.all([
                getJobEventsList(jobId),
                getTaskEventsList(jobId),
                getDatasetLinksList(jobId),
                getMasterLog(jobId),
                getPbsmrtpipeJobOptions(jobId).then(options => {

                    const pipelineIds = job.subJobTypeId ? job.subJobTypeId.split(",") : [""]
                    const pipelineId = pipelineIds.length > 1 ? "" : pipelineIds[0]
                    return Promise.all([
                        datastoreService.getDatastoreViewRulesMap((
                            pipelineId
                        )),
                        getFileDownloads(jobId),
                        apiCache.getPipelineTemplatesMap()
                    ]).then(([viewRules, files, pipelineTemplatesMap]) => {
                        const allFiles = files
                        const downloadFiles = applyViewRules(
                            files,
                            viewRules,
                            showHiddenFiles
                        )

                        let jobApplicationName = "N/A"
                        let uiTaskOptions = []
                        let pipeline: PipelineTemplate
                        if (pipelineId.length > 0) {
                            pipeline = pipelineTemplatesMap[pipelineId]

                            if (!pipeline) {
                                throw new Error(
                                    "Error: The pipeline: " +
                                        pipelineId +
                                        " is not recognized by SMRT Link"
                                )
                            }
                            jobApplicationName =
                                pipeline && pipeline.name ? pipeline.name : pipelineId
                            uiTaskOptions = UiTaskOptionBuilder.uiTaskOptionsForPipelineAndJob(
                                pipeline,
                                options
                            )
                        }
                        const pipelineDetails: PipelineDetails = {
                            pipeline: pipeline,
                            jobApplicationName: jobApplicationName,
                            uiTaskOptions: uiTaskOptions
                        }

                        return [options, pipelineDetails, downloadFiles, [], allFiles] as
                            [
                                JobOptions,
                                PipelineDetails,
                                UiDatastoreFile[], // downloadFiles
                                UiDatastoreFile[], // igvFiles (empty for now)
                                UiDatastoreFile[] // allFiles
                            ]
                    })
                })]).then(
                ([
                    events,
                    taskStatusEvents,
                    datasetLinks,
                    log,
                    [options, pipelineDetails, downloadFiles, igvFiles, allFiles]
                ]) => ({
                    job,
                    events,
                    taskStatusEvents,
                    datasetLinks,
                    log,
                    options,
                    downloadFiles,
                    igvFiles,
                    allFiles,
                    pipelineDetails
                })
            )
            return jobDetails$.then(jobDetails => {
                return getPrimaryDatasetForJobDetails(jobDetails)
                    .then(primaryDataset => {
                        (jobDetails as JobDetails).primaryDataset = primaryDataset
                        return jobDetails
                    })
                    .then(jobDetails => {
                        if (shouldFetchIgvLinks(jobDetails)) {
                            return getIgvFiles(jobDetails, showHiddenFiles).then(
                                igvFiles => {
                                    jobDetails.igvFiles = igvFiles
                                    return jobDetails
                                }
                            )
                        } else {
                            return jobDetails
                        }
                    })
            })
        }
    )
}

export const getUiJob = (
    jobId: number,
    jobType?: string
): Promise<UiJob> => {
    let job$ = getJob(jobId, jobType)
    return job$.then((serverJob: Job) => {
        return convertToUiJob(serverJob)
    })
}

export const getJob = (jobId: number, jobType?: string): Promise<Job> => {
    const promise: Promise<Job> = API.aJob(jobId, jobType).then(response => {
        return response.data
    })
    return promise
}

const getIgvFiles = (
    jobDetails: JobDetails,
    showHiddenFiles: boolean
): Promise<UiDatastoreFile[]> => {
    const pipelineId = jobDetails.pipelineDetails.pipeline.id
    return Promise.all([
        datastoreService.getDatastoreViewRulesMap(pipelineId),
        apiCache.getJobIgvFiles(jobDetails.job.id, "analysis")
    ]).then(([viewRules, igvFilesResponse]) => {
        const files = igvFilesResponse.map(file =>
            processDatastoreFile(file)
        )
        const showIgvFiles = true
        return applyViewRules(files, viewRules, showHiddenFiles, showIgvFiles)
    })
}

const applyViewRules = (
    files: UiDatastoreFile[],
    viewRules: Map<string,DataStoreViewRule>,
    showHiddenFiles: boolean,
    showIgvFiles?: boolean
): UiDatastoreFile[] => {
    let allFiles = files
    if (!viewRules) {
        throw new Error("viewRules is undefined")
    }
    let downloadFiles = showHiddenFiles
        ? allFiles
        : files.filter(file => {
              const rule = viewRules.get(file.sourceId || "")
              if (!rule) return true
              if (rule.isHidden) return false
              if (rule.igvOnly && !showIgvFiles) return false
              return true
          })
    downloadFiles.forEach(file => {
        const rule = viewRules.get(file.sourceId || "")
        if (rule) {
            if (rule.name) {
                file.displayName = rule.name
            }
            if (rule.typeName) {
                file.typeName = rule.typeName
            }
        }
    })
    return downloadFiles
}
const getJobEventsList = (jobId: number): Promise<UiJobEvent[]> => {
    const events$: Promise<JobEvent[]> = API.nJobEvents(jobId).then(
        response => {
            return response.data
        }
    )

    return events$.then((events: JobEvent[]) => {
        return events
            .filter(
                event =>
                    !event.eventTypeId ||
                    event.eventTypeId === "smrtlink_job_status"
            )
            .map(convertToUiJobEvent)
    })
}

export const getTaskEventsList = (jobId: number): Promise<JobStatusEvent[]> => {
    return API.nJobStatusEvents(jobId).then(response => {
        const events = response.data
        return events
            .filter(
                event =>
                    !event.eventTypeId ||
                    event.eventTypeId === "smrtlink_job_task_status"
            )
            .map(convertToJobStatusEvent)
            .sort(function(a, b) {
                return Date.parse(b.createdAt) - Date.parse(a.createdAt)
            })
    })
}

const shouldFetchIgvLinks = (jobDetails: JobDetails): boolean => {
    const igvRelated = jobDetails.allFiles.filter( file => {
        return file.sourceId.endsWith(".mapped") || file.fileTypeId === "PacBio.FileTypes.bam"
    })
    return igvRelated.length > 0
}
const hasMappedFile = (jobDetails: JobDetails): boolean => {
    const mappedFiles  = jobDetails.allFiles.filter( file => {
        return file.sourceId.endsWith(".mapped")
    })
    return mappedFiles.length > 0
}
const hasIgvBamFile = (jobDetails: JobDetails): boolean => {
    const bamFiles  = jobDetails.igvFiles.filter( file => {
        return file.fileTypeId === "PacBio.FileTypes.bam"
    })
    return bamFiles.length > 0
}

export const jobNeedsBamConsolidaton = (jobDetails: JobDetails ): boolean => {
    return hasMappedFile(jobDetails) && !hasIgvBamFile(jobDetails)
}

const getDatasetLinksList = async (jobId: number): Promise<DatasetLink[]> => {

    const entryPointsPromise: Promise<JobEntryPoint[]> = API.nJobEntryPoints(
        jobId
    ).then(response => {
        return response.data
    })
    let entryPoints = await entryPointsPromise
    if (!entryPoints.length) {
        return Promise.resolve([])
    }
    const uuidsByDatastType:  { [key:string]:string[]; }  = {}

    for (let entryPoint of entryPoints) {

        const type = entryPoint.datasetType
        const uuid = entryPoint.datasetUUID

        if (! (type in uuidsByDatastType) ) {
            uuidsByDatastType[type] = []
        }
        uuidsByDatastType[type].push(uuid)
    }

    // get all the datasets, grouped in calls by datasetType
    const datasetsPromises = []
    for (let type of Object.keys(uuidsByDatastType)) {
        const uuids = uuidsByDatastType[type]
        const typeParam = DataType.fileTypeToShortName(type)
        datasetsPromises.push(
            datasetService.getDatasetsBySearchAndType(uuids, typeParam)
        )
    }
    const datasetsData = await Promise.all(datasetsPromises)
    const datasets = [].concat(...datasetsData)

    // Build a hash from uuid to either the successfully retrieved dataset or null if not found (not imported yet).
    const datasetsHash = {}
    for (let entryPoint of entryPoints) {
        datasetsHash[entryPoint.datasetUUID] = null
    }
    for (let dataset of datasets) {
        if (dataset ) {
            datasetsHash[dataset.uuid] = dataset
        }
    }

    // build and a list of DatasetLink
    return entryPoints.map( entryPoint => {
        const uuid = entryPoint.datasetUUID
        const dataset = datasetsHash[uuid]
        const datasetFound = Boolean(dataset)

        const fileTypeId = entryPoint.datasetType
        const datasetType = DatasetType.byFiletype(
            entryPoint.datasetType || ""
        )
        let title = ""
        if (dataset && datasetType && datasetType.title) {
            title = datasetType.title
            if (dataset.name) {
                title += ": " + dataset.name
            }
        }
        let tags: string[] = []
        let isMerged: boolean = false
        if (dataset && dataset.tags) {
            tags = dataset.tags!.split(",")
            isMerged = tags.length > 0 && tags.indexOf("merged") >= 0
        }
        const route = datasetFound ? datasetDetailsLink(uuid, datasetType!.shortName, true) : null

        // TODO: (nechols/mcantor 01.24.19) (SL-5374)
        // Somehow restore the ability to assign "isAccessible", previously detected by 401 responses
        // from the individual dataset calls (see SL-3770 discussion).
        const isAccessible = true
        return {
            id: datasetFound ? dataset.id : null,
            uuid: uuid,
            fileTypeId: fileTypeId,
            title: title,
            route: route,
            isMerged: isMerged,
            jobId: datasetFound ? dataset.jobId : null,
            parentUuid: datasetFound ? dataset.parentUuid : null,
            isAccessible: isAccessible
        } as DatasetLink
    })
}

export const getCollectionJobIds = (
    design: DesignModel,
    projectId: number
): Promise<{ [key: string]: number }> => {
    let ps: Promise<any>[] = []
    design.run.samples.forEach(sample => {
        const matchesProject = (projectId && projectId > 0) ? projectId === sample.projectId : true
        if (Boolean(sample.multiJobId) && matchesProject) {
            let promise = getMultiJobJobs(sample.multiJobId).then(jobs => {
                let result: any[] = []
                jobs.forEach(job => {
                    let settings = JSON.parse(job.jsonSettings || "")
                    if (!PRE_ANALYSIS_IDS.includes(settings.pipelineId)) {
                        settings.entryPoints.forEach(eP => {
                            if (
                                (eP.entryId === "eid_subread" && eP.datasetId === sample.subreadSetUuid ) ||
                                (eP.entryId === "eid_ccs" && eP.datasetId === sample.ccsUuid )
                            ) {
                                result = [sample.subreadSetUuid, job.id]
                            }
                        })
                    }
                })
                return result
            })
            ps.push(promise)
        }
    })
    return Promise.all(ps).then(sets => {
        let result = {}
        sets.forEach(set => {
            if (set) {
                result[set[0]] = set[1]
            }
        })
        return result
    })
}

export const getMultiJobJobs = (multiJobId: number): Promise<Job[]> => {
    return API.getMultiJobJobs(multiJobId).then(response => {
        return response.data
    })
}

export const getMultiJobJobIds = (multiJobId: number): Promise<any> => {
    return getMultiJobJobs(multiJobId).then(jobs => {
        let result = {}
        jobs.forEach(job => {
            let settings = JSON.parse(job.jsonSettings || "")
            settings.entryPoints.forEach(eP => {
                if (eP.entryId === "eid_subread" || eP.entryId === "eid_ccs") {
                    result[eP.datasetId] = job.id
                }
            })
        })
        return result
    })
}

export const isDeleteJobInvalid = (job: Job): boolean => {
    return job.id === -1
}

export const INVALID_DELETE = "Invalid delete error"

export const deleteJob = (jobUuid: string, dryRun: boolean): Promise<Job> => {
    let options: DeleteJobOptions = new DeleteJobOptions(jobUuid, true, dryRun)
    return API.deleteJob(options).then(
        response => response.data,
        errorResponse => {
            if (errorResponse.status === 422) {
                return Promise.reject(INVALID_DELETE)
            } else {
                throw new Error(errorResponse.data.messsage)
            }
        }
    )
}

export const deleteMultiJob = (multiJobId): Promise<any | number> => {
    return API.deleteMultiJob(multiJobId).then(
        response => response.data,
        errorResponse => {
            if (errorResponse.status === 422) {
                return Promise.reject(INVALID_DELETE)
            } else {
                throw new Error(errorResponse.data.messsage)
            }
        }
    )
}

export const createAutoJobs = async (sample, progress, setProgress): Promise<Job> => {

    const partitionedJobs = partitionArray(sample.multiJobJobs, 200)
    let multiJob: MultiJobOptions = {
        description: "RunDesign " + sample.sampleName,
        name: "Auto Analyses of " + sample.sampleName,
        projectId: Number(sample.projectId),
        submit: false, // Runs created from RunDesign not meant to be submitted
        jobs: partitionedJobs[0],
        isNested: sample.multiJobJobs.length > 1
    }
    if (setProgress) {
        setProgress(progress + "Adding batch 1 of " + partitionedJobs.length + ".")
    }
    return API.postMultiJob(multiJob).then(async response => {

        const job: Job = response.data
        for (let i = 1; i < partitionedJobs.length; i++) {
            if (setProgress) {
                setProgress(progress + "Adding batch " + Number(i+1) + " of " + partitionedJobs.length + ".")
            }
            // this is only being used to call the API to add jobs, it does
            // not get submitted as a new job
            let options: MultiJobOptions = {
                description: "RunDesign " + sample.sampleName,
                name: "Auto Analyses of " + sample.sampleName,
                projectId: Number(sample.projectId),
                submit: false, // Runs created from RunDesign not meant to be submitted
                jobs: partitionedJobs[i]
            }
            await API.addMultiJobJobs({
                multiJobId: job.id,
                options
            })
        }
        return job
    })
}

export const postBamConsolidationJob = (file: UiDatastoreFile): Promise<Job> => {
    let entryId: string
    switch ( file.fileTypeId ) {
        case "PacBio.DataSet.AlignmentSet": entryId = "eid_alignment"; break
        case "PacBio.DataSet.ConsensusAlignmentSet": entryId = "eid_ccs_alignment"; break
        default:
            return Promise.reject("Mapped file supplied for postBamConsolidationJob has an invalid fileTypeId: " + file.fileTypeId)
    }

    const name = "BAM consolidation of mapped dataset " + file.name
    const entryPoints: JobOptionEntryPoint[] = [{
        fileTypeId: file.fileTypeId,
        datasetId: file.uuid,
        entryId: entryId
    }]
    const options:JobOptions = {
        entryPoints: entryPoints,
        pipelineId: "cromwell.workflows.sl_consolidate_alignments",
        workflowOptions: [],
        taskOptions: [],
        name: name
    }
    return API.postAnalysisJob(options).then(response => response.data)
}

export const importJobs = (
    filePath: string,
    importType: ImportType,
    additionalPostParams?: {},
    projectId?: number
): Promise<any> => {
    let jobType: string
    let payload: any

    switch (importType) {
        case ImportType.AnalysisZIP:
            jobType = "import-job"
            payload = {
                zipPath: filePath
            }
            break

        case ImportType.Reference: {
            const referenceName = getName(filePath)
            jobType = "convert-fasta-reference"
            payload = {
                path: filePath,
                name: referenceName,
                organism: referenceName,
                ploidy: "haploid"
            }
            break
        }
        case ImportType.SubreadOrCCS:
        case ImportType.BarcodeXML:
        case ImportType.ReferenceSet:
            jobType = "import-dataset"
            payload = {
                path: filePath,
                waitForFile: false
            }
            break

        case ImportType.SubreadOrCCSZIP:
        case ImportType.BarcodeZIP:
        case ImportType.ReferenceSetZIP:
            jobType = "import-datasets-zip"
            payload = {
                path: filePath
            }
            break

        case ImportType.BarcodeFASTA:
            jobType = "convert-fasta-barcodes"
            payload = {
                path: filePath,
                name: getName(filePath)
            }
            break
         case ImportType.TargetRegionsBED:
            jobType = "convert-bedfile"
            payload = {
                path: filePath,
                sourceId: "target_bed"
            }
            break
        default:
            return new Promise((resolve, reject) => {
                reject(new Error("Invalid dataset type."))
            })
    }

    if (! _.isEmpty(projectId)) {
        payload.projectId = projectId
    }
    return API.postJob(jobType, { ...payload, ...additionalPostParams })
}

export const upload = (file: File): Promise<string> => {
    return API.upload(file)
}

export const postExportAnalysisJob = (
    options: ExportAnalysisJobOptions
): Promise<ApiResponse<Job>> => {
    return API.postExportAnalysisJobs(options)
}

export const exportDatasets = (options: any): Promise<ApiResponse<Job>> => {
    return API.exportDatasets(options)
}

export const makeDatasetReports = (options: any): Promise<ApiResponse<Job>> => {
    return API.makeDatasetReports(options)
}

export const copyJob = (options: CopyDatasetJobOptions): Promise<Job> => {
    return API.aCopyJob(options).then(response => response.data)
}

export const updateJob = (
    data: {},
    jobTypeId: string,
    id: number
): Promise<Job> => {
    return API.updateJob(data, jobTypeId, id).then(response => response.data)
}

export const getJobsForDataset = (datasetId: number | string): Promise<Job[]> => {
    return API.nJobsByDatasetIdOrUuid(datasetId).then(response => response.data)
}

export const getPrimaryDatasetForJobDetails = (
    jobDetails: JobDetails
): Promise<Dataset | null> => {
    for (let link of jobDetails.datasetLinks) {
        const isPrimary =
            link.fileTypeId &&
            PRIMARY_FILE_TYPES.indexOf(link.fileTypeId) > -1
        if (isPrimary && link.uuid) {
            const datasetType = DatasetType.byFiletype(
                link.fileTypeId as string
            )
            const type = datasetType!.shortName
            return datasetService
                .getDatasetByUuidAndType(link.uuid, type)
                .catch(() => {
                    return MISSING_DATASET
                })
        }
    }
    return Promise.resolve(null)
}

export const getJobStatesList = (jobId: number): Promise<string[]> => {
    return API.nJobEvents(jobId).then(response => {
        let events = response.data
        return events
            .filter(
                event =>
                    !event.eventTypeId ||
                    event.eventTypeId === "smrtlink_job_status"
            )
            .map(event => event.state || "")
    })
}

export const jobStatesIncludesFinishedState = (
    jobStates: string[]
): boolean => {
    return (
        jobStates.indexOf("SUCCESSFUL") !== -1 ||
        jobStates.indexOf("FAILED") !== -1 ||
        jobStates.indexOf("KILLED") !== -1 ||
        jobStates.indexOf("ABORTED") !== -1 ||
        jobStates.indexOf("TERMINATED") !== -1
    )
}

const MERGE_SOURCE_ID = "pbscala::merge_dataset"
const MERGE_SOURCE_ID_NEW = "sl_merge_datasets.merged"

export const getMergedDatasetForJob = (
    jobId: number,
    datasetType: DatasetType
): Promise<Dataset> => {
    return getJobDatastoreFiles(jobId, "merge-datasets").then(datastoreFiles => {
        const mergeResultFiles: DatastoreFile[] = datastoreFiles.filter(
            file => {
                return (
                    file.sourceId === MERGE_SOURCE_ID ||
                    file.sourceId === MERGE_SOURCE_ID_NEW
                )
            }
        )
        if (mergeResultFiles.length < 1) {
            return Promise.reject(null)
        }
        return API.aDatasetByUuid({
            uuid: mergeResultFiles[0].uuid,
            type: datasetType.shortName,
            projectId: null
        }).then(response => response.data)
    })
}

export const postMergeJob = (options: MergeJobOptions): Promise<Job> => {
    return API.aMergeJob(options).then(response => response.data)
}

export const postTechSupportJob = (options: TechSupportJobOptions): Promise<Job> => {
    return API.postJob("tech-support-job", options).then(response => response.data)
}

export const postTechSupportStatus = (options: TechSupportStatusOptions): Promise<Job> => {
    return API.postJob("tech-support-status", options).then(response => response.data)
}

export const postTechSupportJobHarvester = (options: TechSupportStatusOptions): Promise<Job> => {
    return API.postJob("tech-support-job-harvester", options).then(response => response.data)
}

export const postRestartTomcatJob = (): Promise<any> => {
    return API.postJob("restart-gui", {})
}

export const terminateJob = (jobId: number): Promise<Job> => {
    return API.terminateJob(jobId)
}

export const getJobPollerPromise = (
    jobId: number,
    intervals: number[] = [1, 1, 1, 1, 1, 5, 5, 5, 5, 30]
): PromisePollScheduler<Job> => {
    let poller = new PromisePollScheduler(
        () => {
            return API.aJob(jobId).then(response => response.data)
        },
        intervals,
        job => {
            return (
                job.state === "SUCCESSFUL" ||
                job.state === "FAILED" ||
                job.state === "KILLED"
            )
        }
    )
    return poller
}

export const pipelineNameForJob = (
    job: Job,
    pipelineTemplateMap: PipelineTemplateMap
): string => {
    const jsonSettings = JSON.parse(job.jsonSettings || "{}")
    const pipelineId = jsonSettings["pipelineId"] || "MissingPipelineId"

    const pipeline = pipelineTemplateMap[pipelineId]
    return pipeline && pipeline.name ? pipeline.name : pipelineId
}

export const isPreAnalysis = (jobDetails: JobDetails): boolean => {
    if (!jobDetails.pipelineDetails.pipeline) {
        return false
    }
    const pipelineId = jobDetails.pipelineDetails.pipeline.id
    const jobApplicationName = jobDetails.pipelineDetails.jobApplicationName
    return (
        pipelineId === "pbsmrtpipe.pipelines.sa3_ds_barcode2" ||
        pipelineId === "pbsmrtpipe.pipelines.sl_subreads_to_ccs_auto" ||
        pipelineId === "pbsmrtpipe.pipelines.sl_auto_ccs_barcode" ||
        jobApplicationName === "Demultiplex Barcodes (Auto)" ||
        jobApplicationName === "CCS Demultiplexing (Auto)" ||
        jobApplicationName === "Circular Consensus Sequences (CCS) [Automatic]"
    )
}

export const addPrimaryJobs = (model): void => {
    const run: RunModel = model.run

    run.samples.forEach(s => {
        if (s.enableCCS === "None") {
            // XXX we still allow subread auto-demux but this is deprecated
            if (s.isBarcoded) {
                let demux_job: DeferredJob = toSubreadDemuxJob(s)
                s.multiJobJobs.push(demux_job)
            }
        } else {
            if (s.enableCCS === "OffInstrument") {
                // instrument delivers subreads
                let ccs_job: DeferredJob = toGenerateCcsJob(s)
                s.multiJobJobs.push(ccs_job)
            } else if (!s.isBarcoded) {
                // for un-barcoded OICCS, export FASTQ
                let export_job: DeferredJob = toExportCcsJob(s)
                s.multiJobJobs.push(export_job)
            }
            // off-instrument demux
            if (s.isBarcoded && s.demuxBarcodes === "OffInstrument") {
                let demux_job: DeferredJob = toCcsDemuxJob(s)
                s.multiJobJobs.push(demux_job)
            }
        }
    })
}

const getMasterLog = async (jobId: number): Promise<string> => {
    const datastoreFiles$: Promise<DatastoreFile[]> = getJobDatastoreFiles(jobId, "analysis")

    let datastoreFiles = await datastoreFiles$
    datastoreFiles = datastoreFiles.filter(file => {
        return file.sourceId === MASTER_LOG_SOURCE_ID
    })
    if (!datastoreFiles.length) {
        return ""
    }
    const uuid = datastoreFiles[0].uuid
    if (!uuid) {
        return "Error fetching log for job " + jobId + ": uuid is undefined"
    }
    try {
        return await datastoreService.getFileDownload(uuid)
    } catch {
        return "Error fetching log for job " + jobId + ": log file is missing or could not be downloaded"
    }
}

const getPbsmrtpipeJobOptions = (jobId: number): Promise<JobOptions> => {
    const promise: Promise<JobOptions> = API.nJobOptions(jobId).then(
        response => response.data
    )
    return promise
}

const getJobDatastoreFiles = (jobId: number, jobType: string = "analysis"): Promise<DatastoreFile[]> => {
    return API.nDatastoreFiles({jobId, jobType}).then(
        response => response.data
    )
}

const getFileDownloads = (jobId: number): Promise<UiDatastoreFile[]> => {
    return getJobDatastoreFiles(jobId).then(
        files => files.map(file => processDatastoreFile(file))
    )
}

const addDisplayNameIfMissing = (file: UiDatastoreFile): UiDatastoreFile => {
    if (file.displayName) {
        return file
    }
    let displayName = file.name ? file.name : file.sourceId
    return Object.assign({}, file, { displayName })
}

const addDownloadUrl = (
    serverFile: UiDatastoreFile
): UiDatastoreFile => {
    return Object.assign({}, serverFile, {
        downloadUrl: API.aDatastoreFileDownloadUrl(serverFile.uuid)
    })
}

export const convertToUiJob = (
    serverJob: Job,
    pipelineTemplateMap?: PipelineTemplateMap
): UiJob => {
    let createdAt = serverJob.createdAt
        ? new Date(serverJob.createdAt)
        : undefined
    let importedAt = serverJob.importedAt
        ? new Date(serverJob.importedAt)
        : undefined
    let updatedAt = serverJob.updatedAt
        ? new Date(serverJob.updatedAt)
        : undefined
    const uiJob: UiJob = Object.assign({}, serverJob, {
        createdAt,
        importedAt,
        updatedAt
    })
    if (pipelineTemplateMap) {
        // adapted from "addAnalysisApplicationFieldToItems" in the angular code
        const jsonSettings = JSON.parse(serverJob.jsonSettings || "{}")
        const pipelineId = jsonSettings["pipelineId"]
        const template = pipelineTemplateMap[pipelineId]
        uiJob.analysisApplication = template ? template.name : pipelineId
    }
    return uiJob
}

// return the parent id of the "primary" entrypoint, if there is one
export const parentUuidForJob = ( jobDetails: JobDetails): string | null => {
    for (let dataset of jobDetails.datasetLinks) {
        if (isFileTypePrimary(dataset.fileTypeId)) {
            return dataset.parentUuid || null
        }
    }
    return null
}

export const getMultiJobApplicationName = (subJobTypeId, templateMap) => {
    let analysisName = ""
    const subJobTypeIds = subJobTypeId ? subJobTypeId.split(",") : [""]
    if (subJobTypeIds.length > 1) {
        analysisName = "multiple"
    } else if (templateMap[subJobTypeIds[0]]) {
        analysisName = templateMap[subJobTypeIds[0]].name
    }
    return analysisName
}

export const getJobBarcodeCsvDownload = async (jobId: number): Promise<string> => {
    const options = {"sourceId": "input_file.biosamples_csv"}
    const response = await API.nDatastoreFiles({jobId, options})
    if (response.data.length !== 1) {
        return null // Can be missing in old demux jobs.
    }
    const uuid = response.data[0].uuid
    return datastoreService.getFileDownload(uuid)
}

const processDatastoreFile = (
    file: DatastoreFile
): UiDatastoreFile => {
    return addDisplayNameIfMissing(
        addDownloadUrl(convertToUiDatastoreFile(file))
    )
}

const convertToUiDatastoreFile = (file: DatastoreFile): UiDatastoreFile => {
    let createdAt = file.createdAt ? new Date(file.createdAt) : undefined
    let modifiedAt = file.modifiedAt ? new Date(file.modifiedAt) : undefined
    let importedAt = file.importedAt ? new Date(file.importedAt) : undefined
    return Object.assign({}, file, { createdAt, modifiedAt, importedAt })
}

const convertToUiJobEvent = (event: JobEvent): UiJobEvent => {
    let createdAt = event.createdAt ? new Date(event.createdAt) : undefined
    return Object.assign({}, event, { createdAt })
}

const convertToJobStatusEvent = (event: JobStatusEvent): JobStatusEvent => {
    let createdAt = new Date(event.createdAt)
    return Object.assign({}, { createdAt }, event)
}

const getName = (filePath: string): string => {
    const pathParts = filePath.split("/")
    const fileName = pathParts[pathParts.length - 1]
    let fileParts = fileName.split(".")
    fileParts.pop()
    return fileParts.join(".")
}
