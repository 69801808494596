import { ColumnDef, GRID_INFINITE_SCROLLING_PAGE_SIZE } from "../../core-components/shared/Grid"
import { ColGroupDef, GridApi, ColDef } from "ag-grid-community"
import { smrtLinkDateFormat } from "./smrt-link-date-format"

export type ColDefOrColGroupDef = ColDef | ColGroupDef

export const agGridDateCompartor = (filterDate: Date, target: string) => {
    const targetDate = Date.parse(target)
    if (filterDate.getTime() === targetDate) return 0
    return filterDate.getTime() > targetDate ? -1 : 1
}

export const dateColumnDef = (colDef: ColDef ): ColDef => {
    return Object.assign(colDef, {
        cellRenderer: (params) => {return smrtLinkDateFormat(new Date(params.value))},
        filter: "agDateColumnFilter",
        filterParams: {
            comparator: agGridDateCompartor,
            buttons: ["clear","reset"]
        }
    })
}

export const numberColumnDef = (colDef: ColDef ): ColDef => {
    return Object.assign(colDef, {
        filter: "agNumberColumnFilter"
    })
}

export const equalsColumnDef = (colDef: ColDef ): ColDef => {
    return Object.assign(colDef, {
        filterParams: {
            filterOptions: ["equals"]
        }
    })
}

export const createLinkCellRenderer = ( getLink: (data: any) => string) => {
    return (row: any) => {
        if (!row.value) {
            return ""
        }
        const link = getLink(row.data)
        let span = document.createElement("span")
        span.innerHTML = `<a href=${link}> ${row.value} </a>`
        span.style.cursor = "pointer"
        return span
    }
}

export const createLinkOrNotCellRenderer = ( getLink: (data: any) => string) => {
    return (row: any) => {
        if (!row.value) {
            return ""
        }
        const link = getLink(row.data)
        let span = document.createElement("span")
        if (link) {
            span.innerHTML = `<a href=${link}> ${row.value} </a>`
            span.style.cursor = "pointer"
            return span
        } else {
            return row.value
        }

    }
}

export const scrollPositionLabel = (gridApi: GridApi): string => {
    // NOTE: assumes rowBuffer = 0 in gridOptions
    if (!gridApi) {
        return ""
    }
    let total = gridApi.getDisplayedRowCount()
    if (total === 0) {
        return ""
    }
    if (gridApi["infiniteRowModel"] && !gridApi.isLastRowIndexKnown() && total >= GRID_INFINITE_SCROLLING_PAGE_SIZE) {
        // Adjust for extra displayed row in infinite scrolling mode where shows "Loading"
        total -= 1
    }
    const first = gridApi.getFirstDisplayedRow() + 1
    const last = gridApi.getLastDisplayedRow() + 1
    if (!gridApi["infiniteRowModel"] || gridApi.isLastRowIndexKnown()) {
        return `Displaying rows ${first} to ${last} out of ${total}`
    } else if (first === 1 && last === 1) {
        return ""
    } else {
        return `Displaying rows ${first} to ${last} out of ${total} (scroll to load more)`
    }
}

export const cloneColDefs = (colDefs: ColumnDef[]) => {
    return colDefs.map( colDef => {
        const newColDef = Object.assign({}, colDef)

        const children = (colDef as ColGroupDef).children

        if (children && children.length > 0) {
            newColDef["children"] = cloneColDefs(children)
        }
        return newColDef
    })
}

export const quickFilterHandler = (api: GridApi, event: any) => {
    const filter = event.target.value
    if (api) {
        api.setQuickFilter(filter)
    }
}

export const gridDragOver = (event: any) => {
    try {
        const dragSupported = event.dataTransfer.types.length;
        if (dragSupported) {
            event.dataTransfer.dropEffect = "move";
            event.preventDefault();
        }
    } catch {
        // Do nothing.  This is reached harmlessly in cypress tests
        // because somehow the event doesn't get the dataTransfer (though it works!).
    }
};

export interface DragBetweenGridsEvent {
    event: any
    leftOrRight: "left" | "right"
    rightGridApi: GridApi
    leftGridApi: GridApi
    idField: string
}
export const dragBetweenGridsDropHandler = (dbgEvent: DragBetweenGridsEvent) => {

    const {event, leftOrRight, rightGridApi, leftGridApi, idField} = dbgEvent
    event.preventDefault();

    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf("Trident/") >= 0;
    const jsonData = event.dataTransfer.getData(isIE ? "text" : "application/json");
    const data = JSON.parse(jsonData);

    // if data missing or data has no id, do nothing
    if (!data || data[idField] == null) {
        return;
    }

    const sourceGridApi = leftOrRight === "left" ? rightGridApi : leftGridApi
    const destinationGridApi = leftOrRight === "left" ? leftGridApi : rightGridApi

    const sourceNode = sourceGridApi.getRowNode(data[idField])

    // If we are dropping into the destinationGrid, verify that the source is really the sourceGrid
    if (!sourceNode ) {
        return
    }

    // add the current node to the set of selected nodes, if it's not there already
    sourceNode.setSelected(true)

    const sourceRows = sourceGridApi.getSelectedRows()

    const destinationRows = []
    for (let row of sourceRows) {
        const rowAlreadyInGrid = !!destinationGridApi.getRowNode(row[idField]);
        if (!rowAlreadyInGrid) {
            destinationRows.push(row)
        }
    }
    if (destinationRows.length > 0) {
        sourceGridApi.applyTransaction({remove: destinationRows});
        destinationGridApi.applyTransaction({add: destinationRows});
    }
};

export const getGroupIds = (colDefs: ColDefOrColGroupDef[]): string[] => {

    const getGroupIdsIter = (colDefs: ColDefOrColGroupDef[], currentIds: string[]): string[] => {
        let ids = [...currentIds]
        for (let colDef of colDefs) {
            if ( "children" in colDef) {
                ids.push(colDef.groupId)
                ids = getGroupIdsIter( (colDef as ColGroupDef).children, ids)
            }
        }
        return ids
    }
    return getGroupIdsIter(colDefs, [])
}

export class CheckboxHeaderComponent {

    private checkbox: HTMLInputElement = document.createElement("input")

    constructor() {
        this.checkbox = document.createElement("input")
        this.checkbox.setAttribute("type", "checkbox")
    }

    public init( {isHeaderChecked, onToggle, id}: {isHeaderChecked: boolean, onToggle: (boolean) => void, id: string} ) {
        this.checkbox.checked =isHeaderChecked
        this.checkbox.addEventListener("click", event => {
            this.checkbox.checked = (event.target as HTMLInputElement).checked
            onToggle(this.checkbox.checked)
        })
        if (id) {
            this.checkbox.id = id
        }
    }

    public getGui() {
        return this.checkbox
    }

    public refresh() {
        return this.getGui()
    }
}

export class EmptyHeaderComponent {

    private span: HTMLElement = document.createElement("span")

    public init() {}

    public getGui() {
        return this.span
    }

    public refresh() {
        return this.getGui()
    }
}

export const loadingMarkup = (inner: string) =>
`<span class="ag-overlay-loading-center" style="pointer-events: auto;">${inner}</span>`

export const tooltipHeaderTemplate = (): string => {
    return `<div class="ag-cell-label-container" role="presentation">
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span class="circle-badge info-badge ml-1 badge badge-light">
                            <span class="circle-badge-inner">
                                <span class="oi oi-info"></span>
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle class="fill-info" cx="8" cy="8" r="8" />
                                </svg>
                            </span>
                        </span>
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                    </div>
                </div>`
}
