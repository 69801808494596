import { Reducer } from "redux"
import { PartsApiState, PartsActionTypes } from "./parts-api-types"
import { getPartNumbersFromAutomationConstraintsXML, PartsService, PartLookupService } from "../../model/parts-model"

const initialState: PartsApiState = {
    loading: false,
    data: null,
    error: null,
    partsService: null,
    partLookupService: null
}

export const partsReducer: Reducer<PartsApiState> = (state: PartsApiState = initialState, action: any) => {
    switch (action.type) {
        case PartsActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case PartsActionTypes.API_SUCCESS: {
            const data = getPartNumbersFromAutomationConstraintsXML(action.payload)
            const partsService = new PartsService()
            partsService.setPartNumbers(data)
            const partLookupService = new PartLookupService(partsService)

            return Object.assign({}, state, {
                loading: false,
                data,
                partsService,
                partLookupService
            })
        }
        case PartsActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        default:
            return state
    }
}
