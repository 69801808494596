export const parseUrlParams = (query: string): {[key:string]:string;} => {
    if (query.length === 0) {
        return {}
    }
    let hashes = query.slice(query.indexOf("?") + 1).split("&")
    let params = {}
    hashes.forEach(hash => {
        let [key, val] = hash.split("=")
        params[key] = decodeURIComponent(val)
    })
    return params
}

export const constructUrlQuery = (params: {[key:string]:string;}): string => {
    const keys = Object.keys(params)
    if (keys.length === 0) { return "" }
    let tokens = []
    for (let key of keys) {
        tokens.push(`${key}=${params[key]}`)
    }
    return "?" + tokens.join("&")
}

export const buildQueryStr = (query: any) => {
    let queryStr = ""
    Object.keys(query).forEach(key => {
        if (!queryStr) {
            queryStr += "?"
        } else {
            queryStr += "&"
        }
        const val = query[key]
        if (val) {
            queryStr += `${key}=${val}`
        }
    })
    return queryStr
}
