import { Reducer } from "redux"
import { AnalysisResultsApiState, AnalysisResultsTypes } from "./analysis-results-types"

const initialState: AnalysisResultsApiState = {
    loading: false,
    isValidJob: null,
    loadingJobDetails: false,
    loadingReports: false,
    loadingAnalysisJobs: false,
    jobDetails: null,
    reports: null,
    error: null
}

export const analysisResultsReducer: Reducer<AnalysisResultsApiState> =
    (state: AnalysisResultsApiState = initialState, action: any) => {
        switch (action.type) {
            case AnalysisResultsTypes.SET_IS_VALID_JOB:
                return Object.assign({}, state, {
                    isValidJob: action.payload
                })

            case AnalysisResultsTypes.API_ATTEMPT_JOB_DETAILS:
                return Object.assign({}, state, {
                    loadingJobDetails: true
                })

            case AnalysisResultsTypes.API_SUCCESS_JOB_DETAILS:
                return Object.assign({}, state, {
                    loadingJobDetails: false
                })

            case AnalysisResultsTypes.API_FAILURE_JOB_DETAILS:
                return Object.assign({}, state, {
                    loadingJobDetails: false,
                    error: action.payload
                })


            case AnalysisResultsTypes.SET_JOB_DETAILS:
                return Object.assign({}, state, {
                    loadingJobDetails: false,
                    jobDetails: action.payload
                })

            case AnalysisResultsTypes.API_ATTEMPT_REPORTS:
                return Object.assign({}, state, {
                    loadingReports: true
                })

            case AnalysisResultsTypes.API_SUCCESS_REPORTS:
                return Object.assign({}, state, {
                    loadingReports: false
                })

            case AnalysisResultsTypes.API_FAILURE_REPORTS:
                return Object.assign({}, state, {
                    loadingReports: false,
                    error: action.payload
                })

            case AnalysisResultsTypes.SET_REPORTS:
                return Object.assign({}, state, {
                    loadingReports: false,
                    reports: action.payload
                })
            case AnalysisResultsTypes.API_ATTEMPT:
                return Object.assign({}, state, {
                    loading: true
                })

            case AnalysisResultsTypes.API_SUCCESS:
                return Object.assign({}, state, {
                    loading: false
                })

            case AnalysisResultsTypes.API_FAILURE:
                return Object.assign({}, state, {
                    loading: false
                })

            default:
                return state
        }
}
