import { ColDef, ColGroupDef } from "ag-grid-community"
import { mergeArraysByProperty } from "../../../core/utils/array-helper"

export const ROOT = "root"

export interface DrillDownColRef extends ColDef {
    drillDown?: (data: any) => void
}
export type DrillColumnDef = ColGroupDef | DrillDownColRef

export interface PreDrillDownData {
    key: string
    value: string
    drilldownColumn: string
}

export interface ParentTableInfo {
    parentData: any
    idField: string
    parentNameField: string
    rootTitle: string,
    childTitlePrefix: string
}

export const parentKeyFrom = (info: ParentTableInfo): string => {
    const {parentData, idField} = info
    return parentData ? parentData[idField] : ROOT
}

export const titleFrom = (info: ParentTableInfo): string => {
    const {parentData, parentNameField, rootTitle, childTitlePrefix} = info
    return parentData ? `${childTitlePrefix} ${parentData[parentNameField]}` : rootTitle
}

// SelectionMap maintains a set of selections across a drilldown table tree
export class SelectionMap {
    private selections:{ [key:string]: any[]; } = {} // a map from parent table uuid to a list of selected data objects
    private keyGenerator: (data: any) => string
    private parentIdFromKey: (key: string) => string

    public constructor(keyGenerator: (data: any) => string, parentIdFromKey?: (key: string) => string) {
        this.keyGenerator = keyGenerator
        if (!parentIdFromKey) {
            this.parentIdFromKey = (key) => key // the usual case, where the key is just the id of the parent.
        }
        this.selections[ROOT] = []
    }

    public getSelectedChildren(parent: any): any[] {
        const key = this.keyGenerator(parent)
        return this.get(key)
    }

    public get(key: string): any[] {
        return this.selections[key] || []
    }

    public getParentIdFromKey = (key: string) => {
        return this.parentIdFromKey(key)
    }

    public parentIdsOfSelectedChildren = () => {
        const nonRootKeys = this.selections.keys.filter( key => key !== ROOT)
        return nonRootKeys.map(this.getParentIdFromKey)
    }

    public addSelectionForParent(parentUuid: string, data: any): SelectionMap {
        const selectionMap = this.clone()
        if (parentUuid) {
            if (!selectionMap.selections[parentUuid]) {
                selectionMap.selections[parentUuid] = []
            }
            selectionMap.selections[parentUuid].push(data)
        } else {
            selectionMap.selections[ROOT].push(data)
        }
        return selectionMap
    }

    public mergeSelectionsForParent(parent: any, selected: any[], notSelected: any[], idField): SelectionMap {
        const key = this.keyGenerator(parent)
        const k = key ? key : ROOT


        let currentSelected = this.selections[k] ? [...this.selections[k]] : []

        // remove unselected rows
        if (currentSelected.length > 0) {
            const notSelectedIds = notSelected.map(data => data[idField])
            currentSelected = currentSelected.filter( data => !(notSelectedIds.includes(data[idField])))
        }

        const newSelections = mergeArraysByProperty(currentSelected, selected, idField)
        return this.setSelectionsForKey(key, newSelections)
    }

    public setSelectionsForKey(key: string, data: any[]):SelectionMap {
        const selectionMap = this.clone()
        const k = key ? key : ROOT
        selectionMap.selections[k] = data // items
        return selectionMap
    }

    public removeOtherThan(data: any[]): SelectionMap {
        const selectionMap = this.clone()
        // note: assumes that every item in data is already somewhere in this.selections
        const selectedIds = data.map(item => selectionMap.keyGenerator(item) )
        for (const key of Object.keys(selectionMap.selections)) {
            selectionMap.selections[key] = selectionMap.selections[key].filter(
                selection => {
                    const id = selectionMap.keyGenerator(selection)
                    return selectedIds.indexOf(id) !== -1
                }
            )
        }
        return selectionMap
    }

    public getAllSelected(): any[] {
        let all = []
        for (let key of Object.keys(this.selections)) {
            const selections = this.selections[key]
            all = all.concat(selections)
        }
        return all
    }

    public isSelected(data: any): boolean {
        const selectedIds = this.getAllSelected().map(this.keyGenerator)
        const dataId = this.keyGenerator(data)
        return selectedIds.includes(dataId)
    }

    public getNumSelectedChildren(data: any): number {
        const key: string = this.keyGenerator(data)
        const selectedChildren = this.get(key)
        return selectedChildren ? selectedChildren.length : 0
    }

    public filterBy( filter: (data: any)=> boolean  ): SelectionMap {
        const clone = this.clone()
        for (let key of Object.keys(this.selections)) {
            const filtered = this.selections[key].filter(filter)
            if (filtered.length > 0) {
                clone.selections[key] = filtered
            } else {
                delete clone.selections[key]
            }
        }
        return clone
    }

    public clone(): SelectionMap {
        const copy = new SelectionMap(this.keyGenerator)
        copy.selections = Object.assign( {}, this.selections )
        return copy
    }

    public flatten(): SelectionMap {
        const newMap = new SelectionMap(this.keyGenerator)
        newMap.selections[ROOT] = [...this.getAllSelected()]
        return newMap
    }

    public unFlatten( parentIdField: string): SelectionMap {
        let newMap = new SelectionMap(this.keyGenerator)
        for (let item of this.getAllSelected()) {
            const id = item[parentIdField]
            newMap = newMap.addSelectionForParent(id, item)
        }
        return newMap
    }

    public isSameSelected(other: SelectionMap): boolean {
        if (!other) {
            return false
        }
        return this.isSameSelectedArray(other.getAllSelected())
    }

    public isSameSelectedArray(data: any[]): boolean {
        if (!data) {
            return false
        }
        let thisIds = this.getAllSelected().map( item => this.keyGenerator(item))
        let otherIds = data.map( item => this.keyGenerator(item))

        if (!thisIds && !otherIds) {
            return true
        }
        if (!thisIds || !otherIds) {
            return false
        }
        if(thisIds.length !== otherIds.length) {
            return false
        }
        thisIds = thisIds.sort()
        otherIds = otherIds.sort()
        for (let i = 0; i < thisIds.length; i++) {
            if ( thisIds[i] !== otherIds[i]) {
                return false
            }
        }
        return true
    }

    public static fromField(field: string): SelectionMap {
        // simple SelectionMap that is mapped by a field in the data
        return new SelectionMap( (data: any) => {
            if (!data) {
                return ROOT
            }
            return data[field]
        })
    }

}
