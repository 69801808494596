export class Model {

    public initialize(object?: {}) {
        if (object) {
            Object.assign(this, object)
        }
    }

    clone(): this {
        return new (this as any).constructor(this)
    }
}
