// This is a client model class that is converted into from two orthogonal sources that use different user models:
//  -- 1. The user lookup services described described by adminService and adminServiceV2
//  -- 2. The login mechanism that uses the smrtlink/user api
// TODO SL-8214 disentagle these two model classes.

import { UserResponse } from "../../core-components/login/store/login-types"

export interface User {
  userId: string
  username?: string
  email: string 
  phone?: string
  fullName?: string
  firstName?: string
  lastName?: string
  roles: string[]
  uuid?: string // corresponds to KeycloakUser.id
}

export const isAdmin = (user: User) => {
    return user.roles.includes("Internal/PbAdmin")
}

export const userFromUserResponse = (userResponse: UserResponse) => {
  return {
    userId: userResponse.userId,
    email: userResponse.userEmail,
    roles: userResponse.roles,
    firstName: userResponse.firstName,
    lastName: userResponse.lastName
  }
}

export interface UserValues {
  enabled: boolean
  role: string
}

export interface UserRoleData {
  added?: string[]
  deleted?: string[]
}

// API v2.0.0, using the Keycloak server
export interface KeycloakUserRole {
  id: string
  name: string
}

export interface KeycloakUser {
  id: string
  username: string
  email: string,
  firstName?: string
  lastName?: string
}
