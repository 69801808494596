import { Reducer } from "redux"
import { DatasetDetailsState, DatasetDetailsActionTypes } from "./dataset-details-types"

const initialState: DatasetDetailsState = {
    loading: false,
    isValidDataset: null,
    details: null,
    jobDetails: null,
    error: null
}

export const datasetDetailsReducer: Reducer<DatasetDetailsState> =
    (state: DatasetDetailsState = initialState, action: any) => {
    switch (action.type) {
        case DatasetDetailsActionTypes.SET_IS_VALID_DATASET:
            return Object.assign({}, state, {
                isValidDataset: action.payload
            })
        case DatasetDetailsActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case DatasetDetailsActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case DatasetDetailsActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case DatasetDetailsActionTypes.GET_ALL_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                details: action.payload.details,
                jobDetails: action.payload.jobDetails
            })
        default:
            return state
    }
}
