import { BaseTaskOption } from "./job-model"
import { DataType } from "./data-type"

// corresponds to pbsmrtpipe.pb_pipelines.pb_pipelines_sa3.Tags
export const PIPELINE_TAG = {
    MAP: "mapping",
    CONSENSUS: "consensus",
    RPT: "reports",
    CCS: "ccs",
    LAA: "laa",
    MOD_DET: "modification-detection",
    MOTIF: "motif-analysis",
    ISOSEQ: "isoseq",
    DENOVO: "denovo",
    SAT: "sat",
    CONVERTER: "converters",
    DEV: "dev",
    INTERNAL: "internal",
    ALPHA: "alpha",
    OBSOLETE: "obsolete",
    ANALYSIS: "analysis",
    AUTOANALYSIS: "auto-analysis"

}

export interface PipelineTemplateMap {
    [key: string]: PipelineTemplate
}

export interface PipelineTaskOption extends BaseTaskOption {
    name: string
    description: string
    default: any
    format?: string
    choices?: string[]
}

export interface PipelineEntryPoint {
    entryId: string
    fileTypeId?: string
    name?: string
    optional?: boolean
}

export interface PipelineTemplate {
    description?: string
    entryPoints: PipelineEntryPoint[]
    id: string
    name: string
    options?: any
    tags?: string[]
    taskOptions?: PipelineTaskOption[]
    version?: string
}

export const PIPELINE_ENTRY_IDS = {
    Subread: "eid_subread",
    CCS: "eid_ccs"
}

export const ENTRY_ID_TO_DATATYPE = {
    "eid_subread" : DataType.SUBREAD_TYPE,
    "eid_ccs": DataType.CCS_READ_TYPE
}

export const PRIMARY_ENTRY_IDS = [
    PIPELINE_ENTRY_IDS.Subread,
    PIPELINE_ENTRY_IDS.CCS
]

export function isPrimaryEntryPoint(entryPoint: PipelineEntryPoint): boolean {
    if (!entryPoint.entryId) {
        return false
    }
    return PRIMARY_ENTRY_IDS.indexOf( entryPoint.entryId) > -1
}

export function hasPrimaryEntryPoint(pipeline: PipelineTemplate): boolean {
    for (let entryPoint of pipeline.entryPoints) {
        if (isPrimaryEntryPoint(entryPoint)) {
            return true
        }
    }
    return false
}

export function isAnalysisWorkflow (pipeline: PipelineTemplate): boolean {
    return pipeline.tags && pipeline.tags.includes(PIPELINE_TAG.ANALYSIS)
}

export function parseValueAsType(value: string, optionType: string, defaultValue?: any): any {
    switch (optionType) {
        case "integer":
        case "choice_integer": {
            const intResult = parseInt(value, 10)
            return isNaN(intResult) ? (defaultValue || 0) : intResult
        }
        case "float":
        case "choice_float": {
            const floatResult = parseFloat(value)
            return isNaN(floatResult) ? (defaultValue || 0.0) : floatResult
        }
            case "boolean":
        case "choice_boolean":
            try {
                return !!JSON.parse(value)
            } catch (e) {
                return (defaultValue || false)
            }
        case "string":
        case "choice_string":
            return value || defaultValue || ""
        default:
            return  value || defaultValue || ""
    }
}