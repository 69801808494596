import { Store } from "redux"
import { ApplicationState } from "./types"
import { logout } from "../core-components/login/store/login-actions"

export function configureLocalStorage(store: Store<ApplicationState, any>) {
    // Auth status listener
    window.addEventListener("storage", (e: StorageEvent) => {
        if (e.key === "authStatus" && e.newValue === "LOG_OUT") {
            store.dispatch(logout())
            sessionStorage.clear()
        }
        if (e.key === "authStatus" && e.newValue === "LOG_IN") {
            // Something to do in login
        }
    })
}
