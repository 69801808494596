import { useEffect, useState } from "react"
import { apiCache } from "../../data/services/api-cache"

export const useIsSmrtlinkLite = () => {
    const [isSmrtLinkLite, setIsSmrtLinkLite] = useState<boolean>(false)

    useEffect( () => {
        apiCache.getSlStatus().then ( slStatus => {
            const isLite = slStatus.apiFlags && slStatus.apiFlags.isSmrtLinkLite
            setIsSmrtLinkLite(isLite)
        })
    }, [])
    return isSmrtLinkLite
}
