import * as API from "../api"
import { UiDataset } from "../model/ui-dataset-model"
import { Dataset } from "../model/dataset-model"
import { DatastoreReportFile } from "../model/datastore-report-file-model"
import { DatasetType } from "../model/dataset-type-model"
import * as jobService from "./job-service"
import * as projectService from "./project-service"
import { MergeJobOptions } from "../model/job-options-model"
import { Job } from "../model/job-model"
import { CatalogSpec } from "../utils/shared/catalog-helpers"

export const getUiDataset = (
    datasetId: number | string,
    dataType: string,
    projectId: number = projectService.UNSELECTED_PROJECT_ID
): Promise<UiDataset> => {
    let isUuid = isNaN(parseInt(datasetId as string, 10))

    const datasetsPromise = () => {
        if (isUuid) {
            return dataType
                ? API.aDatasetByUuid({
                    uuid: datasetId as string,
                    type: dataType,
                    projectId,
                })
                : API.anyDatasetByUuid(datasetId as string)
        } else {
            return dataType
                ? API.aDatasetById({id: datasetId as number, type: dataType, projectId})
                : API.anyDatasetById(datasetId as number)
        }
    }

    return datasetsPromise().then(response => {
        const dataset = response.data
        return getRunUuidForDataset(dataset).then( runUuid => {

            return transformDataset(response.data, runUuid)

        })
    })
}

export const getBarcodes = (
    barcodeUuid: string,
    sameBarcodesBothEnds: boolean
): Promise<string[]> => {
    if (!barcodeUuid) {
        return Promise.resolve([])
    }
    return API.nRecordNamesByUuid("barcodes", barcodeUuid).then(response => {
        const recordNames = response.data
        if (sameBarcodesBothEnds) {
            return recordNames.map(barcode => barcode + "--" + barcode)
        } else {
            let result: string[] = []
            for (let a = 0; a < recordNames.length - 1; ++a) {
                for (let b = a + 1; b < recordNames.length; ++b) {
                    result.push(recordNames[a] + "--" + recordNames[b])
                }
            }
            return result
        }
    })
}

export const getDatasetByUuid = (uuid: string): Promise<Dataset> => {
    return API.anyDatasetByUuid(uuid).then(response => response.data)
}

export const getDatasetByUuidAndType = (
    uuid: string,
    type: string
): Promise<Dataset> => {
    return API.aDatasetByUuid({
        uuid,
        type,
        projectId: null,
        reportError: API.ERROR_MODE.custom
    }).then(response => {
        return response.data
    }).catch((e) => {
        return e.data.httpCode })
}

export const getDatasetsBySearchAndType = (
    idsOrUuids: any[], // assumption:  idsOrUuids contains only ids or only uuids, but not both
    type: string
): Promise<Dataset[]> => {

    const field = isNaN(Number(idsOrUuids[0])) ? "uuid" : "id"
    let query = {}
    query[field]  = "in:" + idsOrUuids.join(",")
    return API.datasetSearch(type, query).then(response => {
        // reorder datasets to match order of vals argument;
        const results = response.data
        let resultsMap = {}
        for (let dataset of results) {
            const val = dataset[field]
            resultsMap[val] = dataset
        }
        const sorted = []
        for (let val of idsOrUuids) {
            if (resultsMap[val]) {
                sorted.push(resultsMap[val])
            }
        }
        return sorted
    })
}

export const getDatasetReports = (uuid: string, type: string): Promise<DatastoreReportFile[]> => {
    return API.anyDatasetRptsByUuid(uuid, type).then(response => response.data)
}

export const deleteDataset = (id: number): Promise<any> => {
    return API.putAnyDatasetById(id, { isActive: false })
}

export const transformDataset = (dataset: Dataset, runUuid?: string): UiDataset => {
    let createdAt = new Date(dataset.createdAt)
    let updatedAt = new Date(dataset.updatedAt)
    let importedAt = new Date(dataset.importedAt)
    let datasetType = DatasetType.byFiletype(dataset.datasetType)
    const uiDataset: UiDataset = Object.assign({}, dataset, {
        createdAt,
        updatedAt,
        importedAt,
        datasetType
    })
    if (runUuid) {
        uiDataset.runUuid = runUuid
    }
    return uiDataset
}

export const getJobsForDataset = (datasetId: number): Promise<Job[]> => {
    return API.nJobsByDatasetIdOrUuid(datasetId).then(response => response.data)
}

export const getRunUuidForDataset = (dataset: Dataset): Promise<string | undefined> => {
    const movieName = dataset.metadataContextId
    if (movieName) {
        return API.nRunsMovieName(movieName).then( response => {
            const data = response.data
            let runUuid = data.length ? data[0].uniqueId : undefined
            return runUuid
        }).catch(() => {
            return Promise.resolve(undefined)  // ignore errors
        })
    }
    return Promise.resolve(undefined)
}

/*
Kick off a merge job with the currently selected datasets.  Poll for completion of the job.
When the job completes, return the merged dataset.  Takes a name for the merged dataset.
If name is not provided create one.
*/
export const AUTO_MERGE_PREFIX = "Auto-merged"

interface MergeJobError {
    jobId: number
}

export const mergeDatasets = (
    ids: number[],
    datasetType: DatasetType,
    datasetName?: string,
    autoMergeMode?: boolean
): Promise<Dataset> => {
    if (ids.length === 0) {
        const error =
            "mergeSelectedDatasets was called with fewer than 1 selected datasets"
        throw new Error(error)
    }

    datasetName = datasetName
        ? datasetName
        : `${AUTO_MERGE_PREFIX} ${datasetType.shortName} @ ${Date.now()}`
    const mergeOptions: MergeJobOptions = new MergeJobOptions(
        ids,
        datasetName,
        autoMergeMode
    )

    return jobService.postMergeJob(mergeOptions).then(
        (job: Job) => {
            if (job.id === undefined) {
                return Promise.reject("Missing job id")
            }
            return jobService
                .getJobPollerPromise(job.id)
                .start()
                .then(job => {
                    if (job.state === "FAILED") {
                        const error: MergeJobError = {jobId: job.id }
                        return Promise.reject(error)
                    }
                    return getDatasetForMergeJob(job.id, datasetType)
                })
        },
        (error: any) => {
            return Promise.reject<Dataset>(error)
        }
    )
}

const getDatasetForMergeJob = (
    jobId: number,
    datasetType: DatasetType
): Promise<Dataset> => {
    return jobService
        .getMergedDatasetForJob(jobId, datasetType)
        .then(dataset => {
            if (dataset.id === undefined) {
                return Promise.reject("Missing dataset id")
            }
            return dataset
        })
}

export const getEntryPointDatasets = async (entryPointCatalog: CatalogSpec): Promise<Dataset[]> => {

    if (!entryPointCatalog) {
        return Promise.resolve(null)
    }
    const { type } = entryPointCatalog.args
    const setType = typeof type.shortName === "string" ? type.shortName : type.shortName()

    const datasets = await API.nDatasets({setType: setType}).then( response => response.data)

    const tagsFilter = ( dataset: Dataset ): boolean => {
        const splitOrEmptySet = (s: string) => s ? s.split(",") : []
        const requiredTags = splitOrEmptySet(entryPointCatalog.vrRequiredTags)
        if (requiredTags.length > 0) {
            const datasetTags = splitOrEmptySet(dataset.tags)
            for (let tag of requiredTags) {
                if (! (datasetTags.includes(tag)) ) {
                    return false
                }
            }
        }
        return true
    }
    return datasets.filter(tagsFilter)
}
