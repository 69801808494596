import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { IDoesFilterPassParams, IFilterParams } from "ag-grid-community";
import { CheckboxInput } from "./CheckboxInput";

interface Props extends IFilterParams {
    optionsGenerator: (field: string) => string[]
}

export const CheckboxSetFilter = forwardRef((props: Props, ref) => {
    const [selections, setSelections] = useState<string[]>([]);
  
    const { field } = props.colDef

    
    const options = useMemo( () => 
        props.optionsGenerator(field), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  
    // ---- Methods called by framework:
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams) {
                const { colDef } = props;
                const { node } = params;
                
                if (selections.length === 0) {
                    return true
                }
                const value = node.data[colDef.field]
                return selections.includes(value) 
            },
            isFilterActive() {
                return selections.length > 0;
            },
            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }
                return { value: selections };
            },
            setModel(model: any) {
                if (model) {
                    setSelections(model.value);
                }
            },
        };
    });
    // ---------------------------------


    useEffect(() => {
        props.filterChangedCallback(); // calls the framework methods
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);
  
    const onSelect = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const value = evt.target.value
        const newSelections = evt.target.checked ? 
        [...selections, value] :
        selections.filter(val => val !== value)       
        setSelections(newSelections)
    }

    const areAllSelected = () => {
        return selections.length === options.length
    }

    const onToggleAll = () => {
        const selected = areAllSelected() ? [] : options
        setSelections(selected)
    }

    return (
        <div
            className={field + "-wrapper align-items-center mb-2 outline-FPO"}
            role="region"
            aria-label="checkboxes to filter selections"
            style={{margin: "1rem"}}
        >
            <CheckboxInput
                name={`${field}_SelectAll`}
                key={`${field}_SelectAll`}
                label="Select All"
                checked={areAllSelected()}
                onChange={ () => onToggleAll()}
            />
        <hr></hr>
        
        {options.map(value => (
            <CheckboxInput
                name={`${field}_${value}`}
                key={`${field}_${value}`}
                label={value}
                value={value}
                checked={selections.includes(value)}
                onChange={ (evt) => onSelect(evt)}
            />
        ))}
        </div>
    );
  });
