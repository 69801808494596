import { FileTransferLocation, InstrumentSummary } from "../../model/instrument-configuration-model"

export const InstrumentSettingsActionTypes = {
  API_ATTEMPT_INSTRUMENTS: "@@instrument-settings/API_ATTEMPT_INSTRUMENTS",
  API_SUCCESS_INSTRUMENTS: "@@instrument-settings/API_SUCCESS_INSTRUMENTS",
  API_FAILURE_INSTRUMENTS: "@@instrument-settings/API_FAILURE_INSTRUMENTS",

  API_ATTEMPT_LOCATIONS: "@@instrument-settings/API_ATTEMPT_LOCATIONS",
  API_SUCCESS_LOCATIONS: "@@instrument-settings/API_SUCCESS_LOCATIONS",
  API_FAILURE_LOCATIONS: "@@instrument-settings/API_FAILURE_LOCATIONS",

  SET_INSTRUMENTS: "@@instrument-settings/SET_INSTRUMENTS",
  SET_LOCATIONS: "@@instrument-settings/SET_LOCATIONS",
}

export interface InstrumentSettingsState {
  // FIXME These are not the configs, just summary objects for table views
  readonly instrumentConfigs: InstrumentSummary[]
  readonly fileTransferLocations: FileTransferLocation[]
  readonly loadingInstrumentConfigs: boolean
  readonly errorInstruments: Error | null
  readonly loadingLocations: boolean
  readonly errorLocations: Error | null
}
