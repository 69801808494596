export enum ImportType {
  AnalysisZIP,
  BarcodeXML,
  BarcodeFASTA,
  Reference,
  ReferenceSet,
  GMAPReference,
  GMAPReferenceFASTA,
  SubreadOrCCS,
  BarcodeZIP,
  ReferenceSetZIP,
  GMAPReferenceZIP,
  SubreadOrCCSZIP,
  TargetRegionsBED
}
