import React, { Component } from "react"
import {
  Container,
  Row,
  Col
} from "reactstrap"

const BODY_THEME_CLASS = "btheme-base"

export class NotFoundLayout extends Component {

  componentDidMount() {
    document.body.classList.add(BODY_THEME_CLASS)
  }

  componentWillUnmount() {
    document.body.classList.remove(BODY_THEME_CLASS)
  }

  render() {
    return (
      <Container className="container-main bg-white" fluid>
        <div className="inner-container">
            <Row>
                <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                    <h1 className="display-4 font-weight-bold mt-5">We're Sorry&#8230;</h1>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                    <h2 className="font-weight-bold">
                        The page you're looking for is not available for one of the following reasons:
                    </h2>
                    <ul className="lead">
                        <li>The link to this page may be incorrect or out-of-date.</li>
                        <li>You may have bookmarked a page that has been removed.</li>
                    </ul>
                    <p className="lead">Please continue using SMRT® Link from the navbar. You can also contact us for help.</p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                    <div className="mt-2">
                        <p className="text-muted text-uppercase font-weight-sbold">Error Code: 404.&nbsp;
                            {/* <small>SMRT® Link version 8.TODO</small> */}
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
      </Container>
    )
  }
}