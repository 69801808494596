import { User } from "../../../data/model/user-model"

export const AuthActionTypes = {
    API_ATTEMPT: "@@auth/API_ATTEMPT",
    API_SUCCESS: "@@auth/API_SUCCESS",
    API_FAILURE: "@@auth/API_FAILURE",

    SET_USER: "@@auth/SET_USER",
    SET_TOKEN: "@@auth/SET_TOKEN",
    SET_PENDING_ACTION: "@@auth/SET_PENDING_ACTION",
    LOGIN: "@@auth/LOGIN"
}

export interface AuthState {
    loading: boolean
    errorMessage: string
    readonly user: User | null
    readonly token: string | null
    readonly refreshToken: string | null
}

export interface LoginPayload {
    username: string
    password: string
    disableRedirect?: boolean
}

export interface ErrorResponse {
    error: string
    error_description: string
    message: string
    status: string
}

export interface TokenResponse extends ErrorResponse {
    access_token: string
    refresh_token: string
    id_token: string
    expires_in: number
    scope: string
    token_type: string
}

export interface UserResponse extends ErrorResponse {
    userId: string
    roles: string[]
    userEmail?: string
    firstName?: string
    lastName?: string
}

export const RoleMapping = {
    "Internal/PbAdmin": "Admin",
    "Internal/PbLabTech": "Lab Tech",
    "Internal/PbBioinformatician": "Bioinformatician",
    "Internal/PbInstrument": "Instrument",
    // for V2 API (Keycloak) we don't have the prefix any more
    "PbAdmin": "Admin",
    "PbLabTech": "Lab Tech",
    "PbBioinformatician": "Bioinformatician",
    "PbInstrument": "Instrument"
}
