import React, { FunctionComponent } from "react"
import { ButtonGroup, Button } from "reactstrap"
import { InstrumentSupport } from "../../data/stores/instrument/instrument-types"
import { ApplicationState } from "../../core/types"
import { updateCurrentInstrumentType } from "../../data/stores/instrument/instrument-actions"
import { useSelector, useDispatch } from "react-redux"
import { InstrumentType, isSameInstrumentType } from "../../data/model/instrument-type-model"

export const InstrumentToggle: FunctionComponent = () => {

    const dispatch = useDispatch()

    const instrumentSupport = useSelector<ApplicationState, InstrumentSupport>( state => state.instrument.instrumentSupport )
    const currentInstrumentType = useSelector<ApplicationState, InstrumentType>( state => state.instrument.currentInstrumentType )

    const { supportsSequel, supportsSequel2, supportsSequel2e, supportsRevio } = instrumentSupport

    const isActiveClass = (type: InstrumentType) => (isSameInstrumentType(type, currentInstrumentType)) ? "active" : ""

    let availableInstrumentTypeCount = 0
    if (supportsSequel) { availableInstrumentTypeCount++ }
    if (supportsSequel2) { availableInstrumentTypeCount++ }
    if (supportsSequel2e) { availableInstrumentTypeCount++ }
    if (supportsRevio) { availableInstrumentTypeCount++ }

    if (!availableInstrumentTypeCount) { return null }

    const style = (availableInstrumentTypeCount === 1)
        ? { cursor: "default" }
        : { }

    return (
        <ButtonGroup aria-label="instrument toggle">
            {supportsSequel &&
                <Button
                    style={style}
                    color="light"
                    className={isActiveClass("Sequel")}
                    onClick={
                        () => (availableInstrumentTypeCount > 1) && dispatch(updateCurrentInstrumentType("Sequel"))
                    }
                >
                    Sequel
                </Button>
            }
            {supportsSequel2 &&
                <Button
                    style={style}
                    color="light"
                    className={isActiveClass("Sequel2")}
                    onClick={
                        () => (availableInstrumentTypeCount > 1) && dispatch(updateCurrentInstrumentType("Sequel2"))
                    }
                >
                    Sequel II
                </Button>
            }
            {supportsSequel2e &&
                <Button
                    style={style}
                    color="light"
                    className={isActiveClass("Sequel2e")}
                    onClick={() => (
                        availableInstrumentTypeCount > 1) && dispatch(updateCurrentInstrumentType("Sequel2e"))
                    }
                >
                    Sequel IIe
                </Button>
            }
            {supportsRevio &&
                <Button
                    style={style}
                    color="light"
                    className={isActiveClass("Revio")}
                    onClick={() => (
                        availableInstrumentTypeCount > 1) && dispatch(updateCurrentInstrumentType("Revio"))
                    }
                >
                    Revio
                </Button>
            }
        </ButtonGroup>
    )
}
