import { ChipType } from "./instrument-type-model"

export interface PartJSON {
    Name: string
    Description: string
    PartNumber: string
    IsObsolete: boolean
}

export interface IncompatiblePair {
    Name: string
    Description: string
    PartA: string
    PartB: string
    Version: string
}

export interface PartNumbersJSON {
    version: string
    typeMap: { [key: string]: string; }
    cellMaxMovieTimes: { [grade: string]: number; }
    parts: PartJSON[]
    incompatibleParts: IncompatiblePair[]
}

export enum PartType {
    Workflow,
    BindingKit,
    TemplatePrepKit,
    SequencingKit,
    ControlKit,
    CellPack,
    OSEnzyme,
    CellMineralOil
}

export interface Part {
    name: string
    partNumber: string
    type: PartType
    isObsolete: boolean
    isRestricted: boolean
    metaType: string
    chipType: ChipType
    version: string
}

export interface BindingKit extends Part {}
export interface ControlKit extends Part {
    customSequence: string
}
export interface SequencingKit extends Part {
    maxCollections: number
    maxRunHours: number
    numOilTubes: number
    numOseTubes: number
    supportsDynamicLoading: boolean
}

export interface TemplatePrepKit extends Part {
    minInsertSize: number
    maxInsertSize: number
    leftAdaptorSequence: string
    rightAdaptorSequence: string
}

export interface CellPack extends Part {
    movieTimeGrade: string
}

export function isBindingKit(object: any): object is BindingKit {
    return object.type === PartType.BindingKit
}

export function isControlKit(object: any): object is ControlKit {
    return object.type === PartType.ControlKit
}

export function isSequencingKit(object: any): object is SequencingKit {
    return object.type === PartType.SequencingKit
}

export function isTemplatePrepKit(object: any): object is TemplatePrepKit {
    return object.type === PartType.TemplatePrepKit
}

export function isCellPack(object: any): object is CellPack {
    return object.type === PartType.CellPack
}

export interface BarcodeInfo {
    lotNumber: string
    partNumber: string
    expDate: string
}

const prefixMap = {
    WFA: PartType.Workflow,
    BDK: PartType.BindingKit,
    TPK: PartType.TemplatePrepKit,
    SQK: PartType.SequencingKit,
    CCK: PartType.ControlKit,
    CPK: PartType.CellPack,
    OSE: PartType.OSEnzyme,
    CMO: PartType.CellMineralOil
}

const barcodePattern = /^[A-Za-z0-9]{6}[0-9]{9}[0-9]{6}$/

export class PartsService {
    private parts: { [partNumber: string]: Part; } = {}
    private bindingKits: { [partNumber: string]: BindingKit; } = {}
    private controlKits: { [partNumber: string]: ControlKit; } = {}
    private sequencingKits: { [partNumber: string]: SequencingKit; } = {}
    private templateKits: { [partNumber: string]: TemplatePrepKit; } = {}
    private cellPacks: { [partNumber: string]: CellPack; } = {}

    private incompatibleParts: IncompatiblePair[] = []
    private cellMaxMovieTimes: { [grade: string]: number; } = {}

    public setPartNumbers(json: PartNumbersJSON) {
        json.parts.forEach(partJSON => {
            const part: Part = {} as any
            Object.keys(partJSON).forEach(key => {
                let partValue = partJSON[key]
                if (key === "PartNumber") {
                    part.type = prefixMap[partValue.slice(0, 3)]
                    partValue = partValue.slice(4)
                }

                part[key.charAt(0).toLowerCase() + key.slice(1)] = partValue
            })
            this.parts[part.partNumber] = part
            const partNumber = part.partNumber
            if (isBindingKit(part)) {
                this.bindingKits[partNumber] = part
            } else if (isSequencingKit(part)) {
                this.sequencingKits[partNumber] = part
            } else if (isControlKit(part)) {
                this.controlKits[partNumber] = part
            } else if (isTemplatePrepKit(part)) {
                this.templateKits[partNumber] = part
                // TODO(pfernhout) Remove workaround for SL-10002 and PartsSelector not supporting identical part names
                const part2: Part = part
                if (part2 && part2.partNumber === "999-999-102" && part2.name === "SMRTbell® Kinnex Prep Kit") {
                    part2.name = part2.name + " "
                }
            } else if (isCellPack(part)) {
                this.cellPacks[partNumber] = part
            }
        })

        this.incompatibleParts = json.incompatibleParts

        this.cellMaxMovieTimes = json.cellMaxMovieTimes
    }

    getParts(type: PartType = null): Part[] {
        const parts: Part[] = []

        for (let i in this.parts) {
            if (this.parts.hasOwnProperty(i)) {
                const part = this.parts[i]
                if (!type || part.type === type) {
                    parts.push(part)
                }
            }
        }

        return parts
    }

    getPart(partNumber: string): Part {
        const part = this.parts[partNumber]
        if (part) {
            return Object.assign({}, part)
        }
        return null
    }

    getBindingKits(): BindingKit[] {
        return this.getParts(PartType.BindingKit)
    }

    getBindingKit(partNumber: string): BindingKit {
        const part = this.getPart(partNumber)
        if (part && isBindingKit(part)) {
            return part
        }
        return null
    }

    getControlKits(): ControlKit[] {
        return this.getParts(PartType.ControlKit) as ControlKit[]
    }

    getControlKit(partNumber: string): ControlKit {
        const part = this.getPart(partNumber)
        if (part && isControlKit(part)) {
            return part
        }
        return null
    }

    getSequencingKit(partNumber: string): SequencingKit {
        const part = this.getPart(partNumber)
        if (part && isSequencingKit(part)) {
            return part
        }
        return null
    }

    getTemplatePrepKit(partNumber: string): TemplatePrepKit {
        const part = this.getPart(partNumber)
        if (part && isTemplatePrepKit(part)) {
            return part
        }
        return null
    }

    getCellPack(partNumber: string): CellPack {
        const part = this.getPart(partNumber)
        if (part && isCellPack(part)) {
            return part
        }
        return null
    }

    fromBarcode(barcode: string): Part {
        const info = this.parseBarcode(barcode)
        const object = info ? this.parts[info.partNumber] : null
        if (object) {
            return Object.assign({}, object)
        }
        return null
    }

    bindingKitFromBarcode(barcode: string): BindingKit {
        const object = this.fromBarcode(barcode)
        if (object && isBindingKit(object)) {
            return object
        }
        return null
    }

    controlKitFromBarcode(barcode: string): ControlKit {
        const object = this.fromBarcode(barcode)
        if (object && isControlKit(object)) {
            return object
        }
        return null
    }

    sequencingKitFromBarcode(barcode: string): SequencingKit {
        const object = this.fromBarcode(barcode)
        if (object && isSequencingKit(object)) {
            return object
        }
        return null
    }

    templatePrepKitFromBarcode(barcode: string): TemplatePrepKit {
        const object = this.fromBarcode(barcode)
        if (object && isTemplatePrepKit(object)) {
            return object
        }
        return null
    }

    partNumberFromName(name: string): string {
        let partNumber = ""
        Object.keys(this.parts).some(key => {
            const part = this.parts[key]
            if (part.name === name) {
                partNumber = key
                return true
            }
            return false
        })
        return partNumber
    }

    parseBarcode(barcode: string): BarcodeInfo {
        if (!barcodePattern.test(barcode)) {
            return null
        }

        const lotNumber = barcode.slice(0, 6)
        const rawPartNumber = barcode.slice(6, 15)
        const rawExpDate = barcode.slice(15)

        const partNumber = `${rawPartNumber.slice(0, 3)}-${rawPartNumber.slice(3, 6)}-${rawPartNumber.slice(6)}`
        const expDate = `20${rawExpDate.slice(4)}-${rawExpDate.slice(0, 2)}-${rawExpDate.slice(2, 4)}`

        return {
            lotNumber,
            partNumber,
            expDate
        }
    }

    isValidDate(date) {
        const testDate = new Date(date)
        if (testDate.toString() !== "Invalid Date") {
            return true
        } else {
            return false
        }
      }

    areCompatible(partA: Part, partB: Part): boolean {
        if (!partA || !partB) {
            return true
        }

        if (!this.areChipTypesCompatible(partA.chipType, partB.chipType)) {
            return false
        }

        return !this.incompatibleParts.some(ip => {
            return  (ip.PartA.endsWith(partA.partNumber) && ip.PartB.endsWith(partB.partNumber)) ||
                    (ip.PartA.endsWith(partB.partNumber) && ip.PartB.endsWith(partA.partNumber))
        })
    }

    areChipTypesCompatible(chipTypeA: string, chipTypeB: string): boolean {
        return !chipTypeA || !chipTypeB || chipTypeA === "AllChips" || chipTypeB === "AllChips" || chipTypeA === chipTypeB ||
        (chipTypeB === "8mAnd25mChips" && ["8mChip", "25mChip"].includes(chipTypeA)) ||
        (chipTypeB === "1mAnd8mChips" && ["1mChip", "8mChip"].includes(chipTypeA)) ||
        (chipTypeA === "8mAnd25mChips" && ["8mChip", "25mChip"].includes(chipTypeB)) ||
        (chipTypeA === "1mAnd8mChips" && ["1mChip", "8mChip"].includes(chipTypeB))
    }

    // Gets all the cell grades available amongst the cells of this chip type.
    // If only one cell grade type is available then it will be named "".
    getAvailableCellGrades(chipType: ChipType) {
        // get all cells
        let cells = this.getParts(PartType.CellPack) as CellPack[]

        // get available cells for this chip type
        cells = cells.filter(c => !c.isRestricted && !c.isObsolete &&
            (chipType === undefined || chipType === null || chipType === c.chipType))

        // get unique cell grades amongst available cells
        const grades = cells.map(c => c.movieTimeGrade ? c.movieTimeGrade: "").filter(function (e, i, arr) {
            return arr.lastIndexOf(e) === i
        })

        // if only one grade type available then force its name to ""
        if (grades.length === 1) {
            grades[0] = ""
        }

        return grades
    }

    // Gets the cell movie time grade (e.g. LR) for the provided movie time (in minute) and
    // chip type (1M or 8M).  If cells for only one type of movie time grade are available,
    // then an empty (i.e. "") movie time grade is returned.
    getCellMovieTimeGrade(movieMinutes: number, chipType: ChipType = null): string {
        // get available cell grades
        const grades = this.getAvailableCellGrades(chipType)

        let bestGrade = ""

        // if more than one type of grade is availalbe then find and return the
        // grade name of the lowest grade that satisfies the time requirement
        // if only one (or less) grade types available then return an empty string for
        // the grade name
        if (grades.length > 1) {
            if (this.cellMaxMovieTimes) {
                let shortestMinutes = Number.MAX_VALUE
                grades.forEach(grade => {
                    const minutes = this.cellMaxMovieTimes[grade]
                    if (minutes >= movieMinutes && minutes < shortestMinutes) {
                        shortestMinutes = minutes
                        bestGrade = grade
                    }
                })
            }
        }

        return bestGrade
    }

    // Maps the provided movie time grade to a grade that is available amongst the cells available
    // for this chip type
    mapToAvailableCellGrade(grade: string, chipType: ChipType): string {
        // get the available cell grades
        const grades = this.getAvailableCellGrades(chipType)

        // if grade in available cell grades then just return it
        if (grade in grades) {
            return grade
        }

        // if there is only one available cell grade then it is that
        if (grades.length === 1) {
            return grades[0]
        }

        // otherwise we need to find the available cell grade that meets the time requirement
        const minutes = grade in this.cellMaxMovieTimes ? this.cellMaxMovieTimes[grade] : 0
        return this.getCellMovieTimeGrade(minutes, chipType)
    }
}


export function getPartNumbersFromAutomationConstraintsXML(xmlData): PartNumbersJSON {
    const namespaces = {
        default: "http://pacificbiosciences.com/PacBioAutomationConstraints.xsd",
        xsi: "http://www.w3.org/2001/XMLSchema-instance",
        pbbase: "http://pacificbiosciences.com/PacBioBaseDataModel.xsd",
        pbpn: "http://pacificbiosciences.com/PacBioPartNumbers.xsd",
        xi: "http://www.w3.org/2001/XInclude"
    }

    function namespaceResolver(prefix) {
        return namespaces[prefix] || null
    }

    const booleanKeys = {
        IsObsolete: true,
        IsRestricted: true,
        SupportsCellReuse: true,
        SupportsStageStart: true
    }
    const integerKeys = {
        MaxInsertSize: true,
        MinInsertSize: true,
        MaxCollections: true,
        MaxCollectionsPerCell: true,
        MinMovieLength: true,
        MaxMovieLength: true
    }

    function nodeToObject(itemNode) {
        const item = {}
        select("@*", itemNode).forEach(function (attribute) {
            const value = attribute.nodeValue
            const key = attribute.localName
            if (booleanKeys[key]) {
                item[key] = value === "true"
            } else if (integerKeys[key]) {
                item[key] = parseInt(value, 10)
            } else {
                item[key] = value
            }
        })
        return item
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(xmlData, "text/xml")

    const select = function (xpathExpression, contextNode, single?): any {
        let xpathResult = doc.evaluate( xpathExpression, contextNode, namespaceResolver, XPathResult.ANY_TYPE, null )
        if (xpathResult.resultType === XPathResult.STRING_TYPE) {
            return xpathResult.stringValue
        } else if (xpathResult.resultType === XPathResult.NUMBER_TYPE) {
            return xpathResult.numberValue
        } else if (xpathResult.resultType === XPathResult.BOOLEAN_TYPE) {
            return xpathResult.booleanValue
        } else {
            const nodes: Node[] = []
            let node = xpathResult.iterateNext()
            while (node) {
                nodes.push(node)
                node = xpathResult.iterateNext()
            }
            if (single) {
                return nodes[0]
            }
            return nodes
        }
    }

    const select1 = function (e, doc) {
        return select(e, doc, true)
    }

    const pbpn = select1("//pbpn:PacBioPartNumbers", doc)

    const typeMap: { [key: string]: string; } = {}

    const cellMaxMovieTimes: { [grade: string]: number; } = {}

    select(
        "pbbase:KeyValueMap//pbbase:Item",
        pbpn
    ).forEach(function (item) {
        const key = select1("pbbase:Key/text()", item).nodeValue
        const value = select1("pbbase:Value/text()", item).nodeValue
        typeMap[key] = value
    })

    select(
        "pbbase:CellMaxMovieTimes//pbbase:Item",
        pbpn
    ).forEach(function (item) {
        const keyNode = select1("pbbase:Key/text()", item)
        const key = keyNode ? keyNode.nodeValue : ""
        const value = parseInt(select1("pbbase:Value/text()", item).nodeValue, 10)
        cellMaxMovieTimes[key] = value
    })

    const parts = select(
        "(" +
            [
                "pbpn:Automations",
                "pbpn:BindingKits",
                "pbpn:TemplatePrepKits",
                "pbpn:SequencingKits",
                "pbpn:ControlKits",
                "pbpn:CellPackKits",
                "pbpn:OtherKits"
            ].join (" | ") +
        ")/*",
        pbpn
    ).map(function (itemNode) {
        const item = nodeToObject(itemNode)

        if (itemNode.localName === "TemplatePrepKit") {
            select("*", itemNode).forEach(function (seqNode) {
                item[seqNode.localName] = select1("text()", seqNode)?.nodeValue || ""
            })
        }

        return item
    })

    const incompatible = select(
        "pbpn:IncompatibleParts/pbpn:IncompatiblePart",
        pbpn
    ).map(nodeToObject)

    const partnumbers: PartNumbersJSON = {
        version: select1(
            "//*[local-name(.)='PacBioAutomationConstraints']/@Version",
            doc
        ).nodeValue,
        typeMap: typeMap,
        parts: parts,
        incompatibleParts: incompatible,
        cellMaxMovieTimes: cellMaxMovieTimes
    }

    return partnumbers
}

export function PartTypeValidator(service: PartsService, type: PartType, chipType: ChipType = null) {
    return function (control: any): any {
        if (!control.value) {
            return null
        }

        const info = service.parseBarcode(control.value)
        if (!info || !service.isValidDate(info.expDate)) {
            return {
                invalidBarcode: {
                    barcode: control.value
                }
            }
        }

        const part = service.getPart(info.partNumber)
        if (!part || part.type !== type || part.isObsolete || !service.areChipTypesCompatible(part.chipType, chipType)) {
            return {
                partType: {
                    barcode: control.value,
                    unknownPartNumber: info.partNumber
                }
            }
        }

        return null
    }
}

export interface PartSelections {
    barcode(type: PartType): string

    setSelectionModel(model: any)
}

export function PartCompatibleValidator(partSelections: PartSelections, service: PartsService, type: PartType) {
    return function (control: any): any {
        if (!control.value) {
            return null
        }

        const info = service.parseBarcode(control.value)
        if (!info) {
            return null
        }

        const part = service.getPart(info.partNumber)
        if (!part) {
            return null
        }

        const types = [
            PartType.TemplatePrepKit,
            PartType.BindingKit,
            PartType.SequencingKit,
            PartType.ControlKit ]

        const compatibleFn = testType => {
            if (testType === type) {
                return true
            }
            const testBarcode = partSelections.barcode(testType)
            if (!testBarcode) {
                return true
            }
            const testPart = service.fromBarcode(testBarcode)
            if (!testPart || (testPart.type !== testType) || service.areCompatible(part, testPart)) {
                return true
            }

            return false
        }

        if (types.every(compatibleFn)) {
            return null
        }

        return {
            incompatiblePart: {
                barcode: control.value,
                incompatiblePartNumber: info.partNumber
            }
        }
    }
}

export abstract class LookupService {
    abstract getLabel(id: string, category: string, chipType: ChipType): string
    abstract getLabels(category: string, context: any, chipType: ChipType, isInternalModeEnabled: boolean): string[]
    abstract getDefaultId(label: string, category: string): string
}

export class PartLookupService extends LookupService {
    public partSelections: PartSelections

    private partsService: PartsService

    constructor(partsService: PartsService) {
        super()
        this.partsService = partsService
    }

    getLabel(barcode: string, category: string, chipType: ChipType): string {
        const info = this.partsService.parseBarcode(barcode)
        if (info) {
            if (!this.partsService.isValidDate(info.expDate)) {
                return "Invalid Date"
            }
            const part = this.partsService.getPart(info.partNumber)
            if (part) {

                if (part.type !== this.getPartType(category)) {
                    return `Part ${part.partNumber} is invalid for this kit type`
                }

                if (!this.partsService.areChipTypesCompatible(part.chipType, chipType)) {
                    return `Part ${part.partNumber} is invalid for this platform`
                }

                if (part.isObsolete) {
                    return `Part ${part.partNumber} is obsolete`
                }

                return part.name
            }
        }
        if (barcode) {
            return "Invalid Barcode"
        }
        return ""
    }

    getLabels(category: string, context: any, chipType: ChipType, isInternalModeEnabled: boolean): string[] {
        if (this.partSelections) {
            this.partSelections.setSelectionModel(context)
        }

        const parts = this.partsService.getParts(this.getPartType(category))
                                        .filter(p => this.validPart(p, category, isInternalModeEnabled))
                                        .filter(p => this.partsService.areChipTypesCompatible(p.chipType, chipType))
        return parts.map(p => p.name)
    }

    getDefaultId(label: string): string {
        let pn = this.partsService.partNumberFromName(label)

        if (!pn) {
            return ""
        }

        while (pn.includes("-")) {
            pn = pn.replace("-", "")
        }

        return "Lxxxxx" + pn + "123199"
    }

    private getPartType(category: string): PartType {
        switch(category) {
            case "dnaControlComplex":
                return PartType.ControlKit
            case "templatePrepKit":
                return PartType.TemplatePrepKit
            case "bindingKit":
                return PartType.BindingKit
            case "sequenceChemistry":
                return PartType.SequencingKit
            case "washKit":
                return PartType.SequencingKit
            default:
                return null
        }
    }

    private validPart(part: Part, category: string, isInternalModeEnabled: boolean): boolean {
        const allowRestricted = isInternalModeEnabled
        if (category === "washKit") {
            return (part && part.type === PartType.SequencingKit && part.metaType === "WashPlate")
        }

        if (part.isObsolete || (!allowRestricted && part.isRestricted) || part.metaType === "WashPlate") {
            return false
        }

        if (!this.partSelections) {
            return true
        }

        const type = part.type
        const pn = part.partNumber

        if (type === PartType.TemplatePrepKit) {
            return true
        }
        if (type === PartType.BindingKit) {
            return this.areKitsCompatible(pn, this.partSelections.barcode(PartType.TemplatePrepKit))
        }
        if (type === PartType.SequencingKit) {
            return this.areKitsCompatible(pn, this.partSelections.barcode(PartType.TemplatePrepKit))
                && this.areKitsCompatible(pn, this.partSelections.barcode(PartType.BindingKit))
        }
        if (type === PartType.ControlKit) {
            return this.areKitsCompatible(pn, this.partSelections.barcode(PartType.TemplatePrepKit))
                && this.areKitsCompatible(pn, this.partSelections.barcode(PartType.BindingKit))
                && this.areKitsCompatible(pn, this.partSelections.barcode(PartType.SequencingKit))
        }

        return true
    }

    private areKitsCompatible(partNumberA: string, barcodeB: string): boolean {
        const partA = partNumberA ? this.partsService.getPart(partNumberA) : null
        const partB = barcodeB ? this.partsService.fromBarcode(barcodeB) : null

        if (!partA || !partB) {
            return true
        }

        return this.partsService.areCompatible(partA, partB)
    }
}
