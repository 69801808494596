import React, { FC } from "react"
import { Button, Badge } from "reactstrap"

interface Props {
    count?: number
    size?: string
    onClick?: () => void
}

export const BadgeButton: FC<Props> = (props: Props) => (
    <Button
        outline
        size={props.size || "sm"}
        className="data-cart-btn"
        color="primary"
        onClick={props.onClick}
    >
        <span className="oi oi-list"></span>
        {props.count > 0 && <Badge pill color="primary">{props.count}</Badge>}
    </Button>
)
