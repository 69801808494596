import { InstrumentTableRow } from "../../services/instrument-table-service"

export const InstrumentTableTypes = {
    API_ATTEMPT: "@@instrument-table/API_ATTEMPT",
    API_SUCCESS: "@@instrument-table/API_SUCCESS",
    API_FAILURE: "@@instrument-table/API_FAILURE",
    SET_LAST_UPDATE: "@@instrument-table/SET_LAST_UPDATE",
    SET_IS_POLLING_BLOCKED: "@@instrument-table/SET_IS_POLLING_BLOCKED",
    GET_INSTRUMENTS: "@@instrument-table/GET_INSTRUMENTS",
  }
  
  export interface InstrumentTableState {
    readonly loading: boolean
    readonly data: InstrumentTableRow[]
    readonly error: Error | null
    readonly lastUpdate: Date
    readonly isPollingBlocked: boolean
  }
  