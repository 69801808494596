export const BARCODES_THAT_EXCLUDE_SAME_STRAND_PAIRS = [
    "HiFiViral SARS-CoV-2 M13barcodes",
    "Sequel 64 M13barcodes v1",
    "HiFiViral SARS-CoV-2 barcoded M13 primer plate"
]

export const BARCODES_WITH_FIXED_PAIRS = [
    "Twist Universal Adapters with UDI"
]

export const APPS_THAT_DONT_SUPPORT_INTERACTIVE_MODE = [
    "hifiViralSARSCoV2"
]
