import { Dataset } from "./dataset-model"

export interface PipelineViewRule {
    id: string
    description: string
    taskOptions?: ViewRuleTaskOption[]
    entryPoints?: ViewRuleEntryPoint[]
    inputFilters?: FilterEncoding[]
    allowParentDatasets?: boolean
    allowChildDatasets?: boolean
}

// Concrete class used to encode defaults
export class ConcretePipelineViewRule implements PipelineViewRule {

    id: string
    description: string
    taskOptions: ViewRuleTaskOption[]
    entryPoints: ViewRuleEntryPoint[]
    inputFilters: FilterEncoding[]
    allowParentDatasets: boolean = true
    allowChildDatasets: boolean = true

    public constructor(pvr: PipelineViewRule) {
        this.id = pvr.id
        this.description = pvr.description
        if (typeof pvr.taskOptions !== "undefined") { this.taskOptions = pvr.taskOptions }
        if (typeof pvr.entryPoints !== "undefined") { this.entryPoints = pvr.entryPoints }
        if (typeof pvr.inputFilters !== "undefined") { this.inputFilters = pvr.inputFilters }
        if (typeof pvr.allowParentDatasets !== "undefined") { this.allowParentDatasets = pvr.allowParentDatasets }
        if (typeof pvr.allowChildDatasets !== "undefined") { this.allowChildDatasets = pvr.allowChildDatasets }
    }
}

export interface ViewRuleTaskOption {
    id: string // one.two.id
    hidden: boolean
    advanced: boolean
    required?: boolean
    min?: number
    max?: number
    templateLink?: string
    style?: string
    choiceLabels?: string[]
}

export interface ViewRuleEntryPoint {
    entryId: string
    uuid: string
    label?: string
    requiredTags?: string
}

export interface FilterEncoding {
    field: string
    operator: string
    value: string
    // entryId defaults to "eid_subread" if null
    entryId: string | null
}

// Return a single boolean function that combines the filters encoded by PipelineViewRule.inputFilters
export function viewRuleFilterFunction(filterEncodings: FilterEncoding[], entryId: string = "eid_subread"): (Dataset) => boolean {

    return (dataset: Dataset) => {
        for (let fe of filterEncodings) {
            if ((fe.entryId || "eid_subread") !== entryId) {
                continue
            }
            let dsetValue = dataset[fe.field]
            let filterValue = fe.value
            let pass: boolean = false
            switch(fe.operator) {
                case "=":
                    pass = dsetValue === filterValue
                    break
                case "!=":
                    pass = dsetValue !== filterValue
                    break
                case "contains":
                    pass = dsetValue.includes(filterValue)
                    break
                default:
                    throw new Error("Invalid operator given for inputFilter: " + fe.operator)
            }
            if (!pass) {
                return false
            }
        }
        return true
    }
}

