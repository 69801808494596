export interface BaseReport {
    _changelist?: string
    _version?: string
    version?: string
    attributes?: ReportAttribute[]
    dataset_uuids?: string[]
    id?: string
    uuid?: string
    name?: string
    title?: string
}

export interface Report extends BaseReport {
    plotGroups?: PlotGroup[]
    tables?: ReportTable[]
}

export interface BasePlotGroup {
    id?: string
    legend?: string
    thumbnail?: string
    title?: string
}

export interface PlotGroup extends BasePlotGroup {
    plots?: Plot[]
}

export interface Plot {
    id?: string
    caption?: string
    image?: string
    title?: string
    plotType?: string
}

export interface ReportAttribute {
    id?: string
    name?: string
    description?: string
    value?: any
}

export interface ReportTable {
    columns?: ReportColumn[]
    id?: string
    title?: string
}

export interface ReportColumn {
    header?: string
    id?: string
    values?: any[]
    description?: string
}

export const MISSING_NAME = "Report could not be found"
export const missingReport = (uuid: string): Report => {
    return {
        uuid: uuid,
        name: MISSING_NAME
    }
}

export const isReportMissing = (report: BaseReport):boolean => {
    return report.name && report.name.indexOf(MISSING_NAME) >= 0
}
