export const xmlToJson = xml => {
    // Create the return object
    let obj = {}

    if (xml.nodeType === 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
            obj["@attributes"] = {}
            for (let j = 0; j < xml.attributes.length; j++) {
                let attribute = xml.attributes.item(j)
                obj["@attributes"][attribute.nodeName] = attribute.nodeValue
            }
        }
    } else if (xml.nodeType === 3) {
        // text
        obj = xml.nodeValue
    }

    // do children
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            let item = xml.childNodes.item(i)
            let nodeName = item.nodeName
            if (typeof obj[nodeName] === "undefined") {
                obj[nodeName] = xmlToJson(item)
            } else {
                if (typeof obj[nodeName].push === "undefined") {
                    let old = obj[nodeName]
                    obj[nodeName] = []
                    obj[nodeName].push(old)
                }
                obj[nodeName].push(xmlToJson(item))
            }
        }
    }
    return obj
}

const escapeXMLMap = new Map<string, string>([
    ["&", "&amp;"],
    ["\"", "&quot;"],
    ["'", "&apos;"],
    ["<", "&lt;"],
    [">", "&gt;"]
])

const unescapeXMLMap = new Map<string, string>([
    ["&amp;", "&"],
    ["&quot;", "\""],
    ["&apos;", "'"],
    ["&lt;", "<"],
    ["&gt;", ">"]
])

const escapeXMLRE = /[&"'<>]/g
const unescapeXMLRE = /&(?:amp|quot|apos|lt|gt);/g

interface XNode extends Node {
    xmlElement?: XMLElement
}

interface XDocument extends Document {
    xmlDocument?: XMLDocument
}

export interface NamespaceMap {
    [key: string]: string
    default: string
}

export class XMLElement {
    get type(): number {
        return this.node.nodeType
    }

    get value(): string {
        return this.node.nodeValue!
    }

    set value(value: string) {
        this.node.nodeValue = value
    }

    get firstElementChild(): XMLElement {
        const node = this.node
        if (isElement(node)) {
            return new XMLElement(node.firstElementChild!, this.doc, this.resolver)
        }
        return null!
    }

    get nodeName(): string {
        return this.node.nodeName
    }

    get localName(): string {
        return (this.node as any).localName
    }

    protected doc: Document
    protected node: Node
    protected resolver: ((namespace: string) => string) | undefined

    constructor(node?: Node, doc?: Document, resolver?: (namespace: string) => string) {
        const xnode: XNode = node!
        if (xnode) {
            // If this node has been assigned an XMLElement, return it so
            // strict equality between results of XMLElement methods will
            // still work
            if (xnode.xmlElement) {
                return xnode.xmlElement
            } else {
                xnode.xmlElement = this
            }
        }

        this.node = node!
        this.doc = doc!
        this.resolver = resolver!
    }

    appendChild(element: XMLElement) {
        this.node.appendChild(element.node)
    }

    orphan() {
        this.node.parentNode!.removeChild(this.node)
    }

    empty() {
        let child
        while ((child = this.node.childNodes[0])) {
            this.node.removeChild(child)
        }
    }

    getString(expression: string, context: Node = this.node): string {
        return unescapeXML(this.evaluate(expression, context, XPathResult.STRING_TYPE).stringValue)
    }

    setString(expression: string, value: string, context: Node = this.node) {
        const element = this.getElement(expression, context)
        if (element.type === 2) {
            throw new Error("Use 'setAttribute()' to set the value of an attribute")
        }
        if (element.type === 3) {
            // text nodes
            element.value = escapeXML(value)
        } else {
            element.empty()
            element.node.appendChild(this.doc.createTextNode(value))
        }
    }

    getNumber(expression: string, context: Node = this.node): number {
        return this.evaluate(expression, context, XPathResult.NUMBER_TYPE).numberValue
    }

    setNumber(expression: string, value: number) {
        this.setString(expression, value.toString())
    }

    getElement(expression: string, context: Node = this.node): XMLElement {
        const node = this.evaluate(expression, context, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue
        if (!node) {
            return null!
        }
        return new XMLElement(node, this.doc, this.resolver)
    }

    getElements(expression: string, context: Node = this.node): XMLElement[] {
        const result: XMLElement[] = []

        const xpathResult = this.evaluate(expression, context, XPathResult.ORDERED_NODE_ITERATOR_TYPE)

        if (xpathResult) {
            let node: Node
            while ((node = xpathResult.iterateNext()!)) {
                result.push(new XMLElement(node, this.doc, this.resolver))
            }
        }

        return result
    }

    getAttribute(name: string): string {
        const node = this.node
        if (isElement(node)) {
            return unescapeXML(node.getAttribute(name) || "")
        }
        return ""
    }

    hasAttribute(name: string): boolean {
        const node = this.node
        if (isElement(node)) {
            return node.hasAttribute(name)
        }
        return false
    }

    setAttribute(name: string, value: any) {
        const node = this.node
        if (isElement(node)) {
            node.setAttribute(name, escapeXML(String(value)))
        }
    }

    removeAttribute(name: string) {
        const node = this.node
        if (isElement(node)) {
            node.removeAttribute(name)
        }
    }

    private evaluate(expression: string, context: Node = this.node, type: number): XPathResult {
        return this.doc.evaluate(expression, context, this.resolver, type, null)
    }
}

function namespaceMapToAttributes(namespaces: NamespaceMap): string {
    const strings: string[] = []

    Object.keys(namespaces).forEach(key => {
        const ns = key === "default" ? "" : `:${key}`
        strings.push(`xmlns${ns}="${namespaces[key]}"`)
    })

    return strings.join(" ")
}

export class XMLDocument extends XMLElement {
    protected namespaces: NamespaceMap | undefined

    constructor(doc: Document, namespaces: NamespaceMap) {
        super()

        const xdoc: XDocument = doc
        if (xdoc.xmlDocument) {
            return xdoc.xmlDocument
        }

        xdoc.xmlDocument = this

        this.doc = doc
        this.namespaces = namespaces
        this.resolver = (prefix: string): string => {
            return this.namespaces![prefix] || this.namespaces!.default
        }
        this.node = doc.documentElement
    }

    static fromString(content: string, namespaces: NamespaceMap): XMLDocument {
        if (!/^<\?xml /.test(content)) {
            const namespacesString = namespaceMapToAttributes(namespaces)
            content = `<?xml version="1.0" encoding="utf-8"?><root ${namespacesString}>${content}</root>`
        }

        const parser = new DOMParser()
        const doc = parser.parseFromString(content, "text/xml")
        const xdoc = new XMLDocument(doc, namespaces)

        return xdoc
    }

    createElement(tag: string): XMLElement {
        let [namespace, tagName] = tag.split(":")
        if (!tagName) {
            tagName = namespace
            namespace = null
        }
        let domElement
        if (namespace) {
            domElement = this.doc.createElementNS(this.resolver!(namespace), tagName)
        } else {
            domElement = this.doc.createElement(tagName)
        }
        return new XMLElement(domElement, this.doc, this.resolver)
    }

    createElementTree(xml: string): XMLElement {
        const doc = XMLDocument.fromString(xml, this.namespaces!)
        return this.importElement(doc.firstElementChild)
    }

    importElement(element: XMLElement): XMLElement {
        const node = this.doc.importNode((element as any).node, true)
        return new XMLElement(node, this.doc, this.resolver)
    }

    toString(): string {
        const serializer = new XMLSerializer()
        return serializer.serializeToString(this.doc)
    }
}

export interface AttributeMap {
    [key: string]: string
}

export function escapeXML(input: string): string {
    return replaceMap(input, escapeXMLRE, escapeXMLMap)
}

export function unescapeXML(input: string): string {
    return replaceMap(input, unescapeXMLRE, unescapeXMLMap)
}

export const xml = (function() {
    const escapeRE = /^:escape/
    function xml(strings: string[], ...values: any[]) {
        const result = [strings[0]]
        values.forEach((value, index) => {
            value = String(value)
            let nextString = strings[index + 1]
            if (escapeRE.test(nextString)) {
                value = escapeXML(value)
                nextString = nextString.replace(escapeRE, "")
            }
            result.push(value, nextString)
        })
        return result.join("")
    }
    return xml
})()

function replaceMap(input: string, regex: RegExp, map: Map<string, string>) {
    return input.replace(regex, match => map.get(match))
}

function isElement(node: Node): node is Element {
    return node.nodeType === 1
}
