import { Action, ActionCreator, Dispatch } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { ApiErrorResponse, ApiResponse } from "apisauce"
import { push } from "react-router-redux"

import {
    AuthActionTypes,
    LoginPayload,
    TokenResponse,
    UserResponse
} from "./login-types"
import * as API from "../../../data/api"
import { ApplicationState, ThunkResult } from "../../../core/types"
import { refreshService } from "../../../data/api"
import {
    getChemistryBundleCurrentVersion,
    checkForChemistryBundleUpgrade,
    getUiBundleCurrentVersion,
    checkForUiBundleUpgrade
} from "../../../data/stores/about/about-api-actions"
import { setInstrumentSupport } from "../../../data/stores/instrument/instrument-actions"
import { User, userFromUserResponse } from "../../../data/model/user-model"

export const login = (payload: LoginPayload): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
        try {
            dispatch(apiAttempt())

            const newPayload: any = {
                ...payload,
                grant_type: "password",
                scope:
                    "welcome sample-setup runs instruments data-management analysis openid admin userinfo smrtlink-admin"
            }
            const response: ApiResponse<TokenResponse> = await API.getToken(newPayload)
            const { data: tokenData } = response

            if (response.ok) {
                API.setVersion(response.headers[API.API_VERSION_H])

                // Set Token
                if (tokenData) {
                    dispatch(setToken(tokenData))
                    API.setTokenHeader(tokenData.access_token)
                    refreshService.setHasCachedRefreshToken(!!tokenData.refresh_token)
                }

                const statusResponse = await API.slStatus()
                const slStatus = statusResponse.data
                const isLite = slStatus.apiFlags && slStatus.apiFlags.isSmrtLinkLite

                // Set login status
                localStorage.setItem("authStatus", "LOG_IN")

                const persistInstrument = JSON.parse(localStorage.getItem("persist:instrument")) || {
                    currentInstrumentType: null,
                    instrumentSupport: null
                }

                const instrumentSupport = JSON.parse(persistInstrument.instrumentSupport) || {}
                const currentInstrumentType = JSON.parse(persistInstrument.currentInstrumentType)

                dispatch(setInstrumentSupport(
                    {
                        supportsSequel: false,
                        supportsSequel2: isLite ? false : instrumentSupport.supportsSequel2,
                        supportsSequel2e: instrumentSupport.supportsSequel2e,
                        supportsRevio: instrumentSupport.supportsRevio
                    }, currentInstrumentType)
                )

                // Get user info
                const userResponse: ApiResponse<UserResponse> = await API.aUser()
                const { data } = userResponse
                const user: User = userFromUserResponse(userResponse.data)
                if (userResponse.ok) {
                    dispatch(setUser(user))
                    dispatch(apiSuccess())

                    // check bundle upgrade
                    if (data.roles.includes("Internal/PbAdmin")) {
                        dispatch(getChemistryBundleCurrentVersion())
                        dispatch(checkForChemistryBundleUpgrade())
                        dispatch(getUiBundleCurrentVersion())
                        dispatch(checkForUiBundleUpgrade())
                    }

                    if (payload.disableRedirect) {
                        API.refreshService.retryDeferredAPICalls()
                        return
                    }
                    dispatch(push("/"))
                } else {
                    dispatch(apiFailure((data && data.error_description) || "Login failure."))
                }
            } else {
                let errorMessage = "Login failure."
                if (tokenData) {
                    if (tokenData instanceof Error) {
                        errorMessage = tokenData.message || errorMessage
                    } else if (response.status === 408) {
                        errorMessage = tokenData.message || errorMessage
                    } else {
                        errorMessage = tokenData.error_description || errorMessage
                    }
                } else {
                    if (response.problem === "NETWORK_ERROR") {
                        errorMessage = "Login failed due to a network error."
                        if (response.config && response.config.baseURL) {
                            errorMessage += " Please check your access to: " + response.config.baseURL
                        }
                    }
                }
                dispatch(apiFailure(errorMessage))
            }
        } catch (err) {
            let errorMessage = "Login failed."

            if (! (API.isApiErrorResponse(err))) {
                throw(err)
            }

            let responseErr = err as ApiErrorResponse<any>
            if (responseErr.status === 511 && responseErr.config && responseErr.config.baseURL) {
                errorMessage += " Please check your access to: " + responseErr.config.baseURL
            }
            dispatch(apiFailure(errorMessage))
        }
    }

export const refreshToken = (callback: any): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
    getState: () => ApplicationState
) => {
    try {
        const { refreshToken: refresh_token } = getState().auth

        if (refresh_token) {
            const payload: any = {
                refresh_token
            }
            const response: ApiResponse<TokenResponse> = await API.getToken(payload)
            const { data: tokenData } = response

            if (response.ok) {
                // Fetching tokens
                dispatch(setToken(tokenData))
                // Set Token
                if (tokenData) {
                    API.setTokenHeader(tokenData.access_token)
                    refreshService.setHasCachedRefreshToken(!!tokenData.refresh_token)
                }

                // Set login status
                localStorage.setItem("authStatus", "LOG_IN")

                callback()
            } else {
                // refresh failed; also clearing refresh token
                dispatch(setToken({access_token: null, refresh_token: null }))
                const err = new Error("Refresh failed")
                callback(err)
                throw err
            }
        } else {
            // no refresh token
            callback("did not try")
        }
    } catch (err) {
        dispatch(apiFailure("Login session expired"))
        const errAsAny = err as any
        errAsAny.name = "RefreshTokenError"
        callback(errAsAny)
    }

}

export const logout = (): ThunkResult<Promise<void>> => async (
    dispatch: Dispatch,
    getState: () => ApplicationState
) => {
    const { token } = getState().auth

    if (token) {
        dispatch(setToken({ access_token: null, refresh_token: null }))
        dispatch(setUser(null))
        dispatch(push("/login"))

        // For dispatching an event to logout all windows
        localStorage.setItem("authStatus", "LOG_OUT")

        sessionStorage.clear()
    }
}

export const setToken: ActionCreator<Action> = (payload: TokenResponse) => ({
    type: AuthActionTypes.SET_TOKEN,
    payload
})

export const setUser: ActionCreator<Action> = (payload: UserResponse) => ({
    type: AuthActionTypes.SET_USER,
    payload
})

export const setPendingAction: ActionCreator<Action> = (payload: any) => ({
    type: AuthActionTypes.SET_PENDING_ACTION,
    payload
})

export const apiAttempt: ActionCreator<Action> = () => ({
    type: AuthActionTypes.API_ATTEMPT
})

export const apiSuccess: ActionCreator<Action> = () => ({
    type: AuthActionTypes.API_SUCCESS
})

export const apiFailure: ActionCreator<Action> = (payload) => ({
    type: AuthActionTypes.API_FAILURE,
    payload
})

