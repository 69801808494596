/* eslint-disable no-console */

import { Action } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { ApplicationState } from "../types"
import { reportError } from "../../core-components/shared/Error/ErrorBoundary"

let debounceTimer
function debounce(callback, time) {
    window.clearTimeout(debounceTimer)
    debounceTimer = window.setTimeout(callback, time)
}

export function errorHandler(dispatch: ThunkDispatch<ApplicationState, undefined, Action>, error: any) {
    // error is "any" because this needs to handle catches where the error is not an Error

    console.log("errorHandler", error)

    if (error && error.status === 511) {
        const extraText =
            error.config && error.config.baseURL
                ? " to:\n" + error.config.baseURL
                : ""
        debounce(() => alert("Please check your network access " + extraText), 1000)
    }

    switch (error && error.name) {
        case "RefreshTokenError":
            console.log("reportError: ignoring RefreshTokenError")
            break

        case "Unauthorized":
            if (!window.pb_error_state) {
                // This error handling code is checking for /sl/ at the start of a path.
                // A path like that is currently only present on a deployed server
                // but is missing when testing locally on localhost.
                // We would need to update this code if we ever change how SL paths work in deployment.
                window.location.href = window.location.origin
                    + (window.location.pathname.startsWith("/sl/")
                        ? "/sl"
                        : "")
                    + "/bad-perm"
            } else {
                console.error("error: bad-perm")
            }
            break

        default:
            console.error("errorHandler", error)

            if (!(error instanceof Error)) {
                const newError = new Error(
                    (error && error["data"] && error["data"]["message"]) ||
                    (error && error["name"]) ||
                    "Something went wrong")
                newError["data"] = error
                reportError(newError)
            } else {
                reportError(error)
            }
            break
    }
}
