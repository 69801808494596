/* Helper functions implementing Excel math operators.

--- Excel examples: ---
x               25.21
round(x,0)      25
round(x,1)      25.2
round(x,-1)     30

roundup(x,0)    25      (rounddown works in similar fashion)
roundup(x,1)    25.3
roundup(x,-1)   30

ceiling(x,0)       0    (floor works in similar fashion)
ceiling(x,1)       26
ceiling(x,10)      30
ceiling(x,0.1)     25.3
----------------------
*/


export class TableUtils {
    static round(value: number, num_digits: number): number {
        const powerOfTen = Math.pow(10, num_digits)
        return Math.round(value * powerOfTen) / powerOfTen
    }

    static floor(value: number, significanceArg: number): number {
        // Excel FLOOR
        // Reference: https://github.com/sutoiku/formula.js/blob/master/lib/math-trig.js
        if (significanceArg === 0) {
            return 0
        }
        const significance = Math.abs(significanceArg)
        const precision = -Math.floor(Math.log(significance) / Math.log(10))
        if (value >= 0) {
            return TableUtils.round(Math.floor(value / significance) * significance, precision)
        } else {
            return -TableUtils.round(Math.ceil(Math.abs(value) / significance) * significance, precision)
        }
    }

    static ceiling(value: number, significanceArg: number): number {
        // excel CEILING
        // Reference: https://github.com/sutoiku/formula.js/blob/master/lib/math-trig.js
        const significance = Math.abs(significanceArg)
        if (significanceArg === 0) {
            return 0
        }
        const precision = -Math.floor(Math.log(significance) / Math.log(10))
        if (value >= 0) {
            return TableUtils.round(Math.ceil(value / significance) * significance, precision)
        } else {
            return -TableUtils.round(Math.ceil(Math.abs(value) / significance) * significance, precision)
        }
    }

    static roundUp(value: number, num_digits: number): number {
        // Excel ROUNDUP
        if (! Number.isInteger(num_digits)) {
            throw new Error("Error in roundUp.  num_digits must be an integer! num_digits = " + num_digits)
        }
        const powerOfTen = Math.pow(10, num_digits)
        return Math.ceil(value * powerOfTen) / powerOfTen
    }

    static roundDown(value: number, num_digits: number): number {
        // Excel ROUNDDOWN
        if (! Number.isInteger(num_digits)) {
            throw new Error("Error in roundDown.  num_digits must be an integer! num_digits = " + num_digits)
        }
        const powerOfTen = Math.pow(10, num_digits)
        return Math.floor(value * powerOfTen) / powerOfTen
    }

    static cloneAndRound<T>(obj: T, precision: number): T {
        let rounded: any = {}
        for (let key in obj) {
            if (typeof obj[key] === "number") {
                rounded[key] = TableUtils.round(obj[key] as any, precision)
            } else {
                rounded[key] = obj[key]
            }
        }
        return rounded
    }
}
