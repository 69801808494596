import { Reducer } from "redux"
import { ProjectApiState, ProjectActionTypes } from "./project-api-types"

const initialState: ProjectApiState = {
    loading: false,
    data: [],
    error: null,
    project: null
}

export const projectReducer: Reducer<ProjectApiState> = (state: ProjectApiState = initialState, action: any) => {
    switch (action.type) {
        case ProjectActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case ProjectActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case ProjectActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case ProjectActionTypes.GET_PROJECT_SUCCESS: {
            const data = [...state.data]
            const oldProject = data.find(project => project.id === action.payload.id)

            if (oldProject) {
                Object.assign(oldProject, action.payload)
            } else {
                data.push(action.payload)
            }

            return Object.assign({}, state, {
                loading: false,
                data
            })
        }
        case ProjectActionTypes.GET_PROJECTS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload
            })

        case ProjectActionTypes.SET_PROJECT:
            return Object.assign({}, state, {
                project: action.payload
            })

        default:
            return state
    }
}
