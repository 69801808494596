import { Reducer } from "redux"
import { AlarmActionTypes, AlarmState } from "./alarm-api-types"

const initialState: AlarmState = {
    loading: false,
    alarms: [],
    clearedAlarmIds: [],
    error: null,
}

export const alarmReducer: Reducer<AlarmState> = (
    state: AlarmState = initialState,
    action: any
) => {
    switch (action.type) {
        case AlarmActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case AlarmActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case AlarmActionTypes.SET_ALARMS:
            return Object.assign({}, state, {
                loading: false,
                error: null,
                alarms: action.payload
            })

        case AlarmActionTypes.SET_CLEARED_ALARM_IDS:
            return Object.assign({}, state, {
                clearedAlarmIds: action.payload
            })
        default:
            return state
    }
}
