import { Reducer } from "redux"
import { DatasetSelectorState, DatasetSelectorActionTypes } from "./dataset-selector-api-types"
import { SelectionMap } from "../../../core-components/shared/DrillDownTable/drill-down-types"

const initialState: DatasetSelectorState = {
    selectorType: "datasets",
    selectionMap: SelectionMap.fromField("uuid"),
    isFlatMode: false
}

export const datasetSelectorReducer: Reducer<DatasetSelectorState> = (state: DatasetSelectorState = initialState, action: any) => {
    switch (action.type) {

        case DatasetSelectorActionTypes.SET_SELECTOR_TYPE:
            return Object.assign({}, state, {
                selectorType: action.payload
            })

        case DatasetSelectorActionTypes.SET_SELECTION_MAP:
            return Object.assign({}, state, {
                selectionMap: action.payload
            })

        case DatasetSelectorActionTypes.SET_IS_FLAT_MODE:
            return Object.assign({}, state, {
                isFlatMode: action.payload
            })

        default:
            return state
    }
}
