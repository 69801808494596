import React, { FC } from "react"
import { Button, CustomInput, Modal } from "reactstrap"
import { capitalize } from "lodash"

interface Props {
    isOpen: boolean
    toggleModal: () => void
    onChange: (selected: string[]) => void
    selected: string[]
    parentRef: React.MutableRefObject<Button>
    availableOptions: string[]
    id: string
}

export const ColumnCheckboxFilterModal: FC<Props> = (props: Props) => {

    const onSelect = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        const value = evt.target.value

        // Always remove the value if it is in array, and then add it back if needed
        const selected = props.selected.filter(label => label !== value)
        if (evt.target.checked) {
            selected.push(value)
        }
        props.onChange(selected)
    }

    const areAllSelected = () => {
        return props.selected.length === props.availableOptions.length
    }

    const onToggleAll = () => {
        const selected = areAllSelected() ? [] : props.availableOptions
        props.onChange(selected)
    }

    const bounds = (props.parentRef.current && (props.parentRef.current as any).getBoundingClientRect()) || {top: 0, left: 0}

    return <Modal
            isOpen={props.isOpen}
            toggle={props.toggleModal}
            id={props.id}
            aria-labelledby={props.id}
            style={{top: bounds.top, left: 0}}
            >
        <div
            className={props.id + "-wrapper align-items-center mb-2 outline-FPO"}
            role="region"
            aria-label="checkboxes to filter selections"
            style={{margin: "1rem"}}
        >
        <CustomInput
            type="checkbox"
            id={props.id + "_SelectAll"}
            key={props.id + "SelectAll"}
            label="Select All"
            checked={areAllSelected()}
            onChange={ () => onToggleAll()}
        />
        <hr></hr>
        {props.availableOptions.map(label => (
            <CustomInput
                type="checkbox"
                id={props.id + label}
                key={props.id + label}
                label={capitalize(label)}
                value={label}
                checked={props.selected.includes(label)}
                onChange={ (evt) => onSelect(evt)}
            />
        ))}
        </div>
    </Modal>
}
