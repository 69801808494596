import { BarcodeSampleMap } from "../../../silos/runs/utils/barcodes"

export const BarcodeSampleNamesActionTypes = {
  SET_ALL_BARCODE_SAMPLES: "@@barcodeSampleMap/SET_ALL_BARCODE_SAMPLES",
  SET_USER_BARCODE_SAMPLES: "@@barcodeSampleMap/SET_USER_BARCODE_SAMPLES",
  SET_USER_AND_ALL_BARCODE_SAMPLES: "@@barcodeSampleMap/SET_USER_AND_ALL_BARCODE_SAMPLES"
}

export interface BarcodeSampleNamesState {
  readonly allBarcodeSamples: BarcodeSampleMap 
  readonly userBarcodeSamples: BarcodeSampleMap
}
