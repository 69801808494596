import { Reducer } from "redux"
import { NotificationsActionTypes, NotificationsState } from "./notifications-types"

const initialState: NotificationsState = {
    loading: false,
    notifications: [],
    error: null
}

export const notificationsReducer: Reducer<NotificationsState> = (
    state: NotificationsState = initialState,
    action: any
) => {
    switch (action.type) {
        case NotificationsActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case NotificationsActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case NotificationsActionTypes.SET_NOTIFICATIONS:
            return Object.assign({}, state, {
                loading: false,
                notifications: action.payload,
                error: null
            })
        default:
            return state
    }
}
