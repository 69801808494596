import * as API from "../api"
import { KeycloakUserRole, KeycloakUser } from "../model/user-model"

const maxUsersPerRequest = 100

const slRoles: KeycloakUserRole[] = [
    {
        "id": "03dd2f62-2f47-4600-8f2f-c500b72287f7",
        "name": "PbAdmin"
    },
    {
        "id": "4c268ccb-c38e-41ad-9d81-ed974a50f6cf",
        "name": "PbLabTech"
    },
    {
        "id": "ec35828d-9054-4eb8-93b5-ac6e73a2f655",
        "name": "PbBioinformatician"
    },
    {
        "id": "d36bd1d7-0951-4a10-880b-4087d68fe55d",
        "name": "PbInstrument"
    }
]
const slRolesByName = new Map<string, KeycloakUserRole>(slRoles.map(role =>
  [role.name, role]))

export const getEnabledUsers = async (): Promise<KeycloakUser[]> => {
    let result = []
    let more = true
    while (more) {
        const response = await API.getEnabledUsers(maxUsersPerRequest, result.length)
        if (response.ok) {
            const someUsers = response.data
            result = result.concat(someUsers)
            if (someUsers.length !== maxUsersPerRequest) {
                more = false
            }
        } else {
            throw new Error("Call getEnabledUsers() failed")
        }
    }
    return result
}

export const getAllUsers = async (filter?: string): Promise<KeycloakUser[]> => {
    let result = []
    let more = true
    while (more) {
        const response = await API.getAllUsers(filter, maxUsersPerRequest, result.length)
        if (response.ok) {
            const someUsers = response.data
            result = result.concat(someUsers)
            if (someUsers.length !== maxUsersPerRequest) {
                more = false
            }
        } else {
            throw new Error("Call getAllUsers() failed")
        }
    }
    return result
}

export const getUser = (userId: string): Promise<KeycloakUser> => {
    return API.getUser(userId).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error(`Call getUsers(${userId}) failed`)
        }
    })
}

export const getRolesForUser = (userId: string): Promise<KeycloakUserRole[]> => {
    return API.getRolesForUser(userId).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error(`Call getRolesForUser(${userId}) failed`)
        }
    })
}

export const getUserListByName = (username: string): Promise<KeycloakUser[]> =>
 {
    return API.getUserListByName(username).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error(`Call getUserByName(${username}) failed`)
        }
    })
}

export const getUserListOfRole = async (role: string): Promise<KeycloakUser[]> => {
    let result = []
    let more = true
    while (more) {
        const response = await API.getUserListOfRole(role, maxUsersPerRequest, result.length)
        if (response.ok) {
            const someUsers = response.data
            result = result.concat(someUsers)
            if (someUsers.length !== maxUsersPerRequest) {
                more = false
            }
        } else {
            throw new Error(`Call getUserListOfRole(${role}) failed`)
        }
    }
    return result
}

const toKeycloakRole = (role: string): KeycloakUserRole => {
    const roleName = role.split("/").pop()
    return slRolesByName.get(roleName)
}

export const deleteUserRoles = (userId: string, roles: string[]): Promise<void> => {
    const keycloakRoles = roles.map(r => toKeycloakRole(r))
    return API.deleteUserRoles(userId, keycloakRoles).then(response => {
        if (response.ok) {
            return undefined
        } else {
            throw new Error(`Call deleteUserRoles(${userId}, ${roles}) failed`)
        }
    })
}

export const addUserRoles = (userId: string, roles: string[]): Promise<void> => {
    const keycloakRoles = roles.map(r => toKeycloakRole(r))
    return API.addUserRoles(userId, keycloakRoles).then(response => {
        if (response.ok) {
            return undefined
        } else {
            throw new Error(`Call addUserRoles(${userId}, ${roles}) failed`)
        }
    })
}
