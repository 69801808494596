import * as API from "../api"
import { Project } from "../model/project-model"

export const GENERAL_PROJECT_ID = 1
export const UNSELECTED_PROJECT_ID = -1

export const isCustomProject = (project: Project): boolean => project && project.id > GENERAL_PROJECT_ID
export const getProjectId = (project: Project) => project ? project.id :  UNSELECTED_PROJECT_ID
export const isSameProject = (p1: Project, p2: Project) => {
    if (!p1 && !p2) return true
    if (!p1 || !p2) return false
    return p1.id === p2.id
}

export const getProjects = (): Promise<Project[]> => {
        return API.getProjects().then(response => {
            if (response.ok) {
                return response.data
            } else {
                throw new Error("Error Get Projects!")
            }
        })
    }

export const getProject = (id: number): Promise<Project> =>
    API.getProject(id).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error("Error Get Project!")
        }
    })


export const addProject = (project: Project): Promise<Project> =>
    API.addProject(project).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error("Error Add Project!")
        }
    })

export const updateProject = (project: Project): Promise<Project> =>
    API.updateProject(project).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error("Error Update Project!")
        }
    })


export const saveProject = (project: Project): Promise<Project> => {
    if ("id" in project && project.id !== null) {
        return updateProject(project)
    } else {
        return addProject(project)
    }
}

export const deleteProject = (id: number): Promise<Project> => {
    return API.deleteProject(id).then(response => {
        if (response.ok) {
            return response.data
        } else {
            throw new Error("Error Delete Project!")
        }
    })
}
