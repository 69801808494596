import { Reducer } from "redux"
import { SampleSetupActionTypes, SampleApiState } from "./sample-setup-api-types"

const initialState: SampleApiState = {
    loading: false,
    data: [],
    error: null
}

export const sampleReducer: Reducer<SampleApiState> = (state: SampleApiState = initialState, action: any) => {
    switch (action.type) {
        case SampleSetupActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case SampleSetupActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case SampleSetupActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case SampleSetupActionTypes.GET_SAMPLE_SUCCESS: {
            const data = [...state.data]
            const oldSample = data.find(sample => sample.uniqueId === action.payload.uniqueId)

            if (oldSample) {
                Object.assign(oldSample, action.payload)
            } else {
                data.push(action.payload)
            }

            return Object.assign({}, state, {
                loading: false,
                data
            })
        }
        case SampleSetupActionTypes.GET_SAMPLE_LIST_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload
            })

        default:
            return state
    }
}
