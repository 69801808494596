import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap"

const BODY_THEME_CLASS = "btheme-base"

class HelpPage extends Component {

  componentDidMount() {
    document.body.classList.add(BODY_THEME_CLASS)
  }

  componentWillUnmount() {
    document.body.classList.remove(BODY_THEME_CLASS)
  }

  render() {
    return (
      <Container className="container-main bg-white" fluid>
        <div className="inner-container">
            <Row>
                <Col md={10}>
                    <div className="d-flex align-items-center mt-3">
                        <h1 className="h-subhead mb-0">SMRT Link Help</h1>
                        <Nav className="nav-submenu ml-auto mr-2">
                            <NavItem>
                                <NavLink href="" title="Contents" className="active">
                                    <span className="oi oi-folder"></span>
                                    <span className="ml-1">Contents</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="" title="Index">
                                    <span className="oi oi-book"></span>
                                    <span className="ml-1">Index</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Button size="sm" color="outline-dark">
                            <span className="oi oi-print"></span>
                            <span className="btn-text">Print</span>
                        </Button>
                        <Form inline className="ml-3">
                            <Label for="searchInput" className="sr-only">
                                Help Documentation Search
                            </Label>
                            <InputGroup size="sm">
                                <Input type="search" name="search" id="searchInput" />
                                <InputGroupAddon addonType="prepend">
                                    <Button type="submit" color="primary">
                                        <span className="sr-only"></span>
                                        <span className="oi oi-magnifying-glass"></span>
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={3} sm={5}>
                    <p className="text-FPO outline-FPO">vertical sidebar nav with expanding sub menus goes here.
                    Refer to US Web Standards documentation example.</p>
                </Col>
                <Col md={7} sm={7}>
                    <section className="section-docs">
                        <h1>Section Header</h1>
                        <p className="lead">The page heading communicates the main focus of the page.
                        Make your page heading descriptive and keep it succinct.</p>
                        <h2>Section Subhead</h2>
                        <p className="text-FPO outline-FPO">Place and format Help Doc content as needed.</p>
                    </section>
                </Col>
            </Row>
        </div>
      </Container>
    )
  }
}

export { HelpPage }
