import { User } from "../../model/user-model"

export const UsersActionTypes = {
  GET_USER_LIST_ATTEMPT: "@@users/GET_USER_LIST_ATTEMPT",
  GET_USER_LIST_SUCCESS: "@@users/GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "@@users/GET_USER_LIST_FAILURE",

  SAVE_USER_ATTEMPT: "@@users/SAVE_USER_ATTEMPT",
  SAVE_USER_SUCCESS: "@@users/SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE: "@@users/SAVE_USER_FAILURE",

  SET_SELECTED_USER: "@@users/SET_SELECTED_USER"
}

export interface UsersApiState {
  readonly loadingUsers: boolean
  readonly users: User[]
  readonly loadingUsersError: Error | null
  readonly savingUser: boolean
  readonly selectedUser: User | null
  readonly savingUserError: Error | null
}

