import React, { FunctionComponent } from "react"
import { ICellRendererParams, RowClickedEvent } from "ag-grid-community"
import { Grid } from "../../shared/Grid"
import { ExpandedNotificationRenderer } from "./ExpandedNotificationRenderer"
import { NotificationRow, notificationsTableCols } from "./notifications-table-helper"
import { CheckboxSetFilter } from "../../shared/CheckboxSetFilter"
import { SortModel } from "../../../data/services/grid-storage-service"


interface Props {
    rows: NotificationRow[]
    loading: boolean
    selected:  NotificationRow[ ]
    onSelectionChange: (selected: NotificationRow[]) => void
    markUnread: (notification: NotificationRow, unread: boolean) => void
}

export const NotificationsTable: FunctionComponent<Props> = props => {

    const getRowStyle = (params: ICellRendererParams) => {
        const notification: NotificationRow = params.data
        const styles: any = {}
        if (notification.unread) {
            styles.backgroundColor = "white"
            styles.fontWeight = "400"
        } else {
            styles.backgroundColor = "#f2f6fc"
            styles.fontWeight = "200"
        }
        return styles
    }

    const optionsGenerator = (field:string): string[] => {
        const options = []
        for (let notification of props.rows) {
            const option = notification[field]
            if (! (options.includes(option)) ) {
                options.push(option)
            }
        }
        return options
    }

    const onRowChecked = (event: RowClickedEvent) => {
        const notification = event.node.data
        props.markUnread(notification, false)
    }

    const spanInner = `There are no notifications.
            Click "View Notification Log" above to download archived
            notifications on the SMRTLink file system`
    const noNotificationsMarkup =
        `<span class="ag-overlay-loading-center" style="pointer-events: auto;">${spanInner}</span>`

    const sortModel: SortModel = [{
        colId: "createdAt",
        sort: "desc"
    }]

    return (
        <div
            className="ag-theme-alpine notifications-table"
            style={{ height: "25rem", minHeight: "200px", width: "100%" }}
        >
            <Grid
                selectionMode = "Multiple"
                suppressSelectionHighlighting
                disableSearch
                headerData={notificationsTableCols(optionsGenerator)}
                tableId={"alarms-modal"}
                sortModel={sortModel}
                data={props.rows}
                selected = {props.selected}
                onSelectionChange={ (selected) => props.onSelectionChange(selected as NotificationRow[])}
                idField={"id"}
                loading={false}
                rowBuffer={0}
                frameworkComponents = {{
                    fullWidthCellRenderer: ExpandedNotificationRenderer,
                    checkBoxSetFilter: CheckboxSetFilter
                }}
                fullWidthCellRenderer={"fullWidthCellRenderer"}
                getRowStyle = {getRowStyle}
                suppressCellSelection
                hideScrollPositionLabel
                overlayNoRowsTemplate={noNotificationsMarkup}
                onRowClicked={ onRowChecked }
                rowClickExcludedColumns={["isChecked"]}
            />

        </div>
    )
}
