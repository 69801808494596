import dateFormat from "dateformat"

import { ColDef, ICellRendererParams } from "ag-grid-community";
import { dateColumnDef } from "../../../core/utils/ag-grid-helper";
import { NotificationModel } from "../../../data/model/notification-model";


export interface NotificationRow extends NotificationModel {
    duplicateIds: number[]
}

export const groupNotifications = (notifications: NotificationModel[]): NotificationRow[] => {

    const keyGen = (notification: NotificationModel): string => {
        const { message, sourceId, createdAt } = notification
        const day = dateFormat(createdAt, "yyyy-mm-dd")
        return `${message}-${sourceId}-${day}`
    }

    if ( Array.isArray(notifications)) {
        const notificationsMap: Record<string, NotificationModel[]> = {}
        notifications.forEach( notification => {
            const key = keyGen(notification)
            if ( !notificationsMap[key] ) {
                notificationsMap[key] = []
            }
            notificationsMap[key].push(notification)
        })

        const rows: NotificationRow[] = []

        const comparator = (n1: NotificationModel, n2: NotificationModel):number => {
            return Date.parse(n1.createdAt) - Date.parse(n2.createdAt)
        }

        for (const key in notificationsMap) {
            const notifications = notificationsMap[key]
            const mostRecent = notifications.sort(comparator)[0]
            const duplicateIds = notifications.map( n => n.id)
            const row = {
                ...mostRecent,
                ...{duplicateIds: duplicateIds}
            }
            rows.push(row)
        }
        return rows
    }
    else {
        return null
    }
}

export const typeRenderer = (params: ICellRendererParams) => {
    const notificaton: NotificationModel = params.data

    let { severity, typeId} = notificaton

    if (severity) {
        switch(severity.toUpperCase()) {
            case "INFO": return "Informational";
            default: return severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase();
          }
    } else {
        return typeId
    }
}

export const notificationsTableCols= (optionsGenerator:  (field: string) => string[]): ColDef[] => [
    {
        headerName: "ID",
        field: "id",
        pinned: "left",
        width: 90,
    },
    {
        headerName: "Message",
        field: "message",
        width: 360,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
            "white-space": "normal !important",
            "line-height": "150%"
        },
        cellRenderer:  (params: ICellRendererParams) => {
            const notification: NotificationRow = params.data
            let subscript =  (notification.duplicateIds?.length > 1) ? ` (${notification.duplicateIds.length})` : ""
            return `${notification.message}${subscript}`
        },
        flex: 1
    },
    {
        headerName: "Instrument Name",
        field: "sourceId",
        width: 240,
        filter: "checkBoxSetFilter",
        filterParams: {
            optionsGenerator: optionsGenerator
        }
    },
    {
        headerName: "Type",
        field: "severity",
        width: 150,
        filter: "checkBoxSetFilter",
        filterParams: {
            optionsGenerator: optionsGenerator
        },
        cellRenderer: typeRenderer
    },
    dateColumnDef({
        headerName: "Created On",
        field: "createdAt",
    })
]
