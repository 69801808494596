import React from "react"
import { Spinner } from "reactstrap"

// TODO
export const Loading = () => {
    return (
        <div className="d-flex justify-content-center" style={{height:"100%"}}>
            <Spinner className="pb-full-screen-loading-spinner"/>
        </div>

    )
}
