import { Reducer } from "redux"
import { EntryPointsActionTypes, EntryPointsState } from "./entry-points-types"

const initialState: EntryPointsState = {
    loading: false,
    barcodeSets: null,
    referenceSets: null,
    bedSets: null,
    error: null
}
export const entryPointsReducer: Reducer<EntryPointsState> = (
    state: EntryPointsState = initialState,
    action: any
) => {
    switch (action.type) {
        case EntryPointsActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case EntryPointsActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case EntryPointsActionTypes.SET_BARCODE_SETS:
            return Object.assign({}, state, {
                loading: false,
                barcodeSets: action.payload,
                error: null
            })
        case EntryPointsActionTypes.SET_REFERENCE_SETS:
            return Object.assign({}, state, {
                loading: false,
                referenceSets: action.payload,
                error: null
            })
        case EntryPointsActionTypes.SET_BED_SETS:
            return Object.assign({}, state, {
                loading: false,
                bedSets: action.payload,
                error: null
            })
        default:
            return state
    }
}
