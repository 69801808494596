import React, { Component } from "react"

export class AcceptCertPage extends Component {
    render() {
        return (
            <div className="accept-cert-page">
                <h2>AcceptCert Page</h2>
            </div>
        )
    }
}
