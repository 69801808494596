import { JobStatusEvent } from "../../../model/job-model"

export const AnalysisPollingTypes = {
    SET_LATEST_STATUS_EVENT: "@@analysis-polling/SET_LATEST_STATUS_EVENT",
    START_POLLING: "@@analysis-polling/START_POLLING",
    STOP_POLLING: "@@analysis-polling/START_POLLING",
    SET_IS_CANCELLED: "@@analysis-polling/SET_IS_CANCELLED"

}

export interface AnalysisPollingState {
    readonly latestStatusEvent: JobStatusEvent | null
    readonly isPolling: boolean
    readonly isCancelled: boolean
    readonly error: Error | null
}

