
// create a file and download it in the browser
export const createDownload = (content: string, filename: string) => {
    var blob = new Blob([content], {type: "text/plain"});
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}