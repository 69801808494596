import React, { FunctionComponent } from "react"
import { FormGroup, Input, Label } from "reactstrap"


interface Props {
    name: string
    checked: boolean
    onChange: (e:React.ChangeEvent<HTMLInputElement>) => void
    label: string
    disabled?: boolean
    id?: string
    value?: any
}

export const CheckboxInput: FunctionComponent<Props> = (props: Props) => {
    return (
        <div>
            <FormGroup check>
                <Input
                    type="checkbox"
                    name={props.name}
                    id={props.id || props.name}
                    checked={props.checked}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    value={props.value}
                />
                <Label for={props.name} check>
                    {props.label}
                </Label>
            </FormGroup>
        </div>
    )
}
