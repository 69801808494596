import React, { Component } from "react"
import { ErrorLayout } from "../shared/Error/ErrorLayout"

const BODY_THEME_CLASS = "btheme-base"

export class ErrorPage extends Component {

  componentDidMount() {
    document.body.classList.add(BODY_THEME_CLASS)
  }

  componentWillUnmount() {
    document.body.classList.remove(BODY_THEME_CLASS)
  }

  render() {
    return (
        <div className="error-page">
            <ErrorLayout error="" errorInfo=""/>
        </div>
    )
  }
}
