type IPromiseStopper<T> = (T) => boolean

export interface IPromisePollScheduler<T> {
    start(): Promise<T>
    cancel()
}

export class PromisePollScheduler<T> implements IPromisePollScheduler<T> {

    private isCancelled = false

    constructor (
        private task: () => Promise<T>,
        private intervalsInSecs: number[],
        private stopper: IPromiseStopper<T>
    ) {}

    private doTask(iter: number): Promise<T> {
        return this.task().then(
            result => {
                if (this.isCancelled) {
                    return result
                }
                if (this.stopper(result)) {
                    return result
                }
                const interval = this.getIntervalInMs(iter)
                return new Promise<T>( resolve =>
                    setTimeout(() => resolve(this.doTask(iter + 1)), interval)
                )
            },
            error => {
                return Promise.reject(error)
            }
        )
    }

    private  getIntervalInMs(iter: number): number {
        if (iter >=  this.intervalsInSecs.length) {
            return this.intervalsInSecs[this.intervalsInSecs.length-1] * 1000
        }

        return this.intervalsInSecs[iter] * 1000
    }

    public start(): Promise<T> {
        this.isCancelled = false
        return this.doTask(0)
    }

    public cancel() {
        this.isCancelled = true
    }
}
