import { LoadingOption, SequencingPrimer, IsoSeqVersion } from "./sample-setup-enums"
import { SampleCalculatorOutputsClassic } from "./sample-calculator-model"
import { SampleCalculatorOutputsHT } from "./sample-calculator-model-ht"
import { ResolvedParameters } from "./sample-calculator-params-model"
import { ChipType, InstrumentType, InstrumentTypeToCorrectedTypeMap, getDefaultInstrumentTypeForChipType } from "../../../../data/model/instrument-type-model"

const Default_Percent_Annealing_Reaction_To_Use_in_Binding = "90"

const CurrentVersion = "21"

export type LibraryType = "Standard" | "AAV" | "masSeq"

export class Sample {

    // Be sure to update csvConversionSpecs in sample-setup-csv-conversion.ts
    // and JSON conversion functions below if you add, remove, or rename fields.

    SampleName: string
    SampleGuid: string

    Version: string = CurrentVersion
    // Version 5 changed field names to reflect the spreadsheet

    // Version 6 added chipType field

    // Version 7 is Locust or later, with loading of Diffusion only and
    // obsoleting Ampure_Anticipated_Yield (which is now derived),
    // usePreextension and Pre_extension_Time_To_Be_Used (which are migrated in Run Design)

    // Version 8 is Locust or later, with only AMPure cleanup and obsoleting these fields:
    // Spin_Column_Anticipated_Yield
    // TC6_Anticipated_Yield
    // Spin_Column_Volume_1
    // Spin_Column_Concentration_1
    // Spin_Column_Volume_2
    // Spin_Column_Concentration_2

    // Version 9 adds:
    // isCLR
    // User_Min_Pipetting_Volume
    // IsoSeq_Version
    // ProNex_Concentration_1
    // ProNex_Concentration_2
    // ProNex_Volume_1
    // ProNex_Volume_2
    // Use_Predictive_Loading
    // Cleanup_Anticipated_Yield
    // and removes:
    // - useAmpureCleanup

    // Version 10 adds:
    // User_Annealing_to_Binding_Volume_Margin
    // User_Requested_Cells_Alternate

    // Version 11 adds:
    // Prepare_Entire_Sample

    // Version 12 adds:
    // Application
    // Annealing_Concentration
    // Binding_Concentration
    // Polymerase_Concentration
    // Binding_Time
    // Cleanup_Bead_Type
    // Cleanup_Bead_Concentration
    // User_Requested_OPLC_Alternate
    // useCleanup is no longer used

    // Version 13 adds:
    // instrumentType
    // Percent_Annealing_Reaction_To_Use_in_Binding which inverts the meaning
    //     of User_Annealing_to_Binding_Volume_Margin to be 100 - value
    // and removes:
    // User_Annealing_to_Binding_Volume_Margin

    // Version 14 adds:
    // comment

    // version 15 adds:
    // Annealing_Primer_Concentration

    // version 16 changes:
    // Binding_Time changes from hours to minutes

    // version 17 adds:
    // isBatch
    // Batch_Sample_Count

    // Version 18 removes:
    // isCLR

    // Version 19 adds:
    // libraryType

    // Version 20 changes instrumentType string mapping

    // Version 21 adds:
    // groupId
    // plate
    // wellId

    createdAt: string
    createdBy: string

    locked: null | boolean = false
    finalHtml: null | string = null

    // == Sample and Run Details ==

    chipType: ChipType
    instrumentType: InstrumentType

    // Entered
    bindingKit: string
    controlKit: string
    loadingOption: LoadingOption = "Diffusion"
    isIsoseq: boolean = false
    IsoSeq_Version: IsoSeqVersion = ""
    Available_Starting_Sample_Volume: string
    Prepare_Entire_Sample: boolean = false
    Starting_Sample_Concentration: string
    Insert_Size: string
    Sequencing_Primer: SequencingPrimer
    User_Requested_Cell_Count: string
    On_Plate_Loading_Concentration: string

    // useCleanup is no longer used
    useCleanup: boolean = true
    Cleanup_Anticipated_Yield: string

    // Entered for AMPure Cleanup
    Ampure_Volume_1: string
    Ampure_Concentration_1: string
    Ampure_Volume_2: string
    Ampure_Concentration_2: string

    // Entered for ProNex Cleanup
    ProNex_Concentration_1: string
    ProNex_Concentration_2: string
    ProNex_Volume_1: string
    ProNex_Volume_2: string

    User_Min_Pipetting_Volume: string = "1"

    Use_Predictive_Loading: boolean = false

    // For Application-driven version
    Application: string
    Annealing_Concentration: string
    Annealing_Primer_Concentration: string
    Binding_Concentration: string
    Polymerase_Concentration: string
    Binding_Time: string
    Cleanup_Bead_Type: string = ""
    Cleanup_Bead_Concentration: string
    User_Requested_OPLC_Alternate: string

    Percent_Annealing_Reaction_To_Use_in_Binding: string = Default_Percent_Annealing_Reaction_To_Use_in_Binding
    User_Requested_Cells_Alternate: string = ""

    comment = ""

    isBatch: boolean = false
    Batch_Sample_Count: string = ""

    libraryType: LibraryType = "Standard"

    groupId: string = ""
    plate: string = ""
    wellId: string = ""

    // Derived
    bindingKitName: string
    controlKitName: string
    calculatedOutputsClassic: SampleCalculatorOutputsClassic
    calculatedOutputsHT: SampleCalculatorOutputsHT

    // Not stored -- used by React to distinguish samples in a list
    localUUID = this.guid()

    errors = []

    getCalculatedOutputs() {
        if (this.isBatch) {
            return this.calculatedOutputsHT
        } else {
            return this.calculatedOutputsClassic
        }
    }

    // Verifies that all selected types are of the same type and can be printed together
    // Returns: empty string if ok, or error messages otherwise
    static mismatchedTypeError(sampleArray: Sample[]): String {

        const first = sampleArray[0]

        const chipType = first.chipType
        const instrumentType = first.instrumentType
        const loadingOption = first.loadingOption
        const cleanupMethod = first.Cleanup_Bead_Type
        const useSpikeInControl = first.useSpikeInControl()
        const Sequencing_Primer = first.Sequencing_Primer
        const bindingKitName = first.bindingKitName
        const isIsoseq = first.isIsoseq
        const IsoSeq_Version = first.IsoSeq_Version
        const Use_Predictive_Loading = first.Use_Predictive_Loading
        const Binding_Time = first.Binding_Time
        const libraryType = first.libraryType

        let sameChipType = true
        let sameInstrumentType = true
        let sameMagBead = true
        let sameCleanupMethod = true
        let sameUseSpikeInControl = true
        let sameSequencingPrimer = true
        let sameBindingKit = true
        let sameReadType = true
        let sameIsIsoseq = true
        let sameIsoSeqVersion = true
        let samePredictiveLoading = true
        let sameBindingTime = true
        let sameLibraryType = true

        for (let i = 0; i < sampleArray.length; i += 1) {
            let sample = sampleArray[i]

            const sampleCleanupMethod = sample.Cleanup_Bead_Type

            if (sample.chipType !== chipType) {
                sameChipType = false
            }
            if (sample.instrumentType !== instrumentType) {
                sameInstrumentType = false
            }
            if (sample.loadingOption !== loadingOption) {
                sameMagBead = false
            }
            if (Sequencing_Primer !== sample.Sequencing_Primer) {
                sameSequencingPrimer = false
            }
            if (bindingKitName !== sample.bindingKitName) {
                sameBindingKit = false
            }
            if (sampleCleanupMethod !== cleanupMethod) {
                sameCleanupMethod = false
            }
            if (Boolean(sample.isIsoseq) !== Boolean(isIsoseq)) {
                sameIsIsoseq = false
            }
            if (sample.IsoSeq_Version !== IsoSeq_Version) {
                sameIsoSeqVersion = false
            }
            if (Boolean(sample.useSpikeInControl()) !== Boolean(useSpikeInControl)) {
                sameUseSpikeInControl = false
            }
            if (Boolean(sample.Use_Predictive_Loading) !== Boolean(Use_Predictive_Loading)) {
                samePredictiveLoading = false
            }
            if (sample.Binding_Time !== Binding_Time) {
                sameBindingTime = false
            }
            if ((sample.libraryType === "masSeq" && !["masSeq"].includes(libraryType)) ||
                (sample.libraryType === "Standard" && !["Standard", "AAV"].includes(libraryType)) ||
                (sample.libraryType === "AAV" && !["Standard", "AAV"].includes(libraryType))) {
                sameLibraryType = false
            }
        }

        let nonSameErrors = ""
        if (!sameChipType) {
            nonSameErrors += "Use different chip types. "
        }
        if (!sameInstrumentType) {
            nonSameErrors += "Use different instrument types. "
        }
        if (!sameMagBead) {
            nonSameErrors += "Use different magnetic bead protocols. "
        }
        if (!first.isBatch && !sameSequencingPrimer) {
            nonSameErrors += "Use different sequencing primers. "
        }
        if (!sameUseSpikeInControl) {
            nonSameErrors += "Differ in use of Spike-In Control. "
        }
        if (!sameBindingKit) {
            nonSameErrors += "Differ in use of Binding Kit. "
        }
        if (!sameCleanupMethod) {
            nonSameErrors += "Differ in use of Cleanup method. "
        }
        if (!first.isBatch && !sameReadType) {
            nonSameErrors += "Mix Continuous Long Reads and CCS Reads. "
        }
        if (!first.isBatch && !sameIsIsoseq) {
            nonSameErrors += "Mix isoseq and non-isoseq experiments. "
        }
        if (!first.isBatch && !sameIsoSeqVersion) {
            nonSameErrors += "Mix isoseq versions. "
        }
        if (!first.isBatch && !samePredictiveLoading) {
            nonSameErrors += "Mix Adaptive Loading and not Adaptive Loading. "
        }
        if (!sameBindingTime) {
            nonSameErrors += "Differ in use of Binding Time. "
        }
        if (first.isBatch && !sameLibraryType) {
            nonSameErrors += "Incompatible Library types. "
        }
        if (nonSameErrors !== "") {
            nonSameErrors = "Sorry. We cannot display samples with incompatible instructions together," +
            " due to differences in the following fields: " + nonSameErrors
        }

        if (sampleArray && sampleArray.length > 1 && sampleArray.find(sample => sample.Application === "pureTarget")) {
            nonSameErrors += "Only one PureTarget repeat expansion application can be prepared at one time."
        }

        return nonSameErrors
    }

    public static useSpikeInControl(controlKit: string): boolean {
        return Boolean(controlKit && controlKit !== "")
    }

    guid() {
        // make a guid for new samples using simple random numbers, per
        // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript

        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        }

        return s4() + s4() + "-" + s4() + "-" + s4() + "-" +
            s4() + "-" + s4() + s4() + s4()
    }

    setDefaultsFromParams(params: ResolvedParameters) {
        this.User_Min_Pipetting_Volume = "" + params.Minimum_Pipetting_Volume
        this.Cleanup_Anticipated_Yield = "" + params.Cleanup_Anticipated_Yield
        this.controlKit = this.chipType === "1mChip"
                // 1M 3.0
                ? "Lxxxxx101500300123199"
                : this.chipType === "8mChip"
                    // 8M 1.0
                    ? "Lxxxxx101717600123199"
                    // 25M
                    : "Lxxxxx102798000123199"
    }

    useSpikeInControl(): boolean {
        return Sample.useSpikeInControl(this.controlKit)
    }

    hasAllAmpureCleanupFieldsEntered(): boolean {
        return !(isNaN(this.Ampure_Volume_2 as any) || this.Ampure_Volume_2.trim() === "" ||
            isNaN(this.Ampure_Concentration_2 as any) || this.Ampure_Concentration_2.trim() === "")
    }

    hasAllProNexCleanupFieldsEntered(): boolean {
        return !(isNaN(this.ProNex_Volume_2 as any) || this.ProNex_Volume_2.trim() === "" ||
            isNaN(this.ProNex_Concentration_2 as any) || this.ProNex_Concentration_2.trim() === "")
    }

    ReadFromJson(json) {

        this.SampleName = json.SampleName
        this.SampleGuid = json.SampleGuid
        this.Version = CurrentVersion

        this.createdAt = json.createdAt
        this.createdBy = json.createdBy

        this.chipType = json.chipType || "1mChip"
        this.instrumentType = json.instrumentType ?
            InstrumentTypeToCorrectedTypeMap[json.instrumentType] :
            getDefaultInstrumentTypeForChipType(this.chipType)

        this.bindingKit = json.bindingKit
        this.Sequencing_Primer = json.Sequencing_Primer
        this.loadingOption = json.loadingOption
        this.isIsoseq = json.isIsoseq || false
        this.IsoSeq_Version = json.IsoSeq_Version
        this.controlKit = json.controlKit
        this.useCleanup = json.useCleanup || false
        this.Cleanup_Anticipated_Yield = json.Cleanup_Anticipated_Yield || "50"

        this.Ampure_Volume_1 = json.Ampure_Volume_1 || ""
        this.Ampure_Concentration_1 = json.Ampure_Concentration_1 || ""
        this.Ampure_Volume_2 = json.Ampure_Volume_2 || ""
        this.Ampure_Concentration_2 = json.Ampure_Concentration_2 || ""

        this.ProNex_Volume_1 = json.ProNex_Volume_1 || ""
        this.ProNex_Concentration_1 = json.ProNex_Concentration_1 || ""
        this.ProNex_Volume_2 = json.ProNex_Volume_2 || ""
        this.ProNex_Concentration_2 = json.ProNex_Concentration_2 || ""

        this.User_Min_Pipetting_Volume = json.User_Min_Pipetting_Volume || "1"

        // convert User_Annealing_to_Binding_Volume_Margin in earlier versions to Percent_Annealing_Reaction_To_Use_in_Binding
        if (Number.parseInt(json.Version, 10) < 13 && json.User_Annealing_to_Binding_Volume_Margin) {
            const marginValue = 100 - parseFloat(json.User_Annealing_to_Binding_Volume_Margin)
            if (isNaN(marginValue)) { // || marginValue <= 0 || marginValue >= 100
                this.Percent_Annealing_Reaction_To_Use_in_Binding= Default_Percent_Annealing_Reaction_To_Use_in_Binding
            } else {
                this.Percent_Annealing_Reaction_To_Use_in_Binding = "" + marginValue
            }
        } else {
            this.Percent_Annealing_Reaction_To_Use_in_Binding =
                json.Percent_Annealing_Reaction_To_Use_in_Binding || Default_Percent_Annealing_Reaction_To_Use_in_Binding
        }

        this.User_Requested_Cells_Alternate = json.User_Requested_Cells_Alternate || ""

        this.Use_Predictive_Loading = json.Use_Predictive_Loading || false

        this.Prepare_Entire_Sample = json.Prepare_Entire_Sample || false
        this.Application = remapObsoleteApplicationIfNeeded(this.chipType, json.Application || "")
        this.Annealing_Concentration = json.Annealing_Concentration || ""
        this.Annealing_Primer_Concentration = json.Annealing_Primer_Concentration || "20"
        this.Binding_Concentration = json.Binding_Concentration || ""
        this.Polymerase_Concentration = json.Polymerase_Concentration || ""
        this.Binding_Time = convertBindingTimeFromHoursToMinutesIfNeeded(json.Binding_Time, json.Version)
        this.Cleanup_Bead_Type = json.Cleanup_Bead_Type || ""
        this.Cleanup_Bead_Concentration = json.Cleanup_Bead_Concentration || ""
        this.User_Requested_OPLC_Alternate = json.User_Requested_OPLC_Alternate || ""
        this.comment = json.comment || ""

        this.isBatch = json.isBatch || false
        this.Batch_Sample_Count = json.Batch_Sample_Count || ""

        this.libraryType = json.libraryType || "Standard"

        this.groupId = json.groupId || ""
        this.plate = json.plate || ""
        this.wellId = json.wellId || ""

        if (Number.parseInt(json.Version, 10) < 5 && !(
            // Heuristic to not convert samples saved with wrong version number during development
            json.Available_Starting_Sample_Volume ||
            json.Starting_Sample_Concentration ||
            json.Insert_Size
        )) {
            // Translate data to version 5 from previous versions
            this.Available_Starting_Sample_Volume = json.sampleAvailableVolume_ul
            this.Starting_Sample_Concentration = json.sampleConcentration_ng_per_ul
            this.Insert_Size = json.sampleInsertSize_bp
            this.User_Requested_Cell_Count = json.numberOfSMRTCells_cells
            this.On_Plate_Loading_Concentration = json.onPlateLoadingConcentration_pM
        } else {
            // version 5 data or later
            this.Available_Starting_Sample_Volume = json.Available_Starting_Sample_Volume
            this.Starting_Sample_Concentration = json.Starting_Sample_Concentration
            this.Insert_Size = json.Insert_Size
            this.User_Requested_Cell_Count = json.User_Requested_Cell_Count
            this.On_Plate_Loading_Concentration = json.On_Plate_Loading_Concentration
        }
    }

    ToJson() {
        // Output just the things we need to save into a JSON string for persistance in a key/value store

        const output: any = {}
        output.SampleName = this.SampleName
        output.SampleGuid = this.SampleGuid
        output.Version = CurrentVersion

        output.createdAt = this.createdAt
        output.createdBy = this.createdBy

        output.chipType = this.chipType
        output.instrumentType = this.instrumentType

        output.bindingKit = this.bindingKit
        output.Sequencing_Primer = this.Sequencing_Primer
        output.controlKit = this.controlKit
        output.loadingOption = this.loadingOption
        output.isIsoseq = this.isIsoseq
        output.IsoSeq_Version = this.IsoSeq_Version
        output.Available_Starting_Sample_Volume = this.Available_Starting_Sample_Volume
        output.Prepare_Entire_Sample = this.Prepare_Entire_Sample
        output.Starting_Sample_Concentration = this.Starting_Sample_Concentration
        output.Insert_Size = this.Insert_Size
        output.User_Requested_Cell_Count = this.User_Requested_Cell_Count
        output.On_Plate_Loading_Concentration = this.On_Plate_Loading_Concentration

        output.useCleanup = this.useCleanup
        output.Cleanup_Anticipated_Yield = this.Cleanup_Anticipated_Yield

        output.Ampure_Volume_1 = this.Ampure_Volume_1
        output.Ampure_Concentration_1 = this.Ampure_Concentration_1
        output.Ampure_Volume_2 = this.Ampure_Volume_2
        output.Ampure_Concentration_2 = this.Ampure_Concentration_2

        output.ProNex_Volume_1 = this.ProNex_Volume_1
        output.ProNex_Concentration_1 = this.ProNex_Concentration_1
        output.ProNex_Volume_2 = this.ProNex_Volume_2
        output.ProNex_Concentration_2 = this.ProNex_Concentration_2

        output.User_Min_Pipetting_Volume = this.User_Min_Pipetting_Volume
        output.Percent_Annealing_Reaction_To_Use_in_Binding = this.Percent_Annealing_Reaction_To_Use_in_Binding
        output.User_Requested_Cells_Alternate = this.User_Requested_Cells_Alternate

        output.Use_Predictive_Loading = this.Use_Predictive_Loading

        output.Application = this.Application
        output.Annealing_Concentration = this.Annealing_Concentration
        output.Annealing_Primer_Concentration = this.Annealing_Primer_Concentration
        output.Binding_Concentration = this.Binding_Concentration
        output.Polymerase_Concentration = this.Polymerase_Concentration
        output.Binding_Time = this.Binding_Time
        output.Cleanup_Bead_Type = this.Cleanup_Bead_Type
        output.Cleanup_Bead_Concentration = this.Cleanup_Bead_Concentration
        output.User_Requested_OPLC_Alternate = this.User_Requested_OPLC_Alternate

        output.comment = this.comment

        output.isBatch = this.isBatch
        output.Batch_Sample_Count = this.Batch_Sample_Count

        output.libraryType = this.libraryType

        output.groupId = this.groupId
        output.plate = this.plate
        output.wellId = this.wellId

        return JSON.stringify(output)
    }
}

export function convertBindingTimeFromHoursToMinutesIfNeeded(time, version) {
    // Convert stored value from hours to minutes for earlier version
    if (isNaN(Number.parseInt(version, 10)) || Number.parseInt(version, 10) < 16) {
        // Need to convert if defined
        if (!time) {
            return ""
        } else {
            let timeInMinutes = Number.parseFloat(time) * 60
            if (isNaN(timeInMinutes)) {
                return ""
            } else {
                return "" + timeInMinutes
            }
        }
    } else {
        return time || ""
    }
}

const obsolete1MChipApplicationsToRemap = {
    hifiViralSARSCoV2: "humanWGS",
    ultraLowDNAInput: "humanWGS",
    variantDetection: "humanWGS",
    structuralVariationDetection: "humanWGS",
    hifiReads: "humanWGS"
}

const obsolete8MChipApplicationsToRemap = {
    lowDNAInput: "humanWGS",
    ultraLowDNAInput: "humanWGS",
    variantDetection: "humanWGS",
    structuralVariationDetection: "humanWGS",
    hifiReads: "humanWGS"
}

export function remapObsoleteApplicationIfNeeded(chipType: ChipType, applicationId: string) {
    if (!applicationId) { return applicationId }

    let remappedId = ""
    if (chipType === "1mChip") {
        remappedId = obsolete1MChipApplicationsToRemap[applicationId]
    } else {
        remappedId = obsolete8MChipApplicationsToRemap[applicationId]
    }

    if (remappedId) { return remappedId }
    return applicationId
}
