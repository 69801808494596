import { Reducer } from "redux"
import { AnalysisPollingState, AnalysisPollingTypes } from "./analysis-polling-types"

const initialState: AnalysisPollingState = {
    latestStatusEvent: null,
    isPolling: false,
    isCancelled: false,
    error: null
}

export const analysisPollingReducer: Reducer<AnalysisPollingState> =
    (state: AnalysisPollingState = initialState, action: any) => {
        switch (action.type) {
            case AnalysisPollingTypes.SET_LATEST_STATUS_EVENT:
                return Object.assign({}, state, {
                    latestStatusEvent: action.payload
                })
            case AnalysisPollingTypes.START_POLLING:
                return Object.assign({}, state, {
                    isPolling: true
                })
            case AnalysisPollingTypes.STOP_POLLING:
                return Object.assign({}, state, {
                    isPolling: false
                })
            case AnalysisPollingTypes.SET_IS_CANCELLED:
                return Object.assign({}, state, {
                    isCancelled: action.payload
                })
            default:
                return state
        }
}
