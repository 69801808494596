import React, { FC, ReactNode, Fragment } from "react"
import { Action } from "redux"
import { ThunkDispatch } from "redux-thunk"
import { connect } from "react-redux"
import { Modal, ModalBody } from "reactstrap"
import { Route, RouteComponentProps, Redirect } from "react-router-dom"
import { ConnectedReduxProps, ApplicationState } from "../core/types"
import { login } from "./login/store/login-actions"
import { LoginForm } from "./login/components/LoginForm"
import { LoginPayload } from "./login/store/login-types"
import { User } from "../data/model/user-model"

type Role = "user" | "bioinformatician" | "labtech" | "admin"

interface Props {
  component: FC
  children: ReactNode
  user: User | null
  token: string | null
  loading: boolean
  errorMessage: string
  error: Error | null
  refreshToken: string | null
  guard: Role
  onLogin(payload: LoginPayload): void
}

type AllProps = Props & RouteComponentProps<{}> & ConnectedReduxProps

export function isAuthorized(user: User | null, requiredRole: Role) {
    // This could be changed to use scopes instead of roles once those are provided for the user via WOS2.
    if (!requiredRole) { throw new Error("requiredRole not specified for ProtectedRouteComponent") }
    if (!user) { return false }
    if (requiredRole === "admin") {
        return user.roles.includes("Internal/PbAdmin")
    }
    if (requiredRole === "labtech") {
        return user.roles.includes("Internal/PbLabTech") ||
            user.roles.includes("Internal/PbAdmin")
    }
    if (requiredRole === "bioinformatician") {
        return user.roles.includes("Internal/PbBioinformatician") ||
            user.roles.includes("Internal/PbLabTech") ||
            user.roles.includes("Internal/PbAdmin")
    }
    if (requiredRole === "user") {
        // Any logged in user
        return true
    }
    throw new Error("requested requiredRole " + requiredRole + "is unsupported for ProtectedRouteComponent")
}

const ProtectedRouteComponent: FC<AllProps> = ({
  component,
  children,
  user,
  loading,
  errorMessage,
  refreshToken,
  onLogin,
  guard,
  ...rest
}: AllProps) => {
  
    if ( user && !isAuthorized(user, guard)) {
        return <Redirect to="/bad-perm"/>
    }
    return (
        <Fragment>
            {/* This causes a React error loop sometimes: !user && !refreshToken && <Redirect to="/login"/> */}
            {user && component && <Route {...rest} component={component}/>}
            {user && !component && <Route {...rest}>{children}</Route>}
            {!refreshToken &&
            <Modal contentClassName="bg-transparent" size="sm" isOpen>
                <ModalBody className="login-form">
                <LoginForm loading={loading} onLogin={onLogin} errorMessage={errorMessage}/>
                </ModalBody>
            </Modal>
            }
        </Fragment>
    )
}

const mapStateToProps = ({ auth }: ApplicationState) => auth

const mapDispatchToProps = (dispatch: ThunkDispatch<ApplicationState, undefined, Action>) => ({
  onLogin: (payload: LoginPayload) => {
    dispatch(login({ ...payload, disableRedirect: true }))
  }
})

export const ProtectedRoute: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProtectedRouteComponent)
