// TODO mcantor 5.6.21 -- deprecate this class in favor of Notification


import { toDisplayString } from "../utils/shared/enum"

export enum AlarmSeverity {
    // Clear: Alarm is safe state.
    Clear = 0,

    // Warning: Minor problem due to a resource or operating condition approaching specified limits
    // or an unexpected event or condition.
    // Data quality and throughput are at spec but at risk.
    Warning = 1,

    // Error: Serious problem.
    // High likelihood of some data and/or throughput loss.
    Error = 2,

    // Critical: Unrecoverable situation. Data acquisition operations will discontinue due to this condition.
    // Restart recommended.
    // Data loss likely.
    Critical = 3,

    // Fatal: Unrecoverable and potentially damaging situation that will result in a graceful shutdown of system.
    // Data loss likely.
    Fatal = 4,

    // FatalImmediate: Unrecoverable and potentially damaging situation that will result in an immediate shutdown of system.
    // Data loss very likely.
    FatalImmediate = 5
}

export class AlarmModel {
    // Globally unique id of the Alarm (e.g., smrtflow.alarms.tmp_dir)
    public id: string

    // Description of Alarm
    public message: string

    // Display name of Alarm
    public name: string

    public severity: AlarmSeverity

    // Where in the system the alarm originated (e.g. pbsmrtpipe, client)
    public source: string

    // Last updated at
    public updatedAt: Date

    constructor(
        id: string,
        message: string,
        name: string,
        severity: AlarmSeverity,
        source: string,
        updatedAt: Date | string
    ) {
        this.id = id
        this.message = message
        this.name = name
        this.severity = severity
        this.source = source
        this.updatedAt =
            typeof updatedAt === "string"
                ? new Date(updatedAt)
                : updatedAt
    }

    public static clone(source: AlarmModel): AlarmModel {
        return new AlarmModel(
            source.id,
            source.message,
            source.name,
            source.severity,
            source.source,
            source.updatedAt
        )
    }

    public static parseSeverity(severityString): AlarmSeverity {
        const severity = {
            "": AlarmSeverity.Clear,
            clear: AlarmSeverity.Clear,

            warn: AlarmSeverity.Warning,
            warning: AlarmSeverity.Warning,

            error: AlarmSeverity.Error,

            critical: AlarmSeverity.Critical,

            fatal: AlarmSeverity.Fatal,

            fatalimmediate: AlarmSeverity.FatalImmediate
        }[(severityString || "").toLowerCase()]
        if (severity === undefined) {
            /* eslint-disable-next-line no-console */
            console.log("Alarm with unexpected severity", severityString)
            /* eslint-disable-next-line no-console */
            return AlarmSeverity.Warning
        }
        return severity
    }

    public get updatedAtText(): string {
        return this.updatedAt ? this.format(this.updatedAt) : "Unknown"
    }

    public get severityText(): string {
        return toDisplayString(this.severity, AlarmSeverity)
    }

    public format(date: Date): string {
        // return formatDate(date);
        return "" + date
    }
}
