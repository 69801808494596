import { Reducer } from "redux"
import { NewAnalysisState, NewAnalysisTypes } from "./new-analysis-types"
import { DEFAULT_STEP_ONE_SELECTIONS } from "./new-analysis-reselect-helpers"

const initialState: NewAnalysisState = {
    stepOne: DEFAULT_STEP_ONE_SELECTIONS,
    isSubmittingJob: false,
    numJobsSubmitted: 0
}

export const newAnalysisReducer: Reducer<NewAnalysisState> =
    (state: NewAnalysisState = initialState, action: any) => {
        switch (action.type) {

            case NewAnalysisTypes.SET_STEP_ONE_SELECTIONS:
                return Object.assign({}, state, {
                    loading: false,
                    stepOne: action.payload
                })
            case NewAnalysisTypes.RESET_STEP_ONE_SELECTIONS:
                return Object.assign({}, state, {
                    loading: false,
                    stepOne: DEFAULT_STEP_ONE_SELECTIONS
                })
            case NewAnalysisTypes.SET_IS_SUBMITTING_JOB:
                return Object.assign({}, state, {
                    loading: false,
                    isSubmittingJob: action.payload
                })
            case NewAnalysisTypes.SET_NUM_JOBS_SUBMITTED:
                return Object.assign({}, state, {
                    numJobsSubmitted: action.payload
                })
            case NewAnalysisTypes.API_ATTEMPT:
                return Object.assign({}, state, {
                    loading: true
                })

            case NewAnalysisTypes.API_SUCCESS:
                return Object.assign({}, state, {
                    loading: false
                })

            case NewAnalysisTypes.API_FAILURE:
                return Object.assign({}, state, {
                    loading: false
                })

            default:
                return state
        }
}
