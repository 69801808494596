/* eslint-disable no-console */

import { useState } from "react"
import { smrtLinkTimeFormat } from "./smrt-link-date-format"

export function useMergeState<T>(initialState: T): [Partial<T>, (t:Partial<T>) => void] {
    const [state, setState] = useState<T>(initialState)
    const setMergedState = (newState: Partial<T>) => setState((prevState: T) => Object.assign({}, prevState, newState))
    return [state, setMergedState]
}


export const json2csv = (objArray: any, headers?: any) => {
    let array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray
    let str = ""
    let line = ""

    if (!headers) {
        headers = Object.keys(array[0]).map(key => ({
            name: key,
            value: key
        }))
    }

    for (let header of headers) {
        let value = header.name + ""
        line += "\"" + value.replace(/"/g, "\"\"") + "\","
    }

    line = line.slice(0, -1)
    str += line + "\r\n"

    for (let i = 0; i < array.length; i++) {
        let line = ""

        for (let header of headers) {
            let value = array[i][header.value] + ""

            if (value) {
                line += "\"" + value.replace(/"/g, "\"\"") + "\","
            } else {
                line += ","
            }
        }

        line = line.slice(0, -1)
        str += line + "\r\n"
    }
    return str
}

export const downloadCsvFile = (data: any, fileName: string) => {
    const hiddenElement = document.createElement("a")
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURIComponent(data)
    hiddenElement.target = "_blank"
    hiddenElement.download = fileName
    hiddenElement.click()
}


export const logObject = (obj: any, label: string) => {

    console.group(label)
    console.log(obj)
    console.groupEnd()
}

export const logTime = (msg: string) => {
    console.log(smrtLinkTimeFormat(new Date()), " -- ", msg)
}

export function blobToDataURL(blob: Blob): Promise<string> {
    return convertBlob(blob, true)
}

export function blobToText(blob: Blob): Promise<string> {
    return convertBlob(blob, false)
}

function convertBlob(blob: Blob, toDataURL: boolean): Promise<string> {
    let reader = new FileReader()

    if (toDataURL) {
        reader.readAsDataURL(blob)
    } else {
        reader.readAsText(blob)
    }

    return new Promise((resolve, reject) => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.onerror = (err: any) => reject(err)
    })
}
