import React, { FC } from "react"
import {
    InputGroup,
    Input
} from "reactstrap"
import { useMergeState } from "../../core/utils"

interface Props {
    id?: string
    placeholder: string
    gridRef: any
    onSearchChange?: (text: string) => void
    isLocalSearch?: boolean
}

interface State {
    text: string
    debounceTimeout: number | null
}

const INITIAL_STATE: State = {
    text: "",
    debounceTimeout: null
}

export const Search: FC<Props> = (props: Props) => {

    const [state, setState] = useMergeState<State>(INITIAL_STATE)

    const handleChange = (e: any) => {
        const text = e.target.value
        const stateUpdate = { text }
        setState(stateUpdate)

        if (props.gridRef && props.isLocalSearch) { props.gridRef.setQuickFilter(text) }

        if (props.onSearchChange) {
            if (state.debounceTimeout) {
                clearTimeout(state.debounceTimeout)
            }
            const timeout = setTimeout(() => {
                setState({debounceTimeout: null})
                props.onSearchChange(text)
            }, 1000)
            setState({debounceTimeout: timeout as any})
        }
    }

    return (
        <div className="d-flex">
            <InputGroup size="md" className="ml-2">
                <Input
                    type="search"
                    name="search"
                    className="table-search"
                    value={state.text}
                    placeholder={props.placeholder}
                    onChange={handleChange}
                    style={{borderColor: "black", borderWidth: 1}}
                />
            </InputGroup>
        </div>
    )
}
