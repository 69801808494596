import React, { useEffect, Fragment, forwardRef, useImperativeHandle, ReactNode, useRef, useMemo } from "react"
import { AgGridReact } from "ag-grid-react"
import { GridApi, ColDef, ColGroupDef, ColumnApi, GridOptions, IDatasource, IGetRowsParams, RowClickedEvent } from "ag-grid-community"

import { Search } from "./Search"
import { InstrumentToggle } from "./InstrumentToggle"
import { BadgeButton } from "./BadgeButton"
import { useMergeState } from "../../core/utils"

import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import { scrollPositionLabel, CheckboxHeaderComponent, EmptyHeaderComponent } from "../../core/utils/ag-grid-helper"
import { usePrevious } from "../../core/utils/use-previous"
import { SortModel, storeGridState, retrieveGridState, StoredGridState } from "../../data/services/grid-storage-service"
import { DatasetsOrAnalysis } from "../../silos/constants"
import { SearchColumnHeader } from "./SearchColumnHeader"
import * as _ from "lodash"
import { useDynamicCallback } from "../../core/utils/useDynamicCallback"
import { InstrumentType } from "../../data/model/instrument-type-model"

export const GRID_INFINITE_SCROLLING_PAGE_SIZE = 2000

const STABILITY_INTERVAL = 300 // ms after lastUpdate till we declare the grid stable

export interface IDatasetSourceWithUpdateFlag extends IDatasource {
    updateFlag: string
}

export type GridSelectionMode = "Single" | "Multiple" | "None"

export interface TableProps extends GridOptions{

    data: any[]

    loading: boolean
    tableId?: string
    tableTitle?: string
    disableSearch?: boolean
    idField: string
    nameField?: string
    pageSize?: number
    forceRefreshToggle?: boolean

    // selection
    selectionMode?: GridSelectionMode,
    selected?: any[]
    isSelectable?: (rowData: any) => boolean,
    onSelectionChange?: (selected: any[], notSelected: any[]) => void
    hideSelectAll?: boolean
    suppressSelectionHighlighting?: boolean

    // "highlighted" this overloads the ag-grid selection property to achieve row highlighting
    highlighted?: any[]

    // filter
    searchId?: string
    searchBarPlaceholder?: string
    searchBarHelpHref?: string
    onSearchChanged?: (text: string) => void

    // main cell
    mainHeaderPrependItems?: ColDef[]
    mainHeaderSectionItems?: ColDef[]

    // rest of header data
    headerData?: ColDef[]

    // sequel selector
    sequelSelector?: boolean

    // cart button
    hasCart?: boolean
    modalHeader?: string
    numSelected?: number
    onCartBtnClicked?: () => void

    // sorting
    sortModel?: SortModel
    setSortModel?: (sortModel: SortModel) => void

    // if onRowClick is specified, use this to block the event if it comes from specific columns
    rowClickExcludedColumns?: string[]

    currentInstrumentType?: InstrumentType

    hideScrollPositionLabel?: boolean

    datasetsOrAnalysis?: DatasetsOrAnalysis
    children?: ReactNode

    // style to applied to the div containing the AgGridReact
    gridHeight?: string
}

interface State {
    scrollPositionLabel: string
    columnDefs: ColumnDef[],
    isGridConfigured: boolean,
    isSelectAllChecked: boolean,
    viewPortChangeFlag: boolean,
}

const INITIAL_STATE: State = {
    scrollPositionLabel: "",
    columnDefs: [],
    isGridConfigured: false,
    isSelectAllChecked: false,
    viewPortChangeFlag: false
}

export type ColumnDef = ColGroupDef | ColDef

export const columnDefId = (colDef: ColumnDef): string | null => {
    if(!colDef) {
        return null
    }
   try {
       return (colDef as ColDef).colId
   } catch {
       return (colDef as ColGroupDef).groupId
   }
}

export const Grid = forwardRef((props: TableProps, ref) => {
    const [state, setState] = useMergeState<State>(INITIAL_STATE)

    const stabilityTimer = useRef<NodeJS.Timer>()
    const gridApiRef = useRef<GridApi>(null)
    const columnApiRef = useRef<ColumnApi>(null)

    const stabilityElementRef = React.createRef<HTMLDivElement>()

    // "onDestroy" useEffect
    useEffect( () => {
        return () => {
            if (stabilityTimer.current) {
                clearTimeout(stabilityTimer.current)
            }
            gridApiRef.current = null
            columnApiRef.current = null
        }
    }, [])

    function onComponentStateChanged() {
        if (stabilityElementRef.current) {
            if (stabilityTimer.current) {
                clearTimeout(stabilityTimer.current)
            }
            stabilityElementRef.current.className = `${props.tableId}_is_updating`
            stabilityTimer.current = setTimeout( () => {
                    if (stabilityElementRef.current) {
                        stabilityElementRef.current.className = `${props.tableId}_is_stable`
                    }
            },  STABILITY_INTERVAL)
        }
    }

    function wrapDatasource(datasource: IDatasource) {
        if (!datasource) {
            return datasource
        }
        return {
            getRows(params: IGetRowsParams) {
                const originalSuccessCallback = params.successCallback
                params.successCallback = (rowsThisBlock: any[], lastRow?: number) => {
                    originalSuccessCallback(rowsThisBlock, lastRow)
                    updateScrollPositionLabel()
                }
                return datasource.getRows(params)
            }
        }
    }
    const datasourceRef = useRef<IDatasource>(wrapDatasource(props.datasource))
    useEffect(() => {
        datasourceRef.current = wrapDatasource(props.datasource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.datasource])
    const datasource = datasourceRef.current

    useEffect(() => {
        if (state.isGridConfigured
                && gridApiRef.current
                && datasource
                && (!gridApiRef.current["infiniteRowModel"] || datasource !== gridApiRef.current["infiniteRowModel"].datasource)
            ) {
            gridApiRef.current.setDatasource(datasource)
        }
    }, [state.isGridConfigured, datasource])

    useEffect(() => {
        if (gridApiRef.current) {
            if (!props.datasource) {
                if (props.data === null) {
                    gridApiRef.current.showLoadingOverlay()
                } else {
                    gridApiRef.current.hideOverlay()
                }
            }
        }
    }, [props.datasource, props.data])

    // Fixes ag-grid bug (we think) where getRowNodeId is using a stale closure over the props.
    const idFieldRef = useRef(props.idField)
    useEffect(() => {
        idFieldRef.current = props.idField
    }, [props.idField])

    const defaultColumnDef = {
        filter: true,
        sortable: true,
        resizable: true
    }

    const defaultPageSize = props.pageSize

    const hasColumnGroups = (): boolean => {
        if (!props.headerData) { return false }
        for (let colDef of props.headerData) {
            let gColDef = colDef as ColGroupDef
            if (gColDef.children && gColDef.children.length > 0) {
                return true
            }
        }
        return false
    }

    const getRowClass = useDynamicCallback((data: any) => {
        if (props.suppressSelectionHighlighting) {
            return ""
        }
        if (props.selected && props.selected.length > 0 && data) {
            const ids = props.selected.map( data => data[props.idField])
            if (ids.includes(data[props.idField])) {
                return "ag-grid-selected"
            }
        }
        if (props.highlighted) {
            const ids = props.highlighted.map( data => data[props.idField])
            if (ids.includes(data[props.idField])) {
                return "ag-grid-highlighted"
            }
        }
        return ""
    })

    const getIdForSelectAllCheckBox = () => {
        return "selectAll-" + props.tableId
    }

    const calculateColumnDefs = () => {

        const mainHeaderColumns: ColDef[] = []

        const { selectionMode, isSelectable } = props
        if (selectionMode && selectionMode !== "None") {
            let checkboxCol: ColumnDef = {
                headerName: "",
                colId: "isChecked",
                pinned: "left",
                filter: false,
                width: 60,
                sortable: false,
                cellRenderer: (params) => {
                    const hasCheckbox = params.data && ( !isSelectable || isSelectable(params.data))
                    if (hasCheckbox) {
                        let checkbox = document.createElement("input")
                        checkbox.checked = isSelected(params.data)
                        checkbox.setAttribute("type", "checkbox")
                        checkbox.addEventListener("click", () => { toggleSelection(params.data)} )
                        return checkbox
                    }
                    else {
                        return null
                    }
                }
            }
            if (selectionMode === "Multiple" && !props.hideSelectAll) {
                checkboxCol.headerComponent = CheckboxHeaderComponent
                checkboxCol.headerComponentParams = {
                    onToggle: onToggleSelectAll,
                    isHeaderChecked: state.isSelectAllChecked,
                    id: getIdForSelectAllCheckBox()
                }

            } else {
                checkboxCol.headerComponent = EmptyHeaderComponent
            }

            mainHeaderColumns.push(checkboxCol)
        }

        if (props.mainHeaderPrependItems) {
            for (let item of props.mainHeaderPrependItems) {
                const newItem = _.cloneDeep(item)
                newItem.pinned = "left"
                mainHeaderColumns.push(newItem)
            }
        }

        if (props.mainHeaderSectionItems) {
            for (let item of props.mainHeaderSectionItems) {
                mainHeaderColumns.push(_.cloneDeep(item))
            }
        }

        const mainHeader = hasColumnGroups()
            ? [{
                headerName: "",
                children: mainHeaderColumns
            }]
        : mainHeaderColumns

        const columnDefsCalculated: ColDef[] = mainHeader

        if (props.headerData) {
            for (let item of props.headerData) {
                columnDefsCalculated.push(_.cloneDeep(item))
            }
        }

        for (let columnDef of columnDefsCalculated) {
            if (!columnDef["children"]) {
                if (!columnDef.filterParams) {
                    columnDef.filterParams = {}
                }
                columnDef.filterParams.suppressAndOrCondition = true
            } else {
                for (let childDef of columnDef["children"]) {
                    if (!childDef.filterParams) {
                        childDef.filterParams = {}
                    }
                    childDef.filterParams.suppressAndOrCondition = true
                }
            }
        }

        return columnDefsCalculated
    }

    const updateColumns = (gridApi: GridApi, columnApi: ColumnApi) => {

        const newColumnDefs = calculateColumnDefs()
        if (!gridApi) {
            return
        }

        setState({columnDefs: newColumnDefs})

        // In 21.2.2, apparently ag-grid can get confused about column order when changing existing columns, so clear them first.
        gridApi.setColumnDefs([])
        gridApi.setColumnDefs(newColumnDefs)

        // Load grid state from session storage or initial state
        const storedGridState: StoredGridState = retrieveGridState(props.tableId)
        if (storedGridState) {
            const storedColState = storedGridState.colState
            if (storedColState && storedColState.length > 0) {
                columnApi.setColumnState(storedColState)
            }
        } else {
            columnApi.setColumnState(props.sortModel)
        }
        gridApi.redrawRows()
    }


    const updateScrollPositionLabel = ():void => {
        if (!gridApiRef.current) {
             return
        }
        setState({ scrollPositionLabel: scrollPositionLabel(gridApiRef.current) })
    }

    const prevProps: TableProps = usePrevious(props)

    const haveColDefPropsChanged = () => {
        const serializeCols = (propsArg: TableProps): string => {
            if (!propsArg) {
                return ""
            }
            return JSON.stringify(propsArg.tableId) + "_" + JSON.stringify(propsArg.headerData) + "_" +
                JSON.stringify(propsArg.mainHeaderPrependItems) + "_" + JSON.stringify(propsArg.mainHeaderSectionItems)
        }

        return serializeCols(props) !== serializeCols(prevProps)
    }

    // Handling column updates
    useEffect(() => {
        if (haveColDefPropsChanged()) {
            if (gridApiRef.current) {
                updateColumns(gridApiRef.current, columnApiRef.current)
                gridApiRef.current.sizeColumnsToFit()
            }
        }
    })

    // Handling loading spinner
    useEffect(() => {
        if (!gridApiRef.current) {
            return
        }
        if (props.loading) {
            gridApiRef.current.showLoadingOverlay()
        } else {
            gridApiRef.current.hideOverlay()
        }
    // eslint-disable-next-line
    },        [props.loading])

    useEffect(() => {
        setTimeout(updateScrollPositionLabel, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.datasource, props.currentInstrumentType])

    useEffect(()=> {
        // update select all checkbox when data changes
        // (use this only when the grid is configured with "data" not a "datasource")
        if (!props.datasource) {
            initializeSelectAllCheckbox()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.redrawRows()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },        [props.forceRefreshToggle, props.selected])

    useEffect(() => {

        if (state.isGridConfigured) {
            loadGridState(gridApiRef.current, columnApiRef.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tableId])

    useEffect(() => {
        initializeSelectAllCheckbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.viewPortChangeFlag])

    useEffect(() => {
        if (gridApiRef.current) {
            updateColumns(gridApiRef.current, columnApiRef.current)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.highlighted])

    useEffect(() => {
        // This is done via modifying the select all checkbox element directly instead of via updateColumns
        // to avoid losing any column filters and to avoid a server request for reloading the grid.
        // This assumes there won't be two multi-select Grids on the same page with the same tableId.
        const element = document.getElementById(getIdForSelectAllCheckBox())
        if (element) {
            element["checked"] = state.isSelectAllChecked
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isSelectAllChecked])

    const onGridReady = (params: any) => {
        const { api, columnApi } = params
        const { loading } = props
        gridApiRef.current = api
        columnApiRef.current = columnApi
        updateColumns(api, columnApi)

        if (loading) {
            params.api.showLoadingOverlay()
        }
        window.onresize = () => {
            params.api.sizeColumnsToFit()
        }
    }

    const onSortChange = (event) => {

        const {api, columnApi} = event
        const columnState = columnApi.getColumnState()
        if (props.setSortModel) {
            props.setSortModel(columnState)
        }
        storeGridState(api, columnApi, props.tableId)
    }

    const onDragStopped = ( event ) => {
        // used to store changes to width or order of columns
        const {api, columnApi} = event
        storeGridState(api, columnApi, props.tableId)
    }

    const onRowClicked = props.onRowClicked ?
        ( event: RowClickedEvent) => {
            const exludedCols = props.rowClickExcludedColumns || []
            const clickedCol = event.api.getFocusedCell().column.getColId()
            const suppressClick = exludedCols.includes(clickedCol)
            if (!suppressClick) {
                props.onRowClicked(event)
            }
        } :
        undefined

    useImperativeHandle(ref, () => ({
        // Returns selected rows while preserving sort order
        getSortedData() {
            const gridAPI: GridApi = gridApiRef.current
            const sortedData = []
            const selectedIds = props.selected.map( data => data[props.idField])
            gridAPI.forEachNodeAfterFilterAndSort(row => {
                const isSelected = selectedIds.includes(row.data[props.idField])
                if (isSelected) {
                    sortedData.push(row.data)
                }
            })
            return sortedData
        }
    }))

    const toggleSelection = useDynamicCallback((rowData: any) => {

        const { idField, selected} = props
        const selectionHash = createSelectionHash(props.selected)
        const id = rowData[idField]
        const isBeingSelected =  (selectionHash[id] === false)
        let newSelected = [...selected]
        switch (props.selectionMode) {
            case "Multiple":
                if ( isBeingSelected ) {
                    newSelected.push(rowData)
                } else {
                    newSelected = newSelected.filter( item => item[idField] !== rowData[idField])
                }
                break
            case "Single":
                newSelected = isBeingSelected ? [rowData] : []
                break
            default: break
        }

        const newIsAllSelected = isAllSelected(newSelected)
        if (state.isSelectAllChecked !== newIsAllSelected) {
            setState({isSelectAllChecked: newIsAllSelected})
        }

        props.onSelectionChange(newSelected, getNotSelected(newSelected, false))
    })

    const createSelectionHash = (selected: any[]): {[key: string]: boolean} => {
        if (gridApiRef.current) {
            const hash: {[key: string]: boolean} = {}
            gridApiRef.current.forEachNode( node => {
                if (node.data) {
                    const id = node.data[props.idField]
                    hash[id] = false
                }
            })
            for (let item of selected) {
                const id = item[props.idField]
                hash[id] = true
            }
            return hash
        }
        return null
    }

    const getNotSelected = (selected: any[], selectableOnly:  boolean): any[] => {
        const selectionHash = createSelectionHash(selected)
        let notSelected: any[] = []
        for (let item of allData(selectableOnly)) {
            if (item) {
                const id = item[props.idField]
                if (selectionHash[id] === false) {
                    notSelected.push(item)
                }
            }
        }
        return notSelected
    }

    const isAllSelected = (selected: any[]) => {
        if (!selected || selected.length === 0) {
            return false
        }
        if (allData(true).length === 0) {
            return false
        }
        return getNotSelected(selected, true).length === 0
    }

    const onToggleSelectAll = useDynamicCallback(( isChecked: boolean) => {

        setState({isSelectAllChecked: isChecked})

        let all = allData(true)
        if (props.isSelectable) {
            all = allData(true).filter(props.isSelectable)
        }

        const selected = isChecked ? all : []
        const notSelected = isChecked ? [] : all
        props.onSelectionChange(selected, notSelected)
    })

    const isSelected = useDynamicCallback((rowData: any): boolean => {
        const { idField, selected } = props
        return selected.find(
            item => item[idField] === rowData[idField]
        )
        !== undefined
    })

    const allData = (selectableOnly: boolean): any[] => {

        const canBeIncluded = (data: any) => {
            if (!selectableOnly || !props.isSelectable) {
                return true
            }
            return props.isSelectable(data)
        }
        let allData = []
        if ( gridApiRef.current) {
            gridApiRef.current.forEachNode(node => {
                if (node && node.data) {
                    if (canBeIncluded(node.data)) {
                        allData.push(node.data)
                    }
                }
            })
        }
        return allData
    }

    function loadGridState(gridApi: any, columnApi: any) {

        if (!gridApi || !columnApi) {
            return
        }
        updateColumns(gridApi, columnApi)
    }

    function onFirstDataRendered(event) {
        const {api, columnApi} = event
        loadGridState(api, columnApi)
        api.sizeColumnsToFit()
        setState({ isGridConfigured: true })
    }

    function onSearchChanged(text: string) {
        updateScrollPositionLabel()
        if (props.onSearchChanged) {
            props.onSearchChanged(text)
        }
    }

    // Enclosing component should copy the data if it needs to be copied
    let data: any = props.data

    function onViewportChanged() {
        if (allData(false).length > 0) {
            setState({viewPortChangeFlag: !state.viewPortChangeFlag})
        }
    }

    function initializeSelectAllCheckbox() {
        if (props.selectionMode === "Multiple") {
            const checked = isAllSelected(props.selected)
            setState({isSelectAllChecked: checked})
        }
    }

    const frameworkComponents = useMemo( () => {
        let fc = props.frameworkComponents ? props.frameworkComponents : {}
        if (datasource) {
            fc.agColumnHeader = SearchColumnHeader
        }
        return fc
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.frameworkComponents])

    return (
        <Fragment>
            <div
                className="btn-toolbar align-items-center mb-2 mt-3"
                role="toolbar"
                aria-label="toolbar with button groups and buttons"
            >
                {props.sequelSelector && (
                    <InstrumentToggle/>
                )}
                {props.hasCart && props.numSelected !== undefined && (
                    <BadgeButton
                        count={props.numSelected}
                        onClick={props.onCartBtnClicked}
                    />
                )}
                { props.children && props.children }
                { !props.children &&
                    <h2 className="font-weight-bold mb-0 ml-3" style={{ flex: 1 }}>
                        {props.tableTitle}
                    </h2>
                }
                {!props.hideScrollPositionLabel &&
                    <div className="float-left"> {state.scrollPositionLabel} </div>
                }
                {!props.disableSearch && (
                    <Search
                        id={props.searchId}
                        placeholder={props.searchBarPlaceholder || "Search ..."}
                        gridRef={gridApiRef.current}
                        onSearchChange={onSearchChanged}
                        isLocalSearch={!datasource}
                    />
                )}
            </div>
            <div
                className="ag-theme-alpine"
                style={{ height: props.gridHeight || "90%", width: "100%" }}
            >
                <AgGridReact
                    icons={{sortUnSort: "⇵"}}
                    frameworkComponents={frameworkComponents}
                    paginationPageSize={defaultPageSize}
                    defaultColDef={defaultColumnDef}
                    columnDefs = {state.columnDefs}
                    rowData={datasource ? null : data}
                    gridOptions={{
                        unSortIcon: true,
                        rowModelType: (datasource ? "infinite" : undefined),
                        cacheBlockSize: GRID_INFINITE_SCROLLING_PAGE_SIZE
                    }}
                    getRowNodeId = { data => data[idFieldRef.current] }
                    datasource={datasource}
                    suppressRowClickSelection={true}
                    paginationAutoPageSize={true}
                    pagination={false}
                    overlayLoadingTemplate={
                        '<span class="ag-overlay-loading-center">Loading...</span>'
                      }
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    onFilterChanged={(event) => {
                        storeGridState(event.api, event.columnApi, props.tableId)}
                    }
                    onComponentStateChanged = { onComponentStateChanged }
                    onViewportChanged = {onViewportChanged}
                    onSortChanged={onSortChange}
                    onBodyScroll={updateScrollPositionLabel}
                    rowBuffer={0} // required for scroll calculation and performance hit is negligible
                    onDragStopped={onDragStopped}
                    scrollbarWidth={10}
                    getRowClass = {params =>
                        getRowClass(params.data)
                    }
                    overlayNoRowsTemplate = {props.overlayNoRowsTemplate}
                    domLayout = {props.domLayout}
                    context = {props.context}
                    suppressCellSelection = { props.suppressCellSelection}
                    getRowStyle = {props.getRowStyle}
                    fullWidthCellRenderer = { props.fullWidthCellRenderer}
                    isFullWidthCell = { props.isFullWidthCell}
                    getRowHeight = { props.getRowHeight}
                    alwaysShowHorizontalScroll={props.alwaysShowHorizontalScroll}
                    onRowClicked = { onRowClicked }
                    tooltipShowDelay = {0}
                    applyColumnDefOrder = {true} // Deprecated in ag-grid 26.  remove after we update.
                />

                <div ref={stabilityElementRef} style={{display: "none"}}> Stability element </div>

                <div
                    style={{display: "none"}}
                    className = { props.loading ? `${props.tableId}_isLoading` : `${props.tableId}_notLoading`}
                >
                    Loading element
                </div>

            </div>
        </Fragment>
    )
})
