import { Reducer } from "redux"
import { UsersActionTypes, UsersApiState } from "./users-api-types"

const initialState: UsersApiState = {
    loadingUsers: false,
    users: [],
    loadingUsersError: null,
    savingUser: false,
    selectedUser: null,
    savingUserError: null
}

export const usersReducer: Reducer<UsersApiState> = (state: UsersApiState = initialState, action: any): UsersApiState => {
    switch (action.type) {
        case UsersActionTypes.GET_USER_LIST_ATTEMPT:
            return Object.assign({}, state, {
                loadingUsers: true,
                users: [],
                loadingUsersError: null,
                selectedUser: null,
                savingUserError: null
            })

        case UsersActionTypes.GET_USER_LIST_FAILURE:
            return Object.assign({}, state, {
                loadingUsers: false,
                loadingUsersError: action.payload
            })

        case UsersActionTypes.GET_USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                loadingUsers: false,
                users: action.payload
            })

        case UsersActionTypes.SAVE_USER_ATTEMPT:
            return Object.assign({}, state, {
                savingUser: true,
                savingUserError: null
            })

        case UsersActionTypes.SAVE_USER_FAILURE:
            return Object.assign({}, state, {
                savingUser: false,
                savingUserError: action.payload
            })

        case UsersActionTypes.SAVE_USER_SUCCESS: {
            const selectedUser = action.payload
            // Replace the one changed user
            const users = state.users.map(user => user.userId === selectedUser.userId ? selectedUser : user)
            return Object.assign({}, state, {
                savingUser: false,
                users: users,
                selectedUser: selectedUser
            })
        }

        case UsersActionTypes.SET_SELECTED_USER:
            return Object.assign({}, state, {
                selectedUser: action.payload
            })

        default:
            return state
    }
}
