import { Reducer } from "redux"
import { RunQCActionTypes, RunQCApiState } from "./run-qc-api-types"

const initialState: RunQCApiState = {
    loading: false,
    data: [],
    error: null,
    downloading: false
}

export const runQCReducer: Reducer<RunQCApiState> = (state: RunQCApiState = initialState, action: any) => {
    switch (action.type) {
        case RunQCActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case RunQCActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case RunQCActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case RunQCActionTypes.GET_RUN_SUCCESS: {
            const data = [...state.data]
            const oldRun = data.find(run => run.uniqueId === action.payload.uniqueId)

            if (oldRun) {
                Object.assign(oldRun, action.payload)
            } else {
                data.push(action.payload)
            }

            return Object.assign({}, state, {
                loading: false,
                data
            })
        }
        case RunQCActionTypes.GET_RUNS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload
            })

        case RunQCActionTypes.SET_DOWNLOADING:
            return Object.assign({}, state, {
                downloading: action.payload
            })
        default:
            return state
    }
}
