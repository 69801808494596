import { Reducer } from "redux"
import { UserPreferencesActionTypes, UserPreferencesState } from "./user-preferences-types"

const initialState: UserPreferencesState = {
    userPreferences: null,
    isSaving: false,
    saveError: null
}

export const userPreferencesReducer: Reducer<UserPreferencesState> =
    (state: UserPreferencesState = initialState, action: any): UserPreferencesState => {
    switch (action.type) {
        case UserPreferencesActionTypes.SET_USER_PREFERENCES:
            return Object.assign({}, state, {
                userPreferences: action.payload
            })
        case UserPreferencesActionTypes.SAVE_USER_PREFERENCES_ATTEMPT:
            return Object.assign({}, state, {
                isSaving: true,
                saveError: null
            })

        case UserPreferencesActionTypes.SAVE_USER_PREFERENCES_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                saveError: action.payload
            })

        case UserPreferencesActionTypes.SAVE_USER_PREFERENCES_SUCCESS: {
            return Object.assign({}, state, {
                isSaving: false,
                saveError: null,
                userPreferences: action.payload
            })
        }
        default:
            return state
    }
}
