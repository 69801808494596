
import { Reducer } from "redux"
import { RulesState, RulesActionTypes } from "./rules-types"

const initialState: RulesState = {
    reportViewRules: [],
    reportFormatMap: {},
    pipelineTemplateMap: {},
    pipelineTemplates: [],
    pipelineViewRules: [],
    workflowPresets: []
}

export const rulesReducer: Reducer<RulesState> = (state: RulesState = initialState, action: any) => {
    switch (action.type) {
        case RulesActionTypes.GET_REPORT_VIEW_RULES:
            return Object.assign({},state,{reportViewRules: action.payload})
        case RulesActionTypes.GET_REPORT_FORMAT_MAP:
            return Object.assign({},state,{reportFormatMap: action.payload})
        case RulesActionTypes.GET_PIPELINE_TEMPLATE_MAP:
            return Object.assign({},state,{pipelineTemplateMap: action.payload})
        case RulesActionTypes.GET_PIPELINE_TEMPLATES:
            return Object.assign({},state,{pipelineTemplates: action.payload})
        case RulesActionTypes.GET_PIPELINE_VIEW_RULES:
            return Object.assign({},state,{pipelineViewRules: action.payload})
        case RulesActionTypes.GET_WORKFLOW_PRESETS:
            return Object.assign({},state,{workflowPresets: action.payload})
        default:
            return state
    }
}

