import { InstrumentType, isSequel } from "../../../data/model/instrument-type-model"
import {TableUtils} from "../../sample-setup/utils/binding-calculator/table-utils"

const booleanMap = {"YES": true, "NO": false}

export const calculateRecommendPreextensionTime = (isIsoseq: boolean, Insert_Size: number, constants: any, application: string) => {


    if (isIsoseq === undefined || isIsoseq === null || isNaN(Insert_Size)) { return "" }

    const shouldUsePreextension =
        Insert_Size <= constants.preExtensionRecommendedUpperLimit &&
        Insert_Size >= constants.preExtensionRecommendedLowerLimit

    if (application === "custom" && !shouldUsePreextension) {
        return 0
    }

    if (isIsoseq) {
        return constants.preExtensionRecommendedForIsoseq
    }
    if (application === "variantDetection" || application === "structuralVariationDetection") {
        // TODO: mcantor (10.13.20) To get Otter out the door encoding this in the code.  This logic should be moved
        // into the chemistry bundle somehow eventually. (SL-6798)
        return Insert_Size < 20000 ? 2*60 : 4*60

    }
    const preExtensionSinglePassLength = 2 * (constants.preExtensionAdapterLength + Insert_Size)
    const result = TableUtils.ceiling(
        TableUtils.round(preExtensionSinglePassLength / (60 * constants.preExtensionPolymeraseRate), 4),
        1
    )
    const roundToMultipleOfFiveResult = Math.ceil(result / 5) * 5
    return roundToMultipleOfFiveResult
}

export const getRecommendedPreextensionTime = (
    insertSize,
    isoSeqExperiment,
    systemName: InstrumentType,
    appConfig,
    application: string
): string => {
    const params = (isSequel(systemName)) ? appConfig.Sequel : appConfig.Sequel2
        const isIsoseq = booleanMap[isoSeqExperiment]
        const Insert_Size = insertSize
        const recommendedPreextensionTimeMin = calculateRecommendPreextensionTime(
            isIsoseq,
            parseInt(Insert_Size, 10),
            params,
            application
        )

        if (recommendedPreextensionTimeMin === 0) {
            return "0"
        } else if (recommendedPreextensionTimeMin === "") {
            return ""
        } else {
            let recommendedPreextensionTimeHrsFormatted
            let recommendedPreextensionTimeHrs = recommendedPreextensionTimeMin / 60
            let recommendedPreextensionTimeHrsTruncated = Number(recommendedPreextensionTimeHrs.toFixed(1))
            if (recommendedPreextensionTimeHrs - recommendedPreextensionTimeHrsTruncated > 0) {
                recommendedPreextensionTimeHrsFormatted = recommendedPreextensionTimeHrsTruncated + 0.1
            } else {
                recommendedPreextensionTimeHrsFormatted = recommendedPreextensionTimeHrsTruncated
            }
            return "" + recommendedPreextensionTimeHrsFormatted.toFixed(1)
        }
}
