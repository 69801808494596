import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react"
import ReactDOM from "react-dom";

export class ExpandedNotificationRenderer extends Component<ICellRendererParams> {
      
    componentDidMount() {
        // to allow for inner scrolling
        ReactDOM.findDOMNode(this)!.addEventListener(
            "mousewheel",
            (event) => {
                event.stopPropagation();
            },
        false);
    }
  
    getReactContainerStyle() {
        return {
            display: "inline-block",
            height: "100%",
            width: "100%",
        };
    }
    render() {
        const { context, data } = this.props
        return (
            <div className="pb-full-width-notification-message" onClick={() => context.toggleExpandedRow(data)}>
                {data.message}
            </div>
        );
    }
  }
