import { Reducer } from "redux"
import { MultiJobPollingState, MultiJobPollingTypes } from "./multijob-polling-types"

const initialState: MultiJobPollingState = {
    multiJobJobs: [],
    isPolling: false,
    isCancelled: false,
    error: null
}

export const multiJobPollingReducer: Reducer<MultiJobPollingState> =
    (state: MultiJobPollingState = initialState, action: any) => {
        switch (action.type) {
            case MultiJobPollingTypes.SET_MULTIJOB_JOBS:
                return Object.assign({}, state, {
                    multiJobJobs: action.payload
                })
            case MultiJobPollingTypes.START_POLLING:
                return Object.assign({}, state, {
                    isPolling: true
                })
            case MultiJobPollingTypes.STOP_POLLING:
                return Object.assign({}, state, {
                    isPolling: false
                })
            case MultiJobPollingTypes.SET_IS_CANCELLED:
                return Object.assign({}, state, {
                    isCancelled: action.payload
                })
            default:
                return state
        }
}
