import { Reducer } from "redux"
import { InstrumentTableState, InstrumentTableTypes } from "./instrument-table-types"

const initialState: InstrumentTableState = {
    loading: false,
    data: [],
    error: null,
    lastUpdate: null,
    isPollingBlocked: false
}

export const instrumentTableReducer: Reducer<InstrumentTableState> =
    (state: InstrumentTableState = initialState, action: any) => {

        switch (action.type) {
        case InstrumentTableTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })
        case InstrumentTableTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload,
                lastUpdate: new Date()
            })
        case InstrumentTableTypes.API_SUCCESS: {
            return Object.assign({}, state, {
                loading: false,
                error: null,
                data: action.payload,
                lastUpdate: new Date()
            })
        }
        case InstrumentTableTypes.SET_IS_POLLING_BLOCKED: {
            return Object.assign({}, state, {
                isPollingBlocked: action.payload
            })
        }

        default:
            return state
    }
}
