import { IAppConfig } from "./app-config.interface"

export const appConfig: IAppConfig = {
    "translations": {
        "basePath": "app/translations",
        "supportedLanguages": [
            {
                "languageCode": "es",
                "defaultCountryCode": "es"
            },
            {
                "languageCode": "en",
                "defaultCountryCode": "us",
                "isFallbackLanguage": true
            }
        ]
    },

    "isMultiSystem": true,

    "Sequel": {
        "maxSingleUseCells": 12,
        "importRunMovieHoursLimit": 20,
        "preExtensionAdapterLength": 50,
        "preExtensionPolymeraseRate": 1.5,
        "preExtensionRecommendedUpperLimit": 2000,
        "preExtensionRecommendedLowerLimit": 600,
        "preExtensionRecommendedForIsoseq": 240
    },
    "Sequel2": {
        "maxSingleUseCells": 8,
        "importRunMovieHoursLimit": 30,
        "preExtensionAdapterLength": 50,
        "preExtensionPolymeraseRate": 3,
        "preExtensionRecommendedUpperLimit": 2000,
        "preExtensionRecommendedLowerLimit": 600,
        "preExtensionRecommendedForIsoseq": 120
    },
    "movieTimeLowerLimit": 0.5,

    "isDevModeEnabled": false,
    "isInternalModeEnabled": false,
    "isAlphaModeEnabled": false,

    "isReuseSampleEnabled": false,
    "enableCellReuse": false,
    "maxCollectionsPerCell": 1,
    "maxExtendedUseCells": 4,

    "maxUploadableFileSize": 100000000,
    "systemMessage": "",
    "fileImportPath": "/",
    "silos": [
        "welcome",
        "sample-setup",
        "run-design",
        "run-qc",
        "data-management",
        "analysis"
    ],

    "preExtensionTimeHoursMaxLimit": 12,
    "preExtensionTimeHoursMinLimit": 0,
    "maxDatasetsForMergeBatchAnalysis": 1024,
    "maxDatasetsForCustomBatchAnalysis": 384,
    "maxDatasetsForMultijobBatchAnalysis": 1000000,

    "minLoadingTarget": 0.01,
    "maxLoadingTarget": 1,
    "minMaxLoadingTime": 1,
    "maxMaxLoadingTime": 4,
    "defaultMaxLoadingTime": 2,
    "defaultLoadingTarget": 0.85,
    "maxJobsPerBatch": 200,

    "canSubmitJobs": true,
    "enableSubreads": false
}
