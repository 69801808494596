import { RunSummary } from "../../model/run-design-model"

export const RunQCActionTypes = {
  API_ATTEMPT: "@@run-qc/API_ATTEMPT",
  API_SUCCESS: "@@run-qc/API_SUCCESS",
  API_FAILURE: "@@run-qc/API_FAILURE",

  GET_RUNS_SUCCESS: "@@run-qc/GET_RUNS_SUCCESS",
  GET_RUN_SUCCESS: "@@run-qc/GET_RUN_SUCCESS",

  DOWNLOAD_CSV: "@@run-qc/DOWNLOAD_CSV",
  SET_DOWNLOADING: "@@run-qc/SET_DOWNLOADING"
}

export interface RunQCApiState {
  readonly loading: boolean
  readonly downloading: boolean
  readonly data: RunSummary[]
  readonly error: Error | null
}

