import { Reducer } from "redux"
import { TemplateFilesActionTypes, TemplateFilesState } from "./template-files-types"

const initialState: TemplateFilesState = {
    files: [],
    loading: false,
    error: null
}

export const templateFilesReducer: Reducer<TemplateFilesState> = (
    state: TemplateFilesState = initialState,
    action: any
) => {
    switch (action.type) {
        case TemplateFilesActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true,
                error: null
            })

        case TemplateFilesActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                error: null
            })

        case TemplateFilesActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case TemplateFilesActionTypes.SET_FILES:
            return Object.assign({}, state, {
                loading: false,
                files: action.payload
            })
        default:
            return state
    }
}
