import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap"

const BODY_THEME_CLASS = "btheme-base"

interface Props {
    error: any
    errorInfo: any
}

export class ErrorLayout extends Component<Props> {

    componentDidMount() {
        document.body.classList.add(BODY_THEME_CLASS)
    }

    componentWillUnmount() {
        document.body.classList.remove(BODY_THEME_CLASS)
    }

    reloadApp() {
        window.pb_error_state = false
        window.location.href = process.env.PUBLIC_URL || (window.location.origin + "/")
    }

    getDetails() {
        if (this.props.error && this.props.error.data && this.props.error.data.message) {
            return this.props.error.data.message.toString()
        }
        if (this.props.error) {
            return this.props.error.toString()
        }
        return null
    }

    render() {
        return (
        <Container className="container-main bg-white" fluid>
            <div className="inner-container">
                <Row>
                    <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                        <h1 className="display-4 font-weight-bold mt-5">We're Sorry&#8230;</h1>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                        <h2 className="font-weight-bold">
                            Something went wrong.
                        </h2>
                        <br></br>
                        <p className="lead">
                            <Button color="primary" onClick={() => this.reloadApp()}>Go to SMRT Link Welcome page</Button>
                            <br></br>
                            <br></br>
                            You can also contact us for help.
                        </p>
                        {this.props.error &&
                        <details style={{ whiteSpace: "pre-wrap" }} open={true}>
                            {this.getDetails()}
                            <br />
                            {this.props.errorInfo && this.props.errorInfo.componentStack}
                        </details> }
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={{size:10, offset: 1}} md={{size:8, offset: 2}}>
                        <div className="mt-2">
                            <p className="text-muted text-uppercase font-weight-sbold">Error Code: App1.
                                {/* <small>SMRT® Link version 8.TODO</small> */}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
        )
    }
}
