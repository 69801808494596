import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../core/types"
import { User } from "../../model/user-model"
import { fetchUserPreferences } from "./user-preferences-actions"
import { UserPreferences } from "../../model/user-preferences-model"

export const useUserPreferences = () => {

    const user = useSelector<ApplicationState, User>( state => state.auth.user )
    const userPreferences = useSelector<ApplicationState, UserPreferences>( state => state.userPreferences.userPreferences)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user) {
            dispatch(fetchUserPreferences(user))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return userPreferences
}
