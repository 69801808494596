import { Reducer } from "redux"
import { InstrumentSettingsActionTypes, InstrumentSettingsState } from "./instrument-settings-types"

const initialState: InstrumentSettingsState = {
    instrumentConfigs: null,
    fileTransferLocations: null,
    loadingInstrumentConfigs: false,
    loadingLocations: false,
    errorInstruments: null,
    errorLocations: null,
}

export const instrumentSettingsReducer: Reducer<InstrumentSettingsState> = (
    state: InstrumentSettingsState = initialState,
    action: any
) => {
    switch (action.type) {

        // --- instrumentConfigs cases
        case InstrumentSettingsActionTypes.API_ATTEMPT_INSTRUMENTS:
            return Object.assign({}, state, {
                loadingInstrumentConfigs: true,
                errorInstruments: null
            })

        case InstrumentSettingsActionTypes.API_SUCCESS_INSTRUMENTS:
            return Object.assign({}, state, {
                loadingInstrumentConfigs: false
            })

        case InstrumentSettingsActionTypes.API_FAILURE_INSTRUMENTS:
            return Object.assign({}, state, {
                loadingInstrumentConfigs: false,
                errorInstruments: action.payload
            })

        case InstrumentSettingsActionTypes.SET_INSTRUMENTS:
            return Object.assign({}, state, {
                loadingInstrumentConfigs: false,
                instrumentConfigs: action.payload
            })

        // --- locations cases
        case InstrumentSettingsActionTypes.API_ATTEMPT_LOCATIONS:
            return Object.assign({}, state, {
                loadingLocations: true,
                errorLocations: null
            })

        case InstrumentSettingsActionTypes.API_SUCCESS_LOCATIONS:
            return Object.assign({}, state, {
                loadingLocations: false
            })

        case InstrumentSettingsActionTypes.API_FAILURE_LOCATIONS:
            return Object.assign({}, state, {
                loadingLocations: false,
                errorLocations: action.payload
            })

        case InstrumentSettingsActionTypes.SET_LOCATIONS:
            return Object.assign({}, state, {
                loadingLocations: false,
                fileTransferLocations: action.payload
            })


        default:
            return state
    }
}
