import { InstrumentType, isRevio } from "../../../data/model/instrument-type-model"
import {maxSingleUseCells, oldPartNumbers} from "../constants/constants"
import {getPairs} from "./helpers"
import { RunDurationEstimator } from "./run-duration-estimator"

export const isTooManyNRxnSamples = (runDesignForm, system: InstrumentType, partsService): string[] | null => {
    if (partsService === null) {return null}
    if (!isRevio(system) && runDesignForm.samples.length > maxSingleUseCells[system]) {
        return ["Error: Too many samples specified. Limit is"+ maxSingleUseCells[system] +"."]
    }
    let allKits = getAllSequencingKits(runDesignForm, partsService)
    if (Object.keys(allKits).length > 2) {
        return ["Error: Too many Sequencing Kit specified. Limit is 2."]
    }
    let errors = []
    Object.keys(allKits).forEach(sequencingKit => {
        let kit = partsService.sequencingKitFromBarcode(sequencingKit)
        if (kit) {
            let max = Object.keys(allKits).length === 2 ? kit.maxCollections : kit.maxCollections * 2
            let count = 0
            runDesignForm.samples.forEach((sample) => {
                if (sample.sequencingKit === sequencingKit) {
                    count += 1
                }
            })
            if (count > max) {
                errors.push(["Error: Too many samples specified for "+ kit.name +"."])
            }
        }
    })
    return errors.length > 0 ? errors : null
}

export const getAllSequencingKits = (runDesignForm, partsService): any => {
    if (partsService === null) { return 0 }
    let kits = {}
    runDesignForm.samples.forEach((sample) => {
        if (sample.sequencingKit) {
            let kit = partsService.sequencingKitFromBarcode(sample.sequencingKit)
            kits[sample.sequencingKit] = kit
        }
    })
    return kits
}

export const num4RxnPlates = (runDesignForm, partsService): number => {
    if (partsService === null) { return 0 }
    let count = 0
    runDesignForm.samples.forEach((sample) => {
        let kit = partsService.sequencingKitFromBarcode(sample.sequencingKit)
        if (kit && kit.maxCollections === 4) {
            count += 1
        }
    })
    return count
}

export const num8RxnPlates = (runDesignForm, partsService): number => {
    if (partsService === null) { return 0 }
    let count = 0
    runDesignForm.samples.forEach((sample) => {
        let kit = partsService.sequencingKitFromBarcode(sample.sequencingKit)
        if (kit && kit.maxCollections === 8) {
            count += 1
        }
    })
    return count
}

export const hasOldSequencingPlates = (runDesignForm, partsService) => {
    if (partsService === null) { return false }
    let hasOld = false
    runDesignForm.samples.forEach((sample) => {
        const kit = partsService.sequencingKitFromBarcode(sample.sequencingKit)
        if (kit && (oldPartNumbers.indexOf(kit.partNumber) > -1)) {
            hasOld = true
        }
    })
    return hasOld
}

export const isTooLongRun = (runDesignForm, partsService, system) => {

    const runDurationEstimate = RunDurationEstimator.calculateEstimatedRunDuration_min(runDesignForm, system)
    const maxTotalRunTimeAllowed = RunDurationEstimator.maxTotalRunTimeAllowed_min / 60

    if (partsService && hasOldSequencingPlates(runDesignForm, partsService)) {
        return runDurationEstimate > maxTotalRunTimeAllowed
    } else {
        return false
    }
}

export const partsAreCompatible = (runDesignForm, partsService) => {
    let compatibleParts = true
    if (partsService === null) { return compatibleParts}
    runDesignForm.samples.forEach(sample => {
        let kits = [sample.templatePrepKit,
                    sample.bindingKit,
                    sample.sequencingKit,
                    sample.controlKit]
        let pairs = getPairs(kits)
        pairs.forEach(pair => {
            let kitA = partsService.fromBarcode(pair[0])
            let kitB = partsService.fromBarcode(pair[1])
            let compatible = partsService.areCompatible(kitA, kitB)
            if (!compatible) {compatibleParts = false}
        })
    })
    return compatibleParts
}

export const partsAreValid = (runDesignForm, partLookupService) => {
    let validParts = true
    if (partLookupService === null) { return validParts }
    runDesignForm.samples.forEach(sample => {
        let kits = [sample.templatePrepKit,
        sample.bindingKit,
        sample.sequencingKit,
        sample.controlKit]
        kits.forEach(kit => {
            let label = partLookupService.getLabel(kit)
            if (label === "Invalid Barcode") { validParts = false }
        })
    })
    return validParts
}
