import { Reducer } from "redux"
import { RunSelectorState, RunSelectorActionTypes } from "./run-selector-types"
import { createRunSelectionMap } from "../../../core-components/shared/RunSelector/run-selector-table-state"

const initialState: RunSelectorState = {
    loading: false,
    data: null,
    error: null,
    runSelectionMap: createRunSelectionMap()
}

export const runSelectorReducer: Reducer<RunSelectorState> = (state: RunSelectorState = initialState, action: any) => {
    switch (action.type) {
        case RunSelectorActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                data: null,
                loading: true
            })
        case RunSelectorActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case RunSelectorActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case RunSelectorActionTypes.GET_RUN_DATA_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload
            })
        case RunSelectorActionTypes.SET_SELECTOR_TYPE:
            return Object.assign({}, state, {
                selectorType: action.payload
            })
        case RunSelectorActionTypes.SET_SELECTION_MAP:
            return Object.assign({}, state, {
                runSelectionMap: action.payload
            })
        default:
            return state
    }
}
