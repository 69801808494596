export interface IdAble {
    id: number
}

export interface UuidAble {
    uuid: string
}
export interface BaseDataset extends IdAble, UuidAble {
    name?: string
    path?: string
    tags?: string
    totalLength?: number
    projectId?: number
    numRecords?: number
    version?: string
    md5?: string
    jobId?: number
    isActive?: boolean
    createdBy?: string
    comments?: string
    parentUuid?: string
    parentName?: string
    numChildren?: number
    wellSampleName?: string
    wellName?: string
    bioSampleName?: string
    runName?: string
    cellIndex?: number
    cellId?: string
    metadataContextId?: string
    instrumentName?: string
    dnaBarcodeName?: string
}

export interface Dataset extends BaseDataset {
    updatedAt?: string
    createdAt?: string
    importedAt?: string
    datasetType?: string
}

export const MISSING = -1

export const MISSING_DATASET: Dataset = {
    id:MISSING,
    uuid: ""
}
export const IS_MISSING = (dataset: Dataset): boolean => {
    return dataset.id === -1
}
