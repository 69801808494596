import { Dataset } from "../../model/dataset-model"

export type EntryPointType = "barcodes" | "references" | "bedfiles"

export const EntryPointsActionTypes = {
  API_ATTEMPT: "@@entryPoints/API_ATTEMPT",
  API_FAILURE: "@@entryPoints/API_FAILURE",
  SET_BARCODE_SETS: "@@entryPoints/SET_BARCODE_SETS",
  SET_REFERENCE_SETS:  "@@entryPoints/SET_REFERENCE_SETS",
  SET_BED_SETS: "@@entryPoints/SET_BED_SETS"
}

export interface EntryPointsState {
  readonly loading: boolean
  readonly barcodeSets: Dataset[]
  readonly referenceSets: Dataset[]
  readonly bedSets: Dataset[]
  readonly error: Error | null
}

