import { Reducer } from "redux"
import { InstrumentActionTypes, InstrumentState } from "./instrument-types"

const initialState: InstrumentState = {
    instrumentSupport: {
        supportsSequel: false,
        supportsSequel2: true,
        supportsSequel2e: true,
        supportsRevio: true
    },
    currentInstrumentType: "Revio"
}

export const instrumentReducer: Reducer<InstrumentState> = (state: InstrumentState = initialState, action: any) => {
    switch (action.type) {
        case InstrumentActionTypes.UPDATE_INSTRUMENT_SUPPORT:
            return Object.assign({}, state, { instrumentSupport: action.payload} )
        case InstrumentActionTypes.UPDATE_CURRENT_INSTRUMENT_TYPE:
            return Object.assign({}, state, { currentInstrumentType: action.payload} )
        default:
            return state
    }
}
