import * as _ from "lodash"

export const mergeArraysByProperty =  (arr1: any[], arr2: any[], property: string): any[] => {
    if (arr1.length === 0) {
        return arr2
    }
    if (arr2.length === 0) {
        return arr1
    }
    const ids1 = arr1.map( item => item[property])
    const arr2complement = arr2.filter( item => ! ids1.includes(item[property]))
    return [...arr1, ...arr2complement]
}

export const isSameArrayForProperty = (arr1: any[], arr2: any[], property: string):boolean => {
    if (!arr1 || !arr2) {
        return false
    }
    if (arr1.length !== arr2.length) {
        return false
    }
    for(let i = 0; i < arr1.length ;i++) {
        if (arr1[i][property] !== arr2[i][property]) {
            return false
        }
     }
    return true
}

export const isSameArrayForPropertyIgnoringOrder = (arr1: any[], arr2: any[], property: string):boolean => {
    if (!arr1 || !arr2) {
        return false
    }
    if (arr1.length !== arr2.length) {
        return false
    }
    let vals1 = arr1.map( item => item[property]).sort()
    let vals2 = arr2.map( item => item[property]).sort()

    for(let i = 0; i < arr1.length ;i++) {
        if (vals1[i] !== vals2[i]) {
            return false
        }
     }
    return true
}

export const partitionArray = (items: any[], size: number): any[][] => {
    return _.chunk(items,size)
}

export const compare = (attribute) => {
    return (a, b) => {
        if (a[attribute] < b[attribute]) {
            return -1
        }
        if (a[attribute] > b[attribute]) {
            return 1
        }
        return 0
    }
}
