import { Reducer } from "redux"
import { RunDesignActionTypes, RunDesignApiState } from "./run-design-api-types"

const initialState: RunDesignApiState = {
    loading: false,
    designs: [],
    error: null
}

export const runDesignReducer: Reducer<RunDesignApiState> = (state: RunDesignApiState = initialState, action: any) => {
    switch (action.type) {
        case RunDesignActionTypes.API_ATTEMPT:
            return Object.assign({}, state, {
                loading: true
            })

        case RunDesignActionTypes.API_SUCCESS:
            return Object.assign({}, state, {
                loading: false
            })

        case RunDesignActionTypes.API_FAILURE:
            return Object.assign({}, state, {
                loading: false,
                error: action.payload
            })

        case RunDesignActionTypes.GET_DESIGN_SUCCESS: {
            const designs = [...state.designs]
            const oldDesign = designs.find(design => design.uniqueId === action.payload.uniqueId)

            if (oldDesign) {
                Object.assign(oldDesign, action.payload)
            } else {
                designs.push(action.payload)
            }

            return Object.assign({}, state, {
                loading: false,
                designs
            })
        }
        case RunDesignActionTypes.GET_DESIGNS_SUCCESS:
            return Object.assign({}, state, {
                loading: false,
                designs: action.payload
            })

        default:
            return state
    }
}
