import { Reducer } from "redux"
import { AnalysisSelectorState, AnalysisSelectorTypes } from "./analysis-selector-types"
import { SelectionMap } from "../../../../core-components/shared/DrillDownTable/drill-down-types"


const initialState: AnalysisSelectorState = {
    selectionMap: SelectionMap.fromField("id"),
    isFlatMode: false
}

export const analysisSelectorReducer: Reducer<AnalysisSelectorState> =
    (state: AnalysisSelectorState = initialState, action: any) => {
        switch (action.type) {
            case AnalysisSelectorTypes.SET_SELECTOR_TYPE:
                return Object.assign({}, state, {
                    selectorType: action.payload
                })
            case AnalysisSelectorTypes.SET_SELECTION_MAP:
                return Object.assign({}, state, {
                    selectionMap: action.payload
                })
            case AnalysisSelectorTypes.SET_IS_FLAT_MODE:
                    return Object.assign({}, state, {
                        isFlatMode: action.payload
                    })
            default:
                return state
        }
}
