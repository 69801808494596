import { ActionCreator, Action } from "redux"
import { ThunkResult, ApplicationState } from "../../../core/types"
import { ThunkDispatch } from "redux-thunk"

import * as API from "../../api"
import { AlarmActionTypes } from "./alarm-api-types"
import { AlarmModel } from "../../model/alarm-model"

export const getAlarms = (): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
    dispatch(apiAttempt())
    try {
        const { data: alarms } = await API.getAlarms()
        dispatch(setAlarms(alarms))
    } catch (err) {
        dispatch(apiFailure(err))
    }
}

export const clearAlarms = (alarms: AlarmModel[]): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>
) => {
    const cleardIds = alarms.map(alarm => alarm.id)
    dispatch(setClearedAlarmIds(cleardIds))
}

export const clearAllAlarms = (): ThunkResult<Promise<void>> => async (
    dispatch: ThunkDispatch<ApplicationState, undefined, Action>,
    getState: () => ApplicationState
) => {
    const { alarms } = getState().alarm
    dispatch(clearAlarms(alarms))
}

export const setAlarms: ActionCreator<Action> = (alarms: AlarmModel[]) => ({
    type: AlarmActionTypes.SET_ALARMS,
    payload: alarms
})

export const setClearedAlarmIds: ActionCreator<Action> = (ids: string[]) => ({
    type: AlarmActionTypes.SET_CLEARED_ALARM_IDS,
    payload: ids
})

export const apiAttempt: ActionCreator<Action> = () => ({
    type: AlarmActionTypes.API_ATTEMPT
})

export const apiFailure: ActionCreator<Action> = (error) => ({
    type: AlarmActionTypes.API_FAILURE,
    payload: error
})

