import { UiDatasetDetails } from "../../model/ui-dataset-model"
import { JobDetails } from "../../model/ui-job-model"

export const DatasetDetailsActionTypes = {
    SET_IS_VALID_DATASET: "@@dataset-details/SET_IS_VALID_DATASET",

    API_ATTEMPT: "@@dataset-details/API_ATTEMPT",
    API_SUCCESS: "@@dataset-details/API_SUCCESS",
    API_FAILURE: "@@dataset-details/API_FAILURE",

    GET_ALL_DETAILS_SUCCESS: "@@dataset-details/GET_DATASET_DETAILS_SUCCESS"
}

export interface DatasetDetailsState {
  readonly loading: boolean
  readonly isValidDataset: boolean | null
  readonly details: UiDatasetDetails
  readonly jobDetails: JobDetails
  readonly error: Error | null
}
