import { TemplateFile } from "../../model/template-file"

export const TemplateFilesActionTypes = {
  API_ATTEMPT: "@@template-files/API_ATTEMPT",
  API_SUCCESS: "@@template-files/API_SUCCESS",
  API_FAILURE: "@@template-files/API_FAILURE",

  SET_FILES: "@@template-files/SET_FILES",
}

export interface TemplateFilesState {
  readonly files: TemplateFile[]
  readonly loading: boolean
  readonly error: Error | null
}
