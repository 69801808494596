import React, { FunctionComponent } from "react"
import { Silo } from "./navbar/ModuleNavMenu"

interface Props {
  silo: Silo
  isMagenta: boolean
  size?: "large" | "small" // default is large
 }

const getSvg = (props: Props) => {
  const {silo, isMagenta} = props
  const dir = `${process.env.PUBLIC_URL}/img`
  switch (silo) {
    case "Runs": return isMagenta ? `${dir}/icn_run_qc_magenta.svg` : `${dir}/icn_run_qc.svg`;
    case "Data Management": return isMagenta ? `${dir}/icn_data_management_magenta.svg` : `${dir}/icn_data_management.svg`;
    case "Analysis": return isMagenta ? `${dir}/icn_smrt_analysis_magenta.svg` : `${dir}/icn_smrt_analysis.svg`;
    case "Sample Setup": return isMagenta ? `${dir}/icn_sample_setup_magenta.svg` : `${dir}/icn_sample_setup.svg`;
    case "Instruments": return isMagenta ? `${dir}/icn_instruments_magenta.svg` : `${dir}/icn_instruments.svg`;

    default: throw new Error("missing case for silo")
  }
}


export const SiloIcon: FunctionComponent<Props> = (props: Props) => {

    const { silo, size } = props

    return (
      <div className="svg-icon-wrapper">
        <img
            src={ getSvg(props) }
            width= { size === "small" ? "30px" : "50px" }
            height={ size === "small" ? "30px" : "50px" }
            alt={`Silo icon for ${silo}`}
        />
      </div>
    );
}
