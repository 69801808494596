import { RunSummary } from "../../model/run-design-model"

export const RunDesignActionTypes = {
  API_ATTEMPT: "@@run-design/API_ATTEMPT",
  API_SUCCESS: "@@run-design/API_SUCCESS",
  API_FAILURE: "@@run-design/API_FAILURE",

  GET_DESIGN_SUCCESS: "@@run-design/GET_DESIGN_SUCCESS",
  GET_DESIGNS_SUCCESS: "@@run-design/GET_DESIGNS_SUCCESS"
}

export interface RunDesignApiState {
  readonly loading: boolean
  readonly designs: Array<RunSummary>
  readonly error: Error | null
}
