import React, { Fragment, FC, useState, useEffect } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import { RunForm } from "../../constants/constants"


const BODY_THEME_CLASS = "btheme-rdesign"

export const columnLabelsFull = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
export const rowLabels = ["A", "B", "C", "D", "E", "F", "G", "H"]

interface Props {
  buttonId: string, className: string, runDesignForm: RunForm
  sample: any, replace: any, index: number,
  disabled: boolean
}

export const ModalWellPicker: FC<Props> = (props: Props) => {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [originalValue, setOriginalValue] = useState<string>("")

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const columns = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  const columnLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const rows = [0, 1, 2, 3, 4, 5, 6, 7]


  const getClasses = () => {
    let classes = []
    columns.forEach(col => {
      classes.push([])
      rows.forEach(row => {
        let sID = rowLabels[row] + columnLabelsFull[col]
        let index = allocatedWells.indexOf(sID)
        if (index > -1 && index !== props.index) {
          classes[col].push("well-allocated")
        } else if (index === props.index) {
          classes[col].push("well-current")
        } else {
          classes[col].push("well-empty")
        }
      })
    })
    return classes
  }

  let allocatedWells = props.runDesignForm.samples.map( sample => {return sample.wellName})

  const [wellClass, setWellClass] = useState<string[][]>(getClasses())


  useEffect(() => {
    let classes = getClasses()
    setWellClass(classes)
    document.body.classList.add(BODY_THEME_CLASS)
    return function cleanup() {
      document.body.classList.remove(BODY_THEME_CLASS)
    }
  // eslint-disable-next-line
  },        [props.runDesignForm])

  const clearSelections = (cc) => {
    let classes = []
    columns.forEach(col => {
      classes.push([])
      rows.forEach(row => {
        let sID = rowLabels[col] + columnLabelsFull[row]
        let index = allocatedWells.indexOf(sID)
        if (index === -1 || index === props.index) {
          cc[col][row] = "well-empty"
        }
      })
    })
    setWellClass(classes)
  }

  const updateSample = (wellName: string) => {
    let val = originalValue !== "" ? originalValue : props.sample.wellName
    setOriginalValue(val)
    let newSample = Object.assign({}, props.sample)
    newSample.wellName = wellName
    props.replace(props.index, newSample)
  }

  // eslint-disable-next-line
  const makeIdForWell = (x, y) => {
    return rowLabels[y] + columnLabelsFull[x]
  }

  const wellClicked = (x, y) => {
    let sID = rowLabels[y] + columnLabelsFull[x]
    let wellIndex = allocatedWells.indexOf(sID)
    if (wellIndex > -1 && wellIndex !== props.index) {
      return
    }
    updateSample(sID)
    setWellClass(getClasses())
    let cc = wellClass.slice()
    clearSelections(cc)
    cc[x][y] = wellClass[x][y] === "well-current" ? "well-empty" : "well-current"
    setWellClass(cc)
  }

  const wellContents = (col, row) => {
    let sID = rowLabels[row] + columnLabelsFull[col]
    let wellIndex = allocatedWells.indexOf(sID)
    if (wellIndex === -1) {
      return ""
    } else {
      return wellIndex + 1
    }
  }

  const onSelect = () => {
    setOriginalValue("")
    toggle()
  }

  const onCancel = () => {
    let col = columnLabelsFull.indexOf(originalValue.substring(1, 3))
    let row = rowLabels.indexOf(originalValue.substring(0, 1))
    wellClicked(col, row)
    setOriginalValue("")
    toggle()
  }

  return (
    <Fragment>
      <Button onClick={toggle} color="primary" id={props.buttonId} disabled={props.disabled}>
          <span className="oi oi-list"></span>
          <span className="sr-only">
              select well
          </span>
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} id="wellPickerModal"
          className={props.className} aria-labelledby="wellpickerModal">
          <ModalHeader id="wellpickerModal">
              Select One Sample Well
          </ModalHeader>
          <ModalBody>
                <div className="well-picker-container">
                  <pre className="outline-FPO text-FPO text-center">
                      <div className="well-label"></div>
                      {
                        columns.map((column, index) => {
                         return (
                           <div key={`wellColumnLabel_${index}`}
                                className="well-label">{columnLabels[column]}</div>
                         )
                        })
                      }
                      {
                        rows.map((row, index) => {
                          return (
                            <div key={`wellRow_${index}`}>
                              <div key={`wellRowLabel_${index}`} className="well-label">{rowLabels[row]}</div>
                                {
                                  columns.map((column, index) => {
                                    return (
                                      <div key={`well_${index}`} className={wellClass[column][row]}
                                        onClick={() => { wellClicked(column, row) }}>
                                        {wellContents(column, row)}
                                      </div>
                                    )
                                  })
                                }
                            </div>
                          )
                        })
                      }
                  </pre>
              </div>
          <p><strong>Selected Sample Well:</strong> {props.sample.wellName}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={originalValue === ""} onClick={onCancel}>Cancel</Button>
            <Button color="primary" onClick={onSelect}>OK</Button>
          </ModalFooter>
      </Modal>
    </Fragment>
  )
}
